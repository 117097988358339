import dataProvider from "../../../utils/dataProvider";
import Button from "@mui/material/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as React from "react";

const ButtonCharge = (props) => {
    const handleClickCharge = (e) => {
        props.setCharged(true);
        dataProvider.update(
            'palettes', {
                id: props.record.id,
                data: {
                    'charged': true
                }
            }
        ).then(
            () => {
                dataProvider.getOne('navettes', {id: props.navette.id}).then(
                    ({data}) => {
                        props.setNavette(data);
                    }
                )
            }
        )
    }

    return (
        <Button startIcon={<FontAwesomeIcon icon="fa-solid fa-circle-check"/>} onClick={handleClickCharge} color="primary" variant="contained" >
            Valider chargement
        </Button>
    )
}

export default ButtonCharge
