import { Button, LinearProgress } from "@mui/material";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState, useEffect } from "react";
import dataProvider from "../../utils/dataProvider";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import {Box} from "@mui/material";
import AlertCreatePalette from "../../pages/Prepare/Show/AlertCreatePalette";
import { useNotify, useRefresh } from "react-admin";
  
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  
  const headCells = [
    {
      id: '@id',
      numeric: true,
      disablePadding: true,
      label: 'ID',
    },
    {
      id: 'products',
      numeric: false,
      disablePadding: false,
      label: 'Produits',
    },
    {
      id: 'attributs',
      numeric: false,
      disablePadding: false,
      label: 'Details',
    },
    {
      id: 'quantity',
      numeric: true,
      disablePadding: false,
      label: 'Quantité',
    },
    {
      id: 'spot',
      numeric: true,
      disablePadding: false,
      label: 'Emplacement',
    },
  ];
  
  function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
      props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={'center'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };
  
  function EnhancedTableToolbar(props) {
    const { numSelected } = props;
    const [selectOption, setSelectOption] = useState([])

    useEffect(() => {
      props.paletteList.forEach((palette) =>{
        dataProvider.getOne('palettes', {
            id: palette
        }).then((palette_detail) => {
            let newarray = [palette_detail.data.id, palette_detail.data.typeName]
            setSelectOption((current) => [...current, newarray])
        });
      })
      
    }, [props.paletteList])

    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} Séléctionné(s)
          </Typography>
        ) : (
          <></>
        )}
  
        {numSelected > 0 ? (
        <>
          <Tooltip title="Assigner à une autre palette">
            <Button onClick={props.handleAssign}>Assigner à la palette</Button>
          </Tooltip>
          <select onChange={props.handleSelectPalette}>
            <option selected={"selected"} disabled>Choisir une palette</option>
            {
            selectOption.map((palette) =>{
              return <option value={palette[0]}>{palette[1]}</option>
            })
          }</select>
        </>
        ) : (
            <></>
        )}
      </Toolbar>
    );
  }
  
  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };
  

const SeeContentDialog = (props) => {
  
  const notify = useNotify();
  const refresh = useRefresh();
  let handleClose = props.handleClose;
  let open = props.open;
  let data = props.data;
  let orderprep = props.orderprep;
  
  const [loading, setLoading] = useState(true);
  const [dataItems, setDataItems] = useState([]);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);


  const [selectedPaletteToTransfert, setselectedPaletteToTransfert] = useState("");

  function handleAssign(e){
    
    let selectedItemPalette = selected; //Item selected from the palette
    

    selectedItemPalette.forEach((item)=>{
      dataProvider.update('palette_items', {
        id: item,
        data: {
            'palette': selectedPaletteToTransfert
        }
      }).then(() => {
          notify('Changement de la réussi', {type: 'success'})
      }).catch(() => {
          notify('Erreur lors du changement des produits', {type: 'error'})
      })
    });
    refresh();
  }

  function handleSelectPalette(e){
    let target = e.target;
    setselectedPaletteToTransfert(target.value);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = dataItems.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataItems.length) : 0;  

  useEffect(() => {
    setLoading(true);
    setDataItems([]);
    if(data.items.length > 0){
        data.items.forEach((item_prep) =>{
            dataProvider.getOne('palette_items', {
                id: item_prep
            }).then((item_item) => {
                dataProvider.getOne('order_preparation_items', {
                    id: item_item.data.orderPreparationItem
                }).then((item_detail) => {
                    item_detail["palette_item"] = item_prep;
                    setDataItems((current) => [...current, item_detail["data"]]);
                    setLoading(false)
                });
            });
        });
    }else{
        setLoading(false);
    }
    
  },[open, data]);


  if(loading) return <LinearProgress/>
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Détails de la palette</DialogTitle>
        <DialogContent>
          <AlertCreatePalette color="success" title={"Ajouter une nouvelle palette à votre préparation"} orderPreparation={orderprep}/>
          <br></br>
          <DialogContentText>
            <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                <EnhancedTableToolbar handleSelectPalette={handleSelectPalette} paletteList={orderprep.palettes} handleAssign={handleAssign} numSelected={selected.length} />
                <TableContainer>
                    <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={'medium'}
                    >
                    <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={dataItems.length}
                    />
                    <TableBody>
                        {stableSort(dataItems, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                            const isItemSelected = isSelected(row.paletteItems[0]);
                            const labelId = `enhanced-table-checkbox-${index}`;
        
                            return (
                            <TableRow
                                hover
                                onClick={(event) => handleClick(event, row.paletteItems[0])}
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.paletteItems[0]}
                                selected={isItemSelected}
                            >
                                <TableCell padding="checkbox">
                                <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                    inputProps={{
                                    'aria-labelledby': labelId,
                                    }}
                                />
                                </TableCell>
                                <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                                >
                                {row.id.replace("/order_preparation_items/", "")}
                                </TableCell>
                                <TableCell align="right">{row.productInfos.split("|")[0]}</TableCell>
                                <TableCell align="right">{row.productInfos.replace("|", "").replace(row.productInfos.split("|")[0], "")}</TableCell>
                                <TableCell align="right">{row.quantity}</TableCell>
                                <TableCell align="right">{row.spot.spot}</TableCell>
                            </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                        <TableRow
                            style={{
                            height: (53) * emptyRows,
                            }}
                        >
                            <TableCell colSpan={6} />
                        </TableRow>
                        )}
                    </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={dataItems.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                </Paper>
            </Box>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fermer</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const ViewContentPalette = (props) =>{
    let orderprep = props.orderprep;
    const [open, setOpen] = useState(false);
    

    const handleClose = () => {
        setOpen(false);
    };

    function SeeContent(){
        setOpen(true);
    }

    return(<><Button onClick={SeeContent}><RemoveRedEyeIcon/></Button><SeeContentDialog data={props.data} handleClose={handleClose} orderprep={orderprep} open={open}/></>);
}

export default ViewContentPalette;