import * as React from "react";
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';
import {IconPickerItem} from "react-fa-icon-picker";

const IconFieldGuesser = (props) => {
    const { source } = props;
    const record = useRecordContext(props);
    return (
        <IconPickerItem icon={record[source]} size={30} />
    );
}

IconFieldGuesser.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default IconFieldGuesser;
