import {DateField, ReferenceField, TextField} from "react-admin";
import * as React from "react";
import PropTypes from "prop-types";

const NavetteField = (props) => {
    if(props.record ==null)
        return null

    return (
        <>
            { !props.hideCarrier &&
                <>
                    <ReferenceField record={props.record} reference={"carriers"} source={"carrier"} link={false}>
                    <TextField source="name"/>
                    </ReferenceField>
                    <span> - </span>
                </>
            }

            <DateField source="estimatedAt" locales="fr-FR" options={{
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
            }}/>
        </>
    )
}

NavetteField.propTypes = {
    hideCarrier: PropTypes.bool
}
NavetteField.defaultProps = {
    hideCarrier: false
}

export default NavetteField;