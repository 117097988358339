import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box} from "@mui/material";
import * as React from "react";
import {convertWeightToString} from "../../utils/functionProvider";

const WeightPresenter = (props) =>
    <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 2,
        color: (props.value >= props.max ? 'red' : 'black')
    }}>
        <FontAwesomeIcon icon="fa-solid fa-weight-hanging"/>
        <Box sx={{fontWeight: 'normal', fontSize: 14}}>
            {convertWeightToString(props.value)}
        </Box>
    </Box>


export default WeightPresenter;
