import * as React from 'react'
import {InputColorPickerGuesser} from "../../../input/ColorPicker";
import {InputNameGuesser} from "../../../input/Name";
import FormEditGuesser from "../../../form/Edit";
import {InputGuesser} from "@api-platform/admin";

export class OrderPreparationStateEditState {}
export class OrderPreparationStateEditProps {}
export class OrderPreparationStateEdit extends React.Component<OrderPreparationStateEditProps, OrderPreparationStateEditState> {
    render() {
        return (
            <FormEditGuesser {...this.props}>
                <InputNameGuesser source='name'/>
                <InputColorPickerGuesser source='color'/>
                <InputGuesser source={'private'}/>
            </FormEditGuesser>
        )
    }
}
