import BootstrapDialogTitle from "../../../component/dialog/BootstrapDialogTitle";
import {ButtonGroup, CircularProgress} from "@mui/material";
import {useDataProvider} from "react-admin";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import BootstrapDialog from "../../../component/dialog/BootstrapDialog";
import * as React from "react";
import {UserContext} from "../../../component/context/UserContext";
import {useContext, useState} from "react";
import dataProvider from "../../../utils/dataProvider";
import {Steps} from "./ButtonFinish";


const BootstrapDialogChoiceUserRedirect = ({orderPreparation, steps, step}) => {
    const dataProvider = useDataProvider();
    const [user, setUser] = useContext(UserContext);

    const [loading, setLoading] = useState(false);

    const redirectNext = () => {
        if(step === 'pack') {
            dataProvider.updateMany('users', {
                ids: orderPreparation.currentUsers,
                data: {
                    currentOrderPreparation: null
                }
            }).then(({data}) => {
                setUser(null)
            });
        } else {
            dataProvider.update('order_preparations', {
                id: orderPreparation.id,
                data: {
                    'state': '/order_preparation_states/started'
                }
            })
        }
        window.location.href = "#/" + steps[step].redirect
    }
    const redirectSameStep = () => {
        setLoading(true)
        dataProvider.updateMany('users', {
            ids: orderPreparation.currentUsers,
            data: {
               currentOrderPreparation: null
            }
        }).then(({data}) => {
            setUser(null)
            window.location.href = "#/" + steps[step].step_actual
        })
    }

    const isResponsible = localStorage.getItem('permissions').includes('ROLE_RESPONSABLE');

    return (
        <BootstrapDialog
            aria-labelledby="customized-dialog-title"
            open={true}
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={false}>
                Que voulez-vous faire ?
            </BootstrapDialogTitle>

            <DialogActions>
                <ButtonGroup variant="contained">
                    {
                        (steps[step].next !== 'control-responsible' || isResponsible) &&
                        <Button onClick={redirectNext}>Aller à la prochaine étape avec la même commande ? </Button>
                    }
                    <Button startIcon={loading ? <CircularProgress/> : null} onClick={redirectSameStep} color={'success'}>Retourner au choix des commandes </Button>
                </ButtonGroup>
            </DialogActions>
        </BootstrapDialog>
    )
}

export default BootstrapDialogChoiceUserRedirect;
