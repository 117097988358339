import * as React from 'react'
import {Permission} from "../../Permission";

export default class ProductCategoryResourceGuesser extends React.Component {
    render() {
        return (
            <Permission {...this.props }/>
        )
    }
}
