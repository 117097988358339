
import {Button, Tooltip} from "@mui/material";
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from "react";
import {useNotify} from "react-admin";
import dataProvider from "../../utils/dataProvider";

const style = {"color":"white","margin":"5px","padding":"10px","minWidth":"unset"};

const BtnMemoPrep = (props) =>{
    let memoGet = props.data.memo;
    const [memo, setMemo] = useState(memoGet);
    const [open, setOpen] = useState(false);
    const notify = useNotify();

  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    function handleSave(){
      

      //send memo to targetprep
      dataProvider.update('order_preparations', {
        id: props.data.id,
        data: {memo: memo}
      }).then(() => {
          notify('Le mémo de cette préparation à était mis à jour', 'success');
          handleClose();
      }).catch(() => {
          notify('Erreur: Le mémo de cette préparation ne c\'est pas mis à jour', {type: 'error'});
          handleClose();
      });
    }

    function handleTextArea(e){
      let target = e.target;
      let value = target.value;
      setMemo(value);
    }

    return (
        <>
        <Tooltip title="Mémo Logisticien" arrow>
            <Button style={style} variant="contained" onClick={handleClickOpen} component="label" size="small">
                                <NoteAltIcon />
            </Button>
        </Tooltip>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Mémo pour Logisticien</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <textarea onChange={handleTextArea} style={{width: "100%", height: "100px"}}>
                {memo}
              </textarea>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSave}>Sauvegarder</Button>
          </DialogActions>
        </Dialog>
        </>
    )
}
export default BtnMemoPrep;