import {ENTRYPOINT} from "../../config/entrypoint";
import {ReferenceField} from "react-admin";
import * as React from "react";

const Image = (props) => <img key={props.record.id} style={{maxWidth: '100px', height: 'auto', ...props.style}}
                              src={ENTRYPOINT + props.record.contentUrl} alt={""}/>

const ImageField = (props) => {
    return (
        <ReferenceField reference={"media_objects"} record={props.record} source={props.source} link={false}>
            <Image style={props.style}/>
        </ReferenceField>
    )
};

export default ImageField;
