import * as React from 'react'
import {
    ReferenceInput, SelectInput
} from "react-admin"
import {InputGuesser} from "@api-platform/admin";
import {InputColorPickerGuesser} from "../../../input/ColorPicker";
import FormEditGuesser from "../../../form/Edit";

export default function WarehouseLocationEdit(props) {
    return (
        <FormEditGuesser {...props}>
            <ReferenceInput source="product" reference="products">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <InputGuesser source={"code"} />
            <InputColorPickerGuesser source={'color'}/>
            <InputGuesser source={"quantity"} />
            <InputGuesser source={"priority"} />
            <InputGuesser  source={"comment"} />
        </FormEditGuesser>
    )
}

