import {ReferenceField} from "react-admin";
import {useRef, useState, useEffect} from "react";
import {IconButton, LinearProgress, ListItem} from "@mui/material";
import dataProvider from "../../../utils/dataProvider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as React from "react";
import ShowProduct from "./ShowProduct";


const PaletteItem = (props) => {
    const ref = useRef();
    const [colorbtn, setColorbtn] = useState('grey');

   
    useEffect(() => {
        if (!props.record){return}
        var iconsColor = props.record[props.responsable ? 'controlledResponsible' : 'controlled'] ? 'green' : 'grey';
        setColorbtn(iconsColor);
    });

    if (!props.record) return <LinearProgress/>

    const controlled = props.responsable ? 'controlledResponsible' : 'controlled';
    const data = props.responsable ? {controlledResponsible: !props.record[controlled]} : {controlled: !props.record[controlled]};
    
    var iconsColor = props.record[props.responsable ? 'controlledResponsible' : 'controlled'] ? 'green' : 'grey';
    var negateiconsColor = !props.record[props.responsable ? 'controlledResponsible' : 'controlled'] ? 'green' : 'grey';

    const onItemValidation = (e) => {
        var target = e.target;
        if(e.target.tagName == "svg"){
            target = e.target.parentNode;
        }else if(target.tagName == "path"){
            target = e.target.parentNode.parentNode;
        }
        setColorbtn(negateiconsColor);

        dataProvider.update('palette_items', {
            id: props.record.id,
            data: data
        }).then(
            () => {
                dataProvider.getOne('palettes', {id: props.palette.id}).then(
                    ({data}) => {
                        props.setPalette(data);
                        props.handleRefresh();
                    }
                )
            }
        );

        
    };
    

    function changeIcon(e){
        var target = e.target;
        if(e.target.tagName == "svg"){
            target = e.target.parentNode;
        }else if(target.tagName == "path"){
            target = e.target.parentNode.parentNode;
        }
        if(iconsColor == "grey"){
            var color = target.style.color = "green";
            iconsColor = "green";
        }else{
            var color = target.style.color = "red";
            iconsColor = "red";
        }
    }

    function rchangeIcon(e){
        var target = e.target;
        if(e.target.tagName == "svg"){
            target = e.target.parentNode;
        }else if(target.tagName == "path"){
            target = e.target.parentNode.parentNode;
        }
        if(iconsColor == "red"){
            var color = target.style.color = "green";
            iconsColor = "green";
        }else{
            var color = target.style.color = "grey";
            iconsColor = "grey";
        }
        
    }

    return (
        <ListItem ref={ref} sx={{transition: 'transform 1.5s 0s', borderTop: '1px solid lightgray'}}>
            <ReferenceField reference={'products'} record={props.record} source='product' link={false}>
                <ShowProduct palette={props.record}/>
            </ReferenceField>
            {
                !props.lock &&
                    <IconButton onMouseOver={changeIcon} onMouseOut={rchangeIcon} size="large" onClick={onItemValidation} sx={{ color: colorbtn }}
                                aria-label="Contrôle ok" component="span">
                        <FontAwesomeIcon
                            icon={'fa-solid fa-circle-check'}/>
                    </IconButton>
            }
        </ListItem>
    )
}

export default PaletteItem
