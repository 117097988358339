import * as React from 'react'
import {FormListGuesser} from "../../../form/List";
import { TextInput } from 'react-admin';
import FieldGuesser from "@api-platform/admin/lib/FieldGuesser";
import { useState} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import {useGetList, Loading} from 'react-admin';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import QRCode from "react-qr-code";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



export default function UsersList(props) {
    const [value, setValue] = useState(0);
    const [open, setOpen] = useState(false);
    const [qr, setQr] = useState("");
    const [nameUserqr, setNameUserqr] = useState("");
    
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    
    const handleClickOpen = (e) => {
        setOpen(true);
        setQr(e.target.getAttribute("qr"));
        setNameUserqr(e.target.getAttribute("user"));
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    const userEmail = localStorage.getItem("email");
    const domainEmail = userEmail.split("@")[1];
    const { data, ids, loading, error } = useGetList('users');
    
    
    if (loading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return(

        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Liste des Utilisateurs" {...a11yProps(0)} />
                    <Tab label="Liste des QR CODE" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <FormListGuesser {...props} hasShow={true}>
                    <FieldGuesser source={"email"}/>
                    <FieldGuesser source={"firstName"}/>
                    <FieldGuesser source={"lastName"}/>
                    <FieldGuesser source={"warehouses"}/>
                </FormListGuesser>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ImageList cols={5} gap={15}>
                    {ids.map((id) => (
                        (data[id].email.includes(domainEmail) || domainEmail.includes("admin.com") || userEmail === "admin@3bimport.fr") ?

                        <ImageListItem key={data[id].email}>
                            <Card sx={{ maxWidth: 345 }}>
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image={`https://via.placeholder.com/150px`}
                                    alt={data[id].email}
                                />
                                <CardContent>
                                    <Typography gutterBottom component="div">
                                        {data[id].firstName} {data[id].lastName}  
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button user={data[id].firstName + " " + data[id].lastName} qr={data[id].quickConnectionKey} onClick={handleClickOpen} size="small">Voir le QR CODE</Button>
                                </CardActions>
                            </Card>
                        </ImageListItem>
                        : null
                    ))}
                </ImageList>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <p>{nameUserqr}</p>
                        <QRCode value={qr} />
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose}>Fermer</Button>
                    </DialogActions>
                </Dialog>
            </TabPanel>
        </Box>

    )
    
}

