import * as React from 'react'
import {Permission} from "../../Permission";
import {PaletteCreate} from "./Create";
import {PaletteEdit} from "./Edit";
import PaletteList from "./List";

export default class PaletteResourceGuesser extends React.Component {
    create = props => (
        <PaletteCreate {...props}/>
    );

    edit = props => (
        <PaletteEdit {...props}/>
    );

    list = props => (
        <PaletteList {...props}/>
    );

    render() {
        return (
            <Permission {...this.props} create={this.create} edit={this.edit} list={this.list}/>
        )
    }
}
