import * as React from 'react'
import {InputGuesser} from "@api-platform/admin";
import EditGuesser from "@api-platform/admin/lib/EditGuesser";
import {InputColorPickerGuesser} from "../../../input/ColorPicker";

const ChannelEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source={"code"} />
        <InputGuesser source={"name"} />
        <InputColorPickerGuesser source={"color"} />
        <InputGuesser source={"hostname"} />
        {/*<InputGuesser source={"orderPreparations"} />*/}
        <InputGuesser source={"apiKey"} />
    </EditGuesser>
);

export default ChannelEdit;
