import * as React from 'react';
import Button from '@mui/material/Button';
import {useState} from "react";
import {TransitionProps} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {
    faList,
} from "@fortawesome/free-solid-svg-icons";

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import OrderPreparationShowItems from "../../../component/resource/Order/OrderPreparation/Show/Tab/Items";
import {useRefresh} from "react-admin";

library.add(faList);

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogOrderPreparationitems(props) {
    const [open, setOpen] = useState(false);
    const refresh = useRefresh();

    if (open)
        return (
            <Dialog
                fullScreen
                open={true}
                onClose={() => setOpen(false)}
                TransitionComponent={Transition}
            >
                <AppBar sx={{position: 'relative'}}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => setOpen(false)}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                        <Typography sx={{ml: 2, flex: 1}} variant="h3" component="div">
                            Liste des produits de la commande
                        </Typography>
                        <Button autoFocus color="inherit" onClick={() => refresh()}>
                            Valider
                        </Button>
                    </Toolbar>
                </AppBar>
                <OrderPreparationShowItems id={open} record={props.orderPreparation}/>
            </Dialog>
        )

    return (
        <IconButton onClick={() => setOpen(true)} color="primary" aria-label="Voir les produits de la commande">
            <FontAwesomeIcon icon="fa-solid fa-list"/>
        </IconButton>
    );
}
