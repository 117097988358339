import dataProvider from "../../../utils/dataProvider";
import {Alert, Box, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import * as React from "react";
import {useRef, useState} from "react";
import {useRefresh} from "react-admin";

const ButtonWrap = (props) => {
    const [packed, setPacked] = useState(props.record.packed)
    const [weight, setWeight] = useState(props.record.weight)
    const refresh = useRefresh();
    const refInput = useRef(null);
    const handleClickWrap = () => {
        dataProvider.update(
            'palettes', {
                id: props.record.id,
                data: {
                    'packed': true,
                    weight: parseInt(refInput.current.value)
                }
            }
        ).then(() => {
                dataProvider.getOne('order_preparations', {
                        id: props.orderPreparation.id
                    }
                ).then(({data}) => {
                    props.setOrderPreparation(data)
                    refresh();
                })
            }
        )
        setPacked(true);
    }

    const handleRemoveClickWrap = () => {
        dataProvider.update(
            'palettes', {
                id: props.record.id,
                data: {
                    'packed': false,
                    weight: 0
                }
            }
        ).then(() => {
                dataProvider.getOne('order_preparations', {
                        id: props.orderPreparation.id
                    }
                ).then(({data}) => {
                    props.setOrderPreparation(data)
                    refresh();
                })
            }
        )
        setPacked(false);
    }

    if (packed){
        return (
                <Alert severity="success" style={{alignSelf: "center", alignItems: "center"}}>
                    <div style={{display: "flex", alignSelf: "center", alignItems: "center"}}>
                        <span>Emballé</span> 
                        <Button onClick={handleRemoveClickWrap} color="error" variant="outlined" style={{width: "32px", minWidth: "32px", height: "32px", borderRadius: "32px", marginLeft: "5px"}}>X</Button>
                    </div>
                </Alert>
        );
    }
       
    return (
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
            <TextField inputRef={refInput} type="number" id="outlined-basic" label="Poids réel (g)" variant="standard"
                       value={weight} onChange={(e) => {setWeight(e.target.value)}}/>
            <Button onClick={handleClickWrap}
                    color="primary" variant="contained">
                Valider
            </Button>
        </Box>
    )
}

export default ButtonWrap
