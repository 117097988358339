import * as React from "react";
import {Layout, OrderPreparationCheckCurrentUser, OrderPreparationStepLoader} from "../_Layout";

const page = {
    title: 'Préparer',
    fetchData: '/order_preparation/prepare'
}

const OrderPreparationPrepareIndex = () => {
    return (
        <Layout {...page}>
            <OrderPreparationCheckCurrentUser>
                <OrderPreparationStepLoader {...page}/>
            </OrderPreparationCheckCurrentUser>
        </Layout>
    );
};

export default OrderPreparationPrepareIndex;
