import * as React from 'react'
import {InputGuesser} from "@api-platform/admin";
import {ReferenceInput, SelectInput} from 'react-admin';
import {EditGuesser} from "@api-platform/admin";

export class PaletteEditState {
    location: string;
    orderPreparationId: number;
}

export class PaletteEditProps {
    resource: string;
    id: string;
    location: any;
}

export class PaletteEdit extends React.Component<PaletteEditProps, PaletteEditState> {
    constructor(props) {
        super(props);

        if (this.props.location.state && this.props.location.state.prevPath) {
            this.state = {
                location: this.props.location.state.prevPath.substr(1),
                orderPreparationId: this.props.location.state.orderPreparationId
            }
        } else {
            this.state = {
                location: '/'+this.props.resource,
                orderPreparationId: null
            }
        }

    }

    render() {
        return (
            <EditGuesser
                redirect={this.state.location}
                id={this.props.id}
                resource={this.props.resource}>
                <ReferenceInput source="type" reference="products" filter={{palette: true}}>
                    <SelectInput optionText="name" />
                </ReferenceInput>

                <InputGuesser source={'weight'}/>
                <InputGuesser source={'length'}/>
                <InputGuesser source={'width'}/>
                <InputGuesser source={'height'}/>
            </EditGuesser>
        )
    }
}
