import * as React from 'react'
import {Permission} from "../../Permission";
import WarehouseList from "./List";
import WarehouseShow from "./Show";

export default class WarehouseResourceGuesser extends React.Component {
    render() {
        return (
            <Permission {...this.props } list={WarehouseList} show={WarehouseShow}/>
        )
    }
}
;
