import * as React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {Title, Loading, useGetOne, useRedirect, useDataProvider, useNotify} from 'react-admin';
import {useContext, useEffect, useState} from "react";
import {fetchData} from "../utils/dataProvider";
import AlertPresenter from "../component/presenter/Alert";
import {Alert, Box} from "@mui/material";
import PreparationResume from "../component/box/preparation/PreparationResume";
import PropTypes from "prop-types";
import OrderPreparationStartStep from "../component/resource/Order/OrderPreparation/List/ButtonAction";
import {UserContext} from "../component/context/UserContext";

const OrderPreparationStepLoader = (props) => {
    const [time, setTime] = useState(Date.now());
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        const interval = setInterval(() => setTime(Date.now()), 10000);
        fetchData(props.fetchData).then(
            (results) => results.json()
                .then(
                    (result) => {
                        if(result.error){
                            setError(true)
                            setData(result.error)
                        } else {
                            setError(false)
                            setData(result.id)
                        }
                        setLoading(false);
                        return () => clearInterval(interval);
                    })).catch((result) => {
            setLoading(false);
            setData(result.error);
            setError(true);
        });

        return () => {
            clearInterval(interval);
        }
    });

    if(loading) return <Loading/>
    if(error) return <AlertPresenter type={"error"} value={data} />

    return <OrderPreparationAction id={data} />
}

const Layout = (props) => {
    const [refreshContent, setRefreshContent] = useState(false);
    const handleRefresh = () => {setRefreshContent(!refreshContent)}
    return (
        <Card>
            <Title title={props.title}/>
            <CardContent key={'content'+refreshContent}>
                {React.Children.map(props.children, c => {
                    if(React.isValidElement(c)){
                        return React.cloneElement(c, {handleRefresh: handleRefresh});
                    }
                })}
            </CardContent>
        </Card>
    )
}


const OrderPreparationAction = (props) => {
    const { data, loading, error } = useGetOne("order_preparations", "/order_preparations/" + props.id);

    if(loading) return <Loading />
    if(error) return <Alert severity="error">Erreur lors de la récupération de la commande.</Alert>

    return (
        <>
            <PreparationResume record={data}  />
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 2}}>
                <OrderPreparationStartStep record={data}/>
            </Box>
        </>
    );
}
OrderPreparationAction.propTypes = {
    id: PropTypes.number,
};

const OrderPreparationCheckCurrentUser = (props) => {
    const redirect = useRedirect();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const [user, _] = useContext(UserContext);
    const [stateRedirect, setStateRedirecot] = useState(false)

    useEffect(() => {
        if(user && user.currentOrderPreparation){
            dataProvider.getOne('order_preparations', {id: user.currentOrderPreparation}).then(
                ({data}) => {
                    notify("Vous venez d'être redirigé vers la préparation actuelle lié à votre compte.")
                    redirect('/order_preparations/'+ encodeURIComponent(user.currentOrderPreparation)+'/'+data.currentStep)
                    setStateRedirecot(true)
                }
            )
        }
    })

    if(!stateRedirect)
        return props.children

    return null
}

export {Layout, OrderPreparationCheckCurrentUser, OrderPreparationStepLoader, OrderPreparationAction};
