import * as React from 'react'
import {OrderPreparationStateCreate} from "./Create";
import {OrderPreparationStateEdit} from "./Edit";
import {OrderPreparationStateList} from "./List";
import {Permission} from "../../Permission";

export default class OrderPreparationStateResourceGuesser extends React.Component {
    create = props => (
        <OrderPreparationStateCreate {...props}/>
    );

    edit = props => (
        <OrderPreparationStateEdit {...props}/>
    );

    list = props => (
        <OrderPreparationStateList {...props}/>
    );

    render() {
        return (
            <Permission {...this.props } show={null} create={this.create} edit={this.edit} list={this.list}/>
        )
    }
}
