import {useDataProvider} from "react-admin";
import {useEffect, useState} from "react";
import {Alert, LinearProgress} from "@mui/material";
import * as React from "react";
import { useRefresh } from 'react-admin';

const GetOneOrderPreparation = (props) => {
    const dataProvider = useDataProvider();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    useEffect(() => {
        dataProvider.getOne('order_preparations', {id: props.id})
            .then(({data}) => {
                setData(data);
                setLoading(false);
                // props.setOrderPreparation(data)
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, [dataProvider, props]);
    if (loading) return <LinearProgress/>
    if (error) return <Alert severity="error">Erreur lors de la récupération de la commande.</Alert>
    return (
        <>
            {React.Children.map(props.children, c => {
                if(React.isValidElement(c)){
                    return React.cloneElement(c, {orderPreparation:data, setOrderPreparation:setData});
                }
            })}
        </>
    )
}
const GetOneProduct = (props) => {
    const dataProvider = useDataProvider();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    useEffect(() => {
        dataProvider.getOne('products', {id: props.id})
            .then(({data}) => {
                setData(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, [dataProvider, props.id]);
    if (loading) return <LinearProgress/>
    if (error) return <Alert severity="error">Erreur lors de la récupération du produit.</Alert>
    return (
        <>
            {React.Children.map(props.children, c => {
                if(React.isValidElement(c)){
                    return React.cloneElement(c, {product:data});
                }
            })}
        </>
    )
}

const GetOneOrderPreparationItem = (props) => {
    const dataProvider = useDataProvider();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    useEffect(() => {
        dataProvider.getOne('order_preparation_items', {id: props.id})
            .then(({data}) => {
                setData(data);
                setLoading(false);
                if(props.setOrderPreparationItem){
                    props.setOrderPreparationItem(data)
                }
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, [dataProvider, props]);
    if (loading) return <LinearProgress/>
    if (error) return <Alert severity="error">Erreur lors de la récupération de l'item d'une préparation.</Alert>
    return (
        <>
            {React.Children.map(props.children, c => {
                if(React.isValidElement(c)){
                    return React.cloneElement(c, {orderPreparationItem:data, setOrderPreparationItem: setData});
                }
            })}
        </>
    )
}

const GetOnePalette = (props) => {
    const refresh = useRefresh();
    const dataProvider = useDataProvider();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    useEffect(() => {
        dataProvider.getOne('palettes', {id: props.id})
            .then(({data}) => {
                setData(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
                refresh();
            })
    }, [dataProvider, props, refresh]);
    if (loading) return <LinearProgress/>
    if (error) return <Alert severity="error">Erreur lors de la récupération d'une palette.</Alert>
    return (
        <>
            {React.Children.map(props.children, c => {
                if(React.isValidElement(c)){
                    return React.cloneElement(c, {palette:data});
                }
            })}
        </>
    )
}

const GetOneNavette = (props) => {
    const dataProvider = useDataProvider();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    useEffect(() => {
        dataProvider.getOne('navettes', {id: props.id})
            .then(({data}) => {
                setData(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, [dataProvider, props.id]);
    if (loading) return <LinearProgress/>
    if (error) return <Alert severity="error">Erreur lors de la récupération de la navette.</Alert>
    return (
        <>
            {React.Children.map(props.children, c => {
                if(React.isValidElement(c)){
                    return React.cloneElement(c, {navette:data, setNavette:setData});
                }
            })}
        </>
    )
}


export {
    GetOneOrderPreparation,
    GetOneProduct,
    GetOneOrderPreparationItem,
    GetOnePalette,
    GetOneNavette
}
