import {
    Grid
} from "@mui/material";
import * as React from "react";
import GridPalette from "./GridPalette";


const ControlList = (props) => {
    return (
        <>
            <h2 style={{textAlign: "center"}}>Contrôle palette #{props.palette.number}</h2>

            <Grid container>
                <GridPalette name='Produits à contrôler' backgroundColor='grey' {...props}/>
            </Grid>
        </>
    )
};

export default ControlList;
