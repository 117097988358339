import {Alert} from "@mui/material";
import * as React from "react";
import PropTypes from "prop-types";

const AlertPresenter = (props) => {
    return <Alert severity={props.type}>{props.value}</Alert>;
}
AlertPresenter.propTypes = {
    type: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    reload: PropTypes.bool
};

export default AlertPresenter;
