import {Card as MuiCard, CardContent, withStyles} from "@material-ui/core";
import * as React from "react";
import TransportFilter from "../../../filter/Transport";

const Card = withStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            order: -1, // display on the left rather than on the right of the list
            width: '15em',
            marginRight: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}))(MuiCard);

const FilterSidebar = (props) => (
    <Card>
        <CardContent>
            <TransportFilter typeDisplay="charged" />
            <TransportFilter typeDisplay="expired" />
            <TransportFilter typeDisplay="filterlist" />
        </CardContent>
    </Card>
);

export default FilterSidebar;