import * as React from "react";
import PropTypes from 'prop-types';
import {Avatar} from "@mui/material";
import invert from 'invert-color';

const ChannelPresenter = (props) => {
    if(props.debug === "true"){
    }
    var borderRadiusText = '3px';
    if(props.setColorBorder != null){
        props.setColorBorder("1px solid "+props.record.color);
        borderRadiusText = '3px 0px 0px 3px';
    }
    
   
    if(props.record == null)
        return null
    return (
        <Avatar variant="square" sx={{borderRadius: borderRadiusText, backgroundColor: props.record.color, color: invert(props.record.color, true), fontWeight: 'bold'}}>{props.record.code}</Avatar>
    );
}

ChannelPresenter.propTypes = {
    record: PropTypes.object,
};

export default ChannelPresenter;
