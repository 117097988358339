import {useGetOne} from "react-admin";
import {CircularProgress} from "@mui/material";
import * as React from "react";
import ScanProductDialog from "./Dialog";
import ScanProductCreatePalette from "./CreatePalette";

const ScanProductWrapper = (props) => {
    const {data, loading, error} = useGetOne('products', '/products/' + props.scan);
    const context = document.location.href.split('/').pop();

    if (loading)
        return <CircularProgress/>;

    if (error) {
        return props.handleClose(true);
    }

    props.createScanLog({})

    if(context === 'prepare'){
        if(data.palette){
            return <ScanProductCreatePalette product={data} handleClose={props.handleClose}/>
        }
    }

    return <ScanProductDialog record={data} handleClose={props.handleClose}/>
}

export default ScanProductWrapper
