import {cloneElement, createContext, useState} from "react";
import * as React from "react";

export const ScanContext = createContext([null, () => {}])

export const ScanContextProvider = ({ value, children }) => {
    const [scan, setScan] = useState(value);
    return (
        <ScanContext.Provider value={[scan, setScan]}>
            {cloneElement(children, { scan: scan })}
        </ScanContext.Provider>
    );
};
