import * as React from 'react'
import { Toolbar, SaveButton  } from 'react-admin';

export class PostEditToolbarState {
}

export class PostEditToolbarProps {
    disabled: boolean;
}

export class PostEditToolbar extends React.Component<PostEditToolbarProps, PostEditToolbarState> {
    render() {
        return (
            <Toolbar {...this.props} >
                <SaveButton disabled={this.props.disabled}/>
            </Toolbar>
        )
    }
}
