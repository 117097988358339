import * as React from 'react'
import IconFieldGuesser from "../../../field/Icon";
import ColorFieldGuesser from "../../../field/Color";
import NameFieldGuesser from "../../../field/Name";
import {FormListGuesser} from "../../../form/List";

export class OrderPreparationStateListState {}
export class OrderPreparationStateListProps {}
export class OrderPreparationStateList extends React.Component<OrderPreparationStateListProps, OrderPreparationStateListState> {
    render() {
        return (
            <FormListGuesser {...this.props}>
                <NameFieldGuesser source="name"/>
                <ColorFieldGuesser source="color"/>
                <IconFieldGuesser source="icon"/>
            </FormListGuesser>
        )
    }
}
