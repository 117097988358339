import * as React from "react";
import {Box, CircularProgress, Divider} from "@mui/material";
import {useState, useEffect} from "react";
import {library} from "@fortawesome/fontawesome-svg-core";
import {
    faWeightHanging,
    faLock,
    faUnlock,
    faCaretDown,
    faArrowDown,
    faPlus,
    faMinus,
    faPen,
    faRightLeft,
    faCircleCheck,
    faPaperPlane
} from "@fortawesome/free-solid-svg-icons";
import PreparationLargeResume from "../../component/box/preparation/PreparationLargeResume";
import {GetOneOrderPreparation, GetOneOrderPreparationItem, GetOneProduct} from "../GetOne";
import {Product} from "./Show/Product";
import ButtonFinishPreparation from "./Show/ButtonFinish";
import AlertCreatePalette from "./Show/AlertCreatePalette";
import PaletteItem from "./Show/PaletteItem";
import BreadcrumbOrderPreparationItem from "./Show/BreadcrumbOrderPreparationItem";
import {Title, useVersion} from "react-admin";

library.add(faWeightHanging, faLock, faCaretDown, faArrowDown, faPlus, faMinus, faPen, faRightLeft, faCircleCheck, faUnlock, faPaperPlane);

const ShowWithOrderPreparationItem = (props) => {
    const [lock, setLock] = useState(true);

    const [reloadBreadcrumb, setReloadBreadcrumb] = useState(false);

    function handleReloadBreadCrumb(){
        setReloadBreadcrumb(!reloadBreadcrumb);
    }



    return (
        <>
            <Divider/>
            <GetOneProduct id={props.orderPreparationItem.product}>
                <Product orderPreparation={props.orderPreparation}/>
            </GetOneProduct>
            <Divider/>

            {
                props.orderPreparation.palettes.length > 0 ?
                    <PaletteItem
                        orderPreparation={props.orderPreparation}
                        setOrderPreparation={props.setOrderPreparation}
                        setOrderPreparationItem={props.setOrderPreparationItem}
                        orderPreparationItem={props.orderPreparationItem}
                        palette={props.palette}
                        setPalette={props.setPalette}
                        lock={lock}
                        setLock={setLock}
                        handleReloadBreadCrumb={handleReloadBreadCrumb}
                        setCurrentOrderPreparationItem={props.setCurrentOrderPreparationItem}/>
                    : <AlertCreatePalette orderPreparation={props.orderPreparation}/>
            }
            <Divider/>
            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>

                
                <BreadcrumbOrderPreparationItem
                    orderPreparation={props.orderPreparation}
                    currentOrderPreparationItem={props.orderPreparationItem}
                    setCurrentOrderPreparationItem={props.setCurrentOrderPreparationItem}
                    reloadBreadcrumb={reloadBreadcrumb}
                    setLock={setLock}/>
                <ButtonFinishPreparation orderPreparation={props.orderPreparation} step='prepare'/>
            </Box>
        </>
    )
}

const ShowWithOrderPreparation = (props) => {
    const [palette, setPalette] = useState(null);
    const [orderPreparationItem, setOrderPreparationItem] = useState(null);

    return (
        <>
            <PreparationLargeResume orderPreparation={props.orderPreparation}/>
            {props.orderPreparation.items.length > 0 &&
                <GetOneOrderPreparationItem
                    id={orderPreparationItem == null ? props.orderPreparation.items[0] : orderPreparationItem.id}>
                    <ShowWithOrderPreparationItem
                        orderPreparation={props.orderPreparation}
                        setOrderPreparation={props.setOrderPreparation}
                        setCurrentOrderPreparationItem={setOrderPreparationItem}
                        palette={palette} setPalette={setPalette}/>
                </GetOneOrderPreparationItem>
            }
        </>
    )
}


const OrderPreparationPrepareShow = (props) => {
    const version = useVersion()
    return (
        <>
            <Title title={"Préparer"}/>
            <GetOneOrderPreparation key={version} id={decodeURIComponent(props.match.params.id)}>
                <ShowWithOrderPreparation/>
            </GetOneOrderPreparation>
        </>
    )
}

export default OrderPreparationPrepareShow;
