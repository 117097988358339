import * as React from "react";
import PropTypes from 'prop-types';
import {useGetOne} from 'react-admin';
import {IconPickerItem} from "react-fa-icon-picker";
import {Chip, CircularProgress} from "@mui/material";


const OrderPreparationStepField = (props) => {
    const { data, loading, error } = useGetOne('order_preparation_steps', props.record[props.source]);
    if (loading) { return <CircularProgress size='1rem' />; }
    if (error) { return <p>ERROR</p>; }

    if(props.hiddenLabel) {
        return (
            <IconPickerItem icon={data['icon']} size={props.size} color={data['color']} />
        );
    } else {
        if(props.chip) {
            return <Chip label={data['name']} sx={{backgroundColor: data['color'], color: 'white'}} />
        } else {
            return (
                <div className={'IconAlignWithText'}>
                    {!props.hiddenIcon && <IconPickerItem icon={data['icon']} size={props.size} color={data['color']}/>}
                    {data['name']}
                </div>
            );
        }
    }
}


OrderPreparationStepField.defaultProps = {
    size: 20,
    hiddenLabel: true
}
OrderPreparationStepField.propTypes = {
    hiddenLabel: PropTypes.bool,
    hiddenIcon: PropTypes.bool,
    size: PropTypes.number,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default OrderPreparationStepField;
