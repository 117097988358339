import * as React from "react";
import PropTypes from 'prop-types';
import {Component} from "react";
import dataProvider from "../../utils/dataProvider";
import {TextField} from "@mui/material";

class NumberField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.record == null ? '0' : props.record[props.source]
        }
    }

    handleChange = (e) => {
        if(!e.target.value || e.target.value < 0)
            return;
        const { record, source } = this.props;
        const val = parseInt(e.target.value);

        let data = {};
        data[source] = val;

        dataProvider.update(
            this.props.resource, {
                id: record.id,
                data: data
            }
        ).then(({data}) => {
            if(this.props.handleData) this.props.handleData(data);
        })

        this.setState({
            'value' : val
        });
    }

    render() {
        if(this.props.record == null)
            return null;
        return <TextField hiddenLabel={this.props.hiddenLabel} label={this.props.hiddenLabel ? null : "Priorité"} variant="standard" style={{width: '5em', marginTop: 0}} value={this.state.value} type="number" onChange={this.handleChange} />;
    }
}

NumberField.propTypes = {
    resource: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
    hiddenLabel: PropTypes.bool,
    handleData: PropTypes.func
};

NumberField.defaultProps = {
    hiddenLabel: true
};

export default NumberField;
