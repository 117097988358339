import * as React from "react";
import {Box} from "@mui/material";
import PreparationNumber from "../../../component/box/preparation/PreparationNumber";
import PreparationIdentity from "../../../component/box/preparation/PreparationIdentity";
import PreparationCarrier from "../../../component/box/preparation/PreparationCarrier";
import PreparationCounterState from "../../../component/box/preparation/PreparationCounterState";
import LinearProgressWithLabelField from "../../../component/field/LinearProgressWithLabelField";
import BackButton from "../../../component/button/BackButton";
import PropTypes from "prop-types";
import PreparationState from "./PreparationState";

import DialogOrderPreparationitems from "../../../pages/Prepare/Show/DialogOrderPreparationitems";

const flexbox = {display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2};

const Header = (props) => (
    <>
        <TopHeader orderPreparation={props.orderPreparation}/>
        <BottomHeader orderPreparation={props.orderPreparation}/>
    </>
)
const TopHeader = (props) => (
    <Box sx={flexbox}>
        <BackButton/>
        <Box sx={{display: 'flex', gap: 5}}>
            <PreparationNumber record={props.orderPreparation}/>
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <PreparationIdentity record={props.orderPreparation}/>
            </Box>
        </Box>
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <PreparationState orderPreparation={props.orderPreparation}/>
        </Box>
    </Box>
);
const BottomHeader = (props) => (
    <Box sx={Object.assign({}, flexbox, {justifyContent: 'space-around'})}>
        <DialogOrderPreparationitems orderPreparation={props.orderPreparation}/>
        <PreparationCarrier record={props.orderPreparation}/>
        <Box sx={{display: 'flex'}}>
            <PreparationCounterState record={props.orderPreparation}/>
        </Box>
        <Box sx={{display: 'block', textAlign: 'center', minWidth: 200}}>
            AVANCEMENT PRÉPARATION
            <LinearProgressWithLabelField source="progress" record={props.orderPreparation}/>
        </Box>
    </Box>
);

const PreparationLargeResume = (props) => (
    <Header orderPreparation={props.orderPreparation}/>
);

PreparationLargeResume.propTypes = {
    orderPreparation: PropTypes.any,
}

export default PreparationLargeResume;
