import * as React from 'react'
import {
    useRedirect
} from 'react-admin';

const NavetteList = (props) => {
    const redirect = useRedirect();
    redirect('/charger');

    return null;
}

export default NavetteList;
