import {CircularProgress} from "@mui/material";
import * as React from "react";
import {useDataProvider, useNotify, useRefresh} from "react-admin";
import {useEffect, useState} from "react";

const ScanProductCreatePalette = (props) => {
    const location = document.location.href.split('/')
    const orderPreparationId = decodeURIComponent(location[location.length-2]);

    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();

    useEffect(() => {
        if(data === null){
            setData(false);
            dataProvider.create('palettes', {
                data: {
                    orderPreparation: orderPreparationId,
                    type: props.product.id
                }
            }).then(({data}) => {
                setLoading(false)
                setData(data)
                props.handleClose();
                notify('Création d\'une palette', {type: 'success'})
                refresh();
            }).catch(() => {
                setLoading(false)
                setError(true);
            })
        }
    })

    if(loading)
        return <CircularProgress/>;

    if (error) {
        return props.handleClose(true);
    }

    return null;
}

export default ScanProductCreatePalette
