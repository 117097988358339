
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import dataProvider from "../../../../../utils/dataProvider";

import {useState, useEffect} from 'react';
import { useNotify } from 'react-admin';


export default function ButtonTrackingPopup(props) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [listingPalette, setListingPalette] = useState([]);
    const [listingPaletteValue, setListingPaletteValue] = useState([]);
    const notify = useNotify();

    const style = {"color":"white","margin":"5px","padding":"10px","minWidth":"unset"};

    function openDialogTracking(){
        setOpen(true);
    }

    function close(){
        setOpen(false);
    }

    useEffect(() => {
        if(loading == false){
            if(props.preparation.palettes != "" && props.preparation.palettes != undefined && props.preparation.palettes != null){
                setLoading(true);
                props.preparation.palettes.forEach(function(palette){
                    setListingPalette(current => [...current, palette]);
                    dataProvider.getOne('palettes', {
                        id: palette
                    }).then(({data}) => {
                        setListingPaletteValue(current => [...current, data.tackingNumber])
                    });
                    
                });
            }
            
        }
        
    }, [loading, props.preparation.palettes]);


    //One per One save (for future multiple tracking per palette)
    function clickSaveTracking(e){
        var target = e.target;
        var targetInput = document.getElementById(target.getAttribute('target'));
        var valueofi = targetInput.getAttribute('iofvalue');
        dataProvider.update('palettes', {
            id: listingPalette[valueofi],
            data: {
                'tackingNumber': listingPaletteValue[valueofi]
            }
        }).then(() => {
            close();
            notify('Sauvegarde effectué', {type: 'success'});
            
        })
    }

    function clickSaveForAllTracking(e){
        var target = e.target;
        var targetInput = document.getElementById(target.getAttribute('target'));
        var valueofi = targetInput.getAttribute('iofvalue');
        listingPalette.map((palette) => (
            dataProvider.update('palettes', {
                id: palette,
                data: {
                    'tackingNumber': listingPaletteValue[valueofi]
                }
            }).then(() => {
                
                
            })
        ))
        close();
        notify('Sauvegarde effectué', {type: 'success'});
    }

    function saveValueState(e){
        var target = e.target;
        var iforsave = target.getAttribute("iofvalue");
        const newItems = [...listingPaletteValue];
        newItems[iforsave] = target.value;
        setListingPaletteValue(newItems);

        target.focus();
        target.select();
    }

    const ListingInput = (props) => {
        const styleInput = {
            padding: "10px",
            fontFamily: "Arial",
            borderRadius: "5px"
        };
        console.log(listingPalette);
        console.log(listingPaletteValue);
        if(props.input != null){
            var inputlist = new Array();
            var id="100";
            for(var i = 0; i < props.input.length; i++){
                if(i == 0){ //Retirer ce if pour unlock par 
                    inputlist.push(<span><input iofvalue={i} autoFocus={true} onChange={saveValueState} palette={props.input[i]} value={listingPaletteValue[i]} style={styleInput} id={"tracking_input_"+(id+i)} key={id+i} type={"text"}/><Button onClick={clickSaveForAllTracking} target={"tracking_input_"+(id+i)}>Sauvegarder</Button></span>)
                }
                
            }
            
            return (
                inputlist.map((input) => (
                    <span>
                      {input}
                    </span>
                ))
            );
        }else{
            return (
                <span>Pas de palette attribué</span>
            );
        }
        
    }
    
    return (
        <>  
           <section>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Tooltip title="Suivis des palettes" arrow>
                            <Button onClick={openDialogTracking} style={style} variant="contained" component="label" size="small">
                                <FlightTakeoffIcon />
                            </Button>
                        </Tooltip>
                    </Stack>
                    <Dialog open={open}>
                        <DialogTitle>Liens de suivis de la préparation</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                               <ListingInput input={listingPalette} palettes={props.preparation.palettes}/>
                            </DialogContentText>
                            
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={close}>Fermer</Button>
                        </DialogActions>
                    </Dialog>
            </section>
            
        
        </>
    );
}
    