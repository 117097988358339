const convertWeightToString = (weight) => weight >= 1000
    ? (weight / 1000).toFixed(2) + ' kg'
    : weight + ' g';

const convertStringToColor = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xFF;
        color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
}

const convertNameToAvatar = (name) => {
    return {
        sx: {
            bgcolor: convertStringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

export {
    convertWeightToString,
    convertStringToColor,
    convertNameToAvatar
}