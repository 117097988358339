import * as React from "react";
import PropTypes from 'prop-types';
import {Link} from "react-admin";
import {Box, Fab} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {linkToRecord} from "ra-core";
import {makeStyles} from "@material-ui/core/styles";
import DeleteButtonWithConfirm from "../../../button/DeleteButtonWithConfirm";
import ButtonPaletteMoveLocation from "./ButtonMoveLocation";
import ButtonPalettePrint from "./ButtonPrint";

const useStyles = makeStyles({
    btnMargin: {
        marginRight: 10
    },
    btnDelete: {
        "&:hover": {
            backgroundColor: 'transparent'
        },
        "&:focus": {
            backgroundColor: 'transparent'
        },
        "&:active": {
            backgroundColor: 'transparent'
        }
    },
});


const PaletteItemButtonAction = (props) => {
    const location = window.location.hash;
    const classes = useStyles();
    const palette = props.palette ?? props.record; // peut être l'un ou l'autre...

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '5px'
        }}>
            {props.create && <Link className={classes.btnMargin} to={{
                pathname: `/palettes/create`, state: {prevPath: location, orderPreparationId: props.orderPreparation.id}
            }}>
                <Fab size={"small"} color="default" aria-label="Créer une palette">
                    <FontAwesomeIcon icon="fa-solid fa-plus"/>
                </Fab>
            </Link>}
            {palette != null && <Link className={classes.btnMargin} to={{
                pathname: `${linkToRecord(
                    `/palettes`,
                    encodeURIComponent(palette.id)
                )}`, state: {prevPath: location, orderPreparationId: props.orderPreparation.id}
            }}>
                <Fab size={"small"} color="default" aria-label="Modifier la palette">
                    <FontAwesomeIcon icon="fa-solid fa-pen"/>
                </Fab>
            </Link>}
            {
                palette != null &&
                <ButtonPaletteMoveLocation palette={palette}/>
            }
            {
                palette != null &&
                <ButtonPalettePrint palette={palette} orderPreparation={props.orderPreparation} />
            }
            {palette != null &&
            <DeleteButtonWithConfirm size={"small"} color="default" aria-label="Supprimer la palette" className={classes.btnDelete} record={palette}
                                     label="" resource={'palettes'}/>
            }
        </Box>
    )
}

PaletteItemButtonAction.propTypes = {
    orderPreparation: PropTypes.object.isRequired,
    record: PropTypes.object,
    palette: PropTypes.object,
    create: PropTypes.bool.isRequired
};

export default PaletteItemButtonAction;
