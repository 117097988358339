import * as React from 'react'
import {CreateGuesser} from "@api-platform/admin";
import {PostEditToolbar} from "../toolbar/SaveButton";

export class CreateGuesserState {
    pristine: boolean;
}
export class CreateGuesserProps {
    resource: string;
}
export class FormCreateGuesser extends React.Component<CreateGuesserProps, CreateGuesserState> {
    constructor(props) {
        super(props);
        this.state = {
            pristine: true
        }
    }
    onChange = () => {
        this.setState({pristine: false})
    }

    render() {
        return (
            <CreateGuesser
                redirect={"/" + this.props.resource}
                resource={this.props.resource}
                toolbar={<PostEditToolbar disabled={this.state.pristine}/>}
            >
                {React.Children.map(this.props.children, c => {
                    if(React.isValidElement(c)){
                        return React.cloneElement(c, {resource:this.props.resource, onChange:this.onChange});
                    }
                })}
            </CreateGuesser>
        )
    }
}