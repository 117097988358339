import * as React from 'react'
import FieldGuesser from "@api-platform/admin/lib/FieldGuesser";
import {FormListGuesser} from "../../form/List";
import ColorFieldGuesser from "../../field/Color";
import {ReferenceArrayField, SingleFieldList} from "react-admin";
import NavetteField from "../../field/NavetteField";

export default function CarrierList(props) {
    return (
        <FormListGuesser {...props} hasShow={false}>
            <FieldGuesser source={"name"} />
            <ColorFieldGuesser source="color"/>
            <FieldGuesser source={"maxWeightPerPalette"} />
            <ReferenceArrayField reference="navettes" source="navettes" sortable={false}>
                <SingleFieldList>
                    <NavetteField hideCarrier={true} />
                </SingleFieldList>
            </ReferenceArrayField>
        </FormListGuesser>
    )
}

