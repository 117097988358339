import * as React from "react";
import PropTypes from 'prop-types';
import {Avatar, Tooltip} from "@mui/material";
import {convertNameToAvatar} from "../../utils/functionProvider";
import { withStyles} from '@material-ui/core/styles';

const styles = theme => ({
    avatarMargin: {    
    marginRight: '-20px',   
  },
});

const UserAvatarPresenter = (props) => {
    const { classes } = props;
    if(props.record == null){
        return null
    }
    if(props.type == "list"){
        return (
            <Tooltip title={props.record.firstName + ' ' + props.record.lastName}>
                <Avatar className={classes.avatarMargin} {...convertNameToAvatar(props.record.firstName + ' ' + props.record.lastName)}/>
            </Tooltip>
        );
    }else{
        return (
            <Tooltip title={props.record.firstName + ' ' + props.record.lastName}>
                <Avatar {...convertNameToAvatar(props.record.firstName + ' ' + props.record.lastName)}/>
            </Tooltip>
        );
    }
    
}

UserAvatarPresenter.propTypes = {
    record: PropTypes.object,
};

export default withStyles(styles)(UserAvatarPresenter);
