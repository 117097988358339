import {Box} from "@mui/material";
import {GetOnePalette} from "../../GetOne";
import PaletteChoice from "../../../component/box/preparation/PaletteChoice";
import PaletteWeightField from "../../../component/field/PaletteWeight";
import PaletteItemButtonAction from "../../../component/resource/Palette/Palette/ButtonAction";
import * as React from "react";

const PaletteSelector = (props) => {
    const handlePaletteChange = (value) => {
        props.setPalette(value);
        props.setLock(true);
        props.setQuantityToPrepare(0);
        props.setPalette(value);
    };

    return (
        <Box>
            <Box sx={{textAlign: 'center', fontWeight: 'bold'}}>
                <Box>Palette en cours</Box>
                <GetOnePalette id={props.palette}>
                    <PaletteChoice orderPreparation={props.orderPreparation} onChange={handlePaletteChange}/>
                    <PaletteWeightField maxWeight={props.orderPreparation.maxWeightPerPalette}/>
                    <PaletteItemButtonAction orderPreparation={props.orderPreparation} create={true}/>
                </GetOnePalette>
            </Box>
        </Box>
    )
}

export default PaletteSelector
