import {useContext} from "react";
import dataProvider from "../../../utils/dataProvider";
import Profile from "./Profile";
import * as React from "react";
import {TeamContext} from "../../context/TeamContext";

const TeamMembers = (props) => {
    const [team, setTeam] = useContext(TeamContext)

    const handleLogout = (user) => {
        dataProvider.update('users', {
            id: user.id,
            data: {
                currentOrderPreparation: null
            }
        }).then(() => setTeam([...team.filter((u) => u.id !== user.id)]));
    }

    return team.map((user) => user.id !== props.user.id ? <Profile key={user.id} user={user} handleLogout={() => handleLogout(user)} /> : null)
}

export default TeamMembers;
