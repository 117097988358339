import * as React from 'react'
import {Permission} from "../../Permission";

export default class UserTeamResourceGuesser extends React.Component {
    render() {
        return (
            <Permission {...this.props} show={null} edit={null}/>
        )
    }
}
