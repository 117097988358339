import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import * as React from "react";
import NavetteLargeResume from "../../../pages/Charge/Show/NavetteLargeResume";
import NavetteListAction from "../../../pages/Charge/Index/NavetteListAction";

const ScanNavetteDialog = (props) => {
    const navette = props.record;
    return (
        <Dialog open={true}>
            <DialogTitle>Navette : </DialogTitle>
            <DialogContent>
                <NavetteLargeResume navette={navette}/>
                <NavetteListAction label='Actions' record={navette}/>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="success" onClick={props.handleClose}>
                    Terminer
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default ScanNavetteDialog
