import * as React from "react";
import PropTypes from 'prop-types';
import {PAUSED, STARTED, STOPPED} from "../../utils/enum/StateEnum";
import StateButton from "./StateButton";

const InverseStateButton = (props) => {
    switch(props.record.code) {
        case STARTED:
            return <>
                <StateButton title="Mettre en pause" state={PAUSED} {...props} />
                <StateButton title="Arrêter" state={STOPPED} {...props} />
            </>

        case PAUSED:
            return <>
                <StateButton title="Démarrer" state={STARTED} {...props} />
                <StateButton title="Arrêter" state={STOPPED} {...props} />
            </>

        case STOPPED:
            return null;

        default:
            return null;
    }
}

InverseStateButton.propTypes = {
    record: PropTypes.object,
};

export default InverseStateButton;
