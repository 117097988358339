import * as React from 'react';
import Button from '@mui/material/Button';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import {
    useListContext,
    useNotify,
    useRefresh,
    useUnselectAll
} from 'react-admin';
import {useState} from "react";
import NavetteDialog from "../../../../dialog/NavetteDialog";
import dataProvider from "../../../../../utils/dataProvider";

export default function BulkNavetteDialogAction() {
    const {selectedIds} = useListContext();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();

    const handleValidation = (navette) => {
        setLoading(true)
        dataProvider.updateMany('order_preparations', {
            ids: selectedIds,
            data: {navettes: [navette]}
        }).then(() => {
            setOpen(false)
            setLoading(false)
            refresh();
            notify('Les préparations de commande sont mises à jour');
            unselectAll('order_preparations');
        }).catch(() => {
            setOpen(false)
            setLoading(false)
            notify('Erreur: les préparations de commande n\'ont pas pu être mis à jour', {type: 'error'})
        });
    }

    if (open) {
        return (
            <NavetteDialog loading={loading} open={open} setOpen={setOpen} handleValidation={handleValidation} />
        )
    }

    return (
        <Button startIcon={<LocalShippingIcon/>} onClick={() => setOpen(true)}>
            Navette
        </Button>

    );
}
