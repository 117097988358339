
import {Buffer} from 'buffer';
import FileSaver from "file-saver"; //npm install file-saver ou yarn add file-saver
import DescriptionIcon from '@mui/icons-material/Description';
import BallotIcon from '@mui/icons-material/Ballot';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import {useGetOne, useGetMany} from 'react-admin';
import {CircularProgress} from "@mui/material"; 

export default function Downloadpdf(props) {
    
    const type     = props.type;
    const style = {"color":"white","margin":"5px","padding":"10px","minWidth":"unset"};
    var hasPDF;
    
    //const channel  = props.preparation.channel.code;
    const { data: channel, loading, error } = useGetOne('channels', props.preparation["channel"]);
    if (loading) { return <CircularProgress size='1rem' />; }
    if (error) { return <p>ERROR</p>; }

    if(type === "Invoice"){ 
        hasPDF = (props.preparation.invoice == 1) ? true : false;
    }else{
        hasPDF = (props.preparation.deliveryslip == 1) ? true : false;
    }
    
    function clickdownload(){
        var id_order = props.preparation.orderId;
        var url = 'https://'+channel.hostname+'/api/mclogistic/order/'+id_order+'/get'+type;
        var keyapi64 = Buffer.from(channel.apiKey).toString('base64');
        downloadFilePdf(url, keyapi64, type);
    }

    
    function downloadFilePdf(url, keyapi64){
        var filename;
        fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {
                Authorization: 'Basic ' + keyapi64,
            }
          })
        .then(response => {
               if(response.headers.get("content-disposition").includes('attachment; filename="')){                    
                  filename = response.headers.get("content-disposition").replace('attachment; filename="', '').replace('"', '');
               }
               return response.blob();
         })
        .then(response => {
            FileSaver.saveAs(response, filename);
            
        })
        .catch(err => console.error(err));
    }

    return btn(style, clickdownload, type, hasPDF);
}

export function DownloadpdfList(props) {
    
    const type     = props.type;
    const navette = props.navette;
    const style = {"color":"white","margin":"5px","padding":"10px","minWidth":"unset"};
    var hasPDF = true; // pour être plus précis, il faudrait vérifier si au moins une préparation de la liste a un PDF
    
    //console.log(navette);

    //const channel  = props.preparation.channel.code;
    // const { data: channel, loading, error } = useGetOne('channels', props.preparation["channel"]);
//     let ids_channels    = [];
// console.log(navette.channels);
//     navette.channels.map((channel) => {
//         // ids_channels.push(channel.split("/")[2]);
//         ids_channels.push(channel);
//     });
// console.log(ids_channels);

//     let ids_preparations = [];
// console.log(navette.orderPreparations);
//     navette.orderPreparations.map((preparation) => {
//         // ids_preparations.push(preparation.split("/")[2]);
//         ids_preparations.push(preparation);
//     });
// console.log(ids_preparations);

    if(!(navette.orderPreparations.length > 0)){
        hasPDF=false; // désactivation du btn si pas de préparation
    }

    // const { data: channels, loading, error } = useGetMany('channels', ids_channels);
    const { data: channels, loading, error } = useGetMany('channels', navette.channels);
    // const {data: preparations, loading2, error2} = useGetMany('order_preparations', ids_preparations);
    const {data: preparations, loading2, error2} = useGetMany('order_preparations', navette.orderPreparations);
    
    if (loading || loading2) { return <CircularProgress size='1rem' />; }
    if (error || error2) { return <p>ERROR</p>; }
    
    function clickdownload(){
       downloadPDFListingByNavette(type);
    }

    // function downloadPDFListingByNavette(id_nav, type){
    function downloadPDFListingByNavette(type){
        // TODO: refactoriser le code pour ne pas avoir les code de channel en dur en supprimant les lignes de déclaration ES/OC et en testant si listeOrders[channel.code] est null 
        var listOrders = new Array();
        listOrders['ES'] = new Array(); //les non déclaré s'ajouterons tout seul à la ligne 109 exemple si un nouveau channel viens à se mettre (c'est juste pour evité d'avoir une erreur car défois la boucle commence sur OC et d'autre sur ES et si OC n'est pas défini dans le tableau le length provoque une erreur plus bas)
        listOrders['OC'] = new Array();
        preparations.forEach(function(preparation){
            if(preparation == null){return;}
            channels.forEach(function(channellist){
                if(channellist['@id'] == preparation.channel){
                    if(listOrders[channellist.code] == null){
                        listOrders[channellist.code] = new Array();
                    }

                    // faire un push de l'objet preparation.orderId si not include  (pour éviter les doublons)
                    if(!listOrders[channellist.code].includes(preparation.orderId)){
                        listOrders[channellist.code].push(preparation.orderId);
                    }
                }
            });
            
        });


        channels.forEach(function(channel){
            
            if(listOrders[channel.code].length > 0){ // si la navette contient des commandes pour ce channel
                var url      = channel.hostname;
                var apikey   = channel.apiKey;
                var url      = 'https://'+url+'/api/mclogistic/get' + type + 's';
                var keyapi64 = Buffer.from(apikey).toString('base64');
                downloadListFilePdf(url, keyapi64, listOrders[channel.code]);
            }
        });
    }

    function downloadListFilePdf(url, keyapi64, list) {
        var filename;
        let formData = new FormData();
        var listing = "";
        list.forEach(function(order_id){
            listing+= order_id +",";
        });
        listing = listing.slice(0, -1);
        formData.append('list', listing);

        fetch(url, {
            method: 'POST',
            body: formData,
            mode: 'cors',
            headers: {
                Authorization: 'Basic ' + keyapi64,
            }
        })
            .then(response => {
                if(response.headers.get("content-disposition").includes('attachment; filename="')){    
                    filename = response.headers.get("content-disposition").replace('attachment; filename="', '').replace('"', '');
                }
                return response.blob();
            })
            .then(response => {
                FileSaver.saveAs(response, filename);
            })
            .catch(err => console.error(err));
    }

    return btn(style, clickdownload, type, hasPDF);    
}

function btn(style, clickdownload, type, hasPDF){
    if(hasPDF === true){
        if(type === "Invoice"){
            return (
                <section>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Tooltip title="Facture" arrow>
                        <Button style={style} onClick={clickdownload} variant="contained" component="label" size="small">
                            <DescriptionIcon />
                        </Button>
                    </Tooltip>
                  </Stack>
                </section>
                
                
            );
        }else{
            return (
                <section>
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Tooltip title="Bon de livraison" arrow>
                        <Button style={style} onClick={clickdownload} variant="contained" component="label" size="small">
                            <BallotIcon />
                        </Button>
                    </Tooltip>
                </Stack>
                </section>
            );
        }
        
    }else{ //nopdf
        if(type === "Invoice"){
            return (
                <section>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Tooltip title="Facture" arrow>
                        <Button disabled style={style} variant="contained" component="label" size="small">
                            <DescriptionIcon />
                        </Button>
                    </Tooltip>
                  </Stack>
                </section>
            );
        }else{
            return (
                <section>
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Tooltip title="Bon de livraison" arrow>
                        <Button disabled style={style} variant="contained" component="label" size="small">
                            <BallotIcon />
                        </Button>
                    </Tooltip>
                </Stack>
                </section>
            );
        }
        
    }
    
}

  