import * as React from 'react'
import {InputNameGuesser} from "../../../input/Name";
import {InputColorPickerGuesser} from "../../../input/ColorPicker";
import {InputIconPickerGuesser} from "../../../input/IconPicker";
import {FormCreateGuesser} from "../../../form/Create";

export class OrderPreparationStateCreateState {}
export class OrderPreparationStateCreateProps {}
export class OrderPreparationStateCreate extends React.Component<OrderPreparationStateCreateProps, OrderPreparationStateCreateState> {
    render() {
        return (
            <FormCreateGuesser {...this.props}>
                <InputNameGuesser source={'name'} autoFocus/>
                <InputColorPickerGuesser source={'color'}/>
                <InputIconPickerGuesser source={'icon'}/>
            </FormCreateGuesser>
        )
    }
}
