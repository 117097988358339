import * as React from 'react'
import {Box} from "@mui/material";
import {CreditCard} from "@mui/icons-material";
import PaymentField from "../../../../field/PaymentField";
import NumberField from "../../../../field/NumberField";
import OrderPreparationStepField from "../../../../field/OrderPreparationStep";
import PreparationIdentity from "../../../../box/preparation/PreparationIdentity";
import PreparationNumber from "../../../../box/preparation/PreparationNumber";
import PreparationCarrier from "../../../../box/preparation/PreparationCarrier";
import BackButton from "../../../../button/BackButton";
import OrderPreparationStartStep from "../List/ButtonAction";
import StatePresenter from "../../../../presenter/StatePresenter";
import ButtonCustomerPopup from './ButtonCustomerPopup';
import ButtonTrackingPopup from './ButtonTrackingPopup';

import {
    ReferenceField,
} from "react-admin";
import Downloadpdf from '../../../../module/btnDownloadPDFfromPresta';

export class MenuTopState {}

export class MenuTopProps {
    resource: string;
    record: Object;
}

export class MenuTop extends React.Component<MenuTopProps, MenuTopState> {
    render() {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2}}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexGrow: 1}}>
                    <Box>
                        {/* eslint-disable-next-line no-script-url */}
                        <BackButton/>
                    </Box>

                    <PreparationNumber record={this.props.record} />
                    <PreparationIdentity vertical={true} record={this.props.record} />
                    
                    <Box sx={{ display: 'flex', flexDirection: 'row', m: 1 }}>
                        <Downloadpdf sx={{m: 1}} type={"Invoice"} preparation={this.props.record} />
                        <Downloadpdf sx={{m: 1}} type={"DeliverySlip"} preparation={this.props.record}/> 
                        <ButtonCustomerPopup sx={{m: 1}} preparation={this.props.record}/>   
                        <ButtonTrackingPopup sx={{m: 1}} preparation={this.props.record} />
                    </Box>
                    
                    <PreparationCarrier record={this.props.record} />

                    <Box sx={{textAlign: 'center', display: 'flex', flexDirection: 'row'}}>
                        <CreditCard/>
                        <PaymentField record={this.props.record}/>
                    </Box>

                    <Box>
                        <NumberField hiddenLabel={false} resource={this.props.resource} record={this.props.record} source="priority" />
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px' }}>
                        <OrderPreparationStepField chip={true} hiddenLabel={false} hiddenIcon={false} record={this.props.record} source="step" />
                        <ReferenceField record={this.props.record} reference={"order_preparation_states"} source={"state"} link={false} sortBy={'state.code'}>
                            <StatePresenter hiddenLabel={false} />
                        </ReferenceField>
                        <OrderPreparationStartStep record={this.props.record} eye={false}/>
                    </Box>
                </Box>
            </Box>
        )
    }
}

