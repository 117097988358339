import * as React from 'react'
import {InputNameGuesser} from "../../../input/Name";
import {InputColorPickerGuesser} from "../../../input/ColorPicker";
import {FormCreateGuesser} from "../../../form/Create";
import {InputGuesser} from "@api-platform/admin";

export class OrderPreparationStateCreateState {}
export class OrderPreparationStateCreateProps {}
export class OrderPreparationStateCreate extends React.Component<OrderPreparationStateCreateProps, OrderPreparationStateCreateState> {
    render() {
        return (
            <FormCreateGuesser {...this.props}>
                <InputNameGuesser source={'name'} autoFocus/>
                <InputColorPickerGuesser source={'color'}/>
                <InputGuesser source={'private'}/>
            </FormCreateGuesser>
        )
    }
}
