import {ChipField, Datagrid, FunctionField, ReferenceField, ReferenceManyField, TextField} from "react-admin";
import GetOne from "../../../component/field/GetOne";
import * as React from "react";
import NumberField from "../../../component/field/NumberField";
import LockField from "./LockField";
import SetNavetteButton from "../../../component/button/SetNavetteButton";
import {useState} from "react";
import {convertWeightToString} from "../../../utils/functionProvider";

import ChannelAndOrderIdPresenter from "../../../component/presenter/ChannelAndOrderIdPresenter";

const ButtonsAction = (props) => (
    <SetNavetteButton palette={props.record} />
)

const ListPalettes = (props) => {
    const volumes = [];
    props.navette.palettes.forEach((p) => volumes[p] = -1);
    const [volume, setVolume] = useState(volumes);

    const handleVolume = (data) => {
        volumes[data.id] = data.volume;
        setVolume(volumes)
    }
    return (
        <ReferenceManyField
            record={props.navette}
            reference="palettes"
            target="navette.id"
            label="Palettes"
            sort={{field: 'orderPreparation.orderId', order: 'ASC'}}
        >
            <Datagrid>
                <FunctionField
                    label="Palette n°"
                    render={record => `${record.id.replace('/palettes/', '')}`}
                />
                <ReferenceField label="Commande" reference={'order_preparations'} source={'orderPreparation'} link={true} linkType="show">
                    <ChannelAndOrderIdPresenter label='Canal' sortBy="channel.code"/>
                </ReferenceField>
                <TextField source="number" sortBy={"number"} />
                <GetOne source="products" recordData="type" dataName="name" label='Type'/>
                <TextField source="products"/>
                <NumberField handleData={handleVolume} source="length"/>
                <NumberField handleData={handleVolume} source="width"/>
                <NumberField handleData={handleVolume} source="height"/>
                <FunctionField
                    label="Volume m³"
                    render={ record => volume[record.id] === -1 ? record.volume : volume[record.id] }
                />
                <FunctionField
                    label="Poids"
                    render={ record => convertWeightToString(record.weight) }
                />
                <ButtonsAction label={"Actions"} {...props} />
                <LockField label={"Emplacement"} navette={props.navette} setNavette={props.setNavette} hideLabel={true}/>
            </Datagrid>
        </ReferenceManyField>
    )
}

export default ListPalettes;
