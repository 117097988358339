import {useNotify, usePermissions} from "react-admin";
import {Redirect} from "react-router-dom";
import {ResourceGuesser} from "@api-platform/admin";
import * as React from "react";

export const Permission = (props) => {
    const notify = useNotify();
    const { permissions } = usePermissions();

    if(!permissions)
        return null;


    if(permissions && permissions.includes('ROLE_ADMIN')){
        switch(props.name) {
            case 'order_preparation_states':
            case 'order_preparation_steps':
            case 'order_preparation_items':
            case 'palettes':
            case 'palette_items':
            case 'order_preparations':
            case 'channels':
            case 'agencies':
            case 'brands':
            case 'warehouses':
            case 'products':
            case 'carriers':
            case 'product_categories':
            case 'warehouse_locations':
            case 'navettes':
            case 'users':
            case 'user_souffrances':
            case 'scan_logs':
                return <ResourceGuesser {...props}/>
            default:
                return null;
        }
    }

    if(permissions && permissions.includes('ROLE_RESPONSABLE')){
        switch (props.name) {
            //READ ONLY WITH LIST
            case 'order_preparations':
            case 'products':
                return <ResourceGuesser {...props} create={null} edit={null}/>
            //READ ONLY WITHOUT LIST
            case 'order_preparation_states':
            case 'order_preparation_steps':
            case 'order_preparation_items':
            case 'channels':
            case 'agencies':
            case 'brands':
            case 'palette_items':
            case 'product_categories':
                return <ResourceGuesser {...props} create={null} edit={null} list={false}/>
            //READ WRITE WITHOUT LIST
            
            case 'warehouses':
            case 'warehouse_locations':
            case 'navettes':
            case 'palettes':
            case 'user_souffrances':
            case 'carriers':
            case 'users':
                return <ResourceGuesser {...props}/>
            default:
                return null;
        }
    }

    if(permissions && permissions.includes('ROLE_LOGISTICIEN')){
        switch (props.name) {
            //READ ONLY WITHOUT LIST
            case 'order_preparations':
            case 'carriers':
            case 'order_preparation_states':
            case 'order_preparation_steps':
            case 'order_preparation_items':
            case 'channels':
            case 'products':
            case 'palette_items':
                return <ResourceGuesser {...props} create={null} edit={null} list={null}/>
            //READ AND WRITE
            case 'navettes':
            case 'palettes':
                return <ResourceGuesser {...props}/>
            default:
                return <Redirect to={'/'}/>;
        }
    }

    notify(`Permissions non trouvé pour ${props.name} et permissions ${permissions}`, 'error')
    return null;
}
