import * as React from "react";
import {
    Box,
    Divider
} from "@mui/material";

import {useState} from "react";
import PreparationLargeResume from "../../component/box/preparation/PreparationLargeResume";
import {GetOneOrderPreparation, GetOnePalette} from "../GetOne";
import ButtonFinishPreparation from "../Prepare/Show/ButtonFinish";
import BreadcrumbPalette from "./Show/BreadcrumbPalette";
import ControlList from "./Show/ControlList";
import ButtonFinishPalette from "./Show/ButtonFinishPalette";
import ButtonPaletteMoveLocation from "../../component/resource/Palette/Palette/ButtonMoveLocation";
import {Title, useVersion} from "react-admin";
import ButtonPalettePrint from "../../component/resource/Palette/Palette/ButtonPrint";

const ShowWithPalette = (props) => {
    return (
        <>
            <Box sx={{display: 'flex', justifyContent: 'space-around', alignContent: 'bottom', mb: 2, mt: 2}}>
                <ButtonPaletteMoveLocation palette={props.palette}/>
                <ButtonPalettePrint palette={props.palette} orderPreparation={props.orderPreparation} />
            </Box>
            <Divider/>

            <ControlList
                palette={props.palette}
                setPalette={props.setCurrentPalette}
            />
            <Divider/>

            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <BreadcrumbPalette
                    orderPreparation={props.orderPreparation}
                    palette={props.palette}
                    setPalette={props.setCurrentPalette}
                />

                <ButtonFinishPalette
                    palette={props.palette}
                    setPalette={props.setCurrentPalette}/>
            </Box>
        </>
    )
}

const ShowWithOrderPreparation = (props) => {
    const version = useVersion();
    return (
        <>
            <PreparationLargeResume orderPreparation={props.orderPreparation}/>
            <Divider/>
            <ButtonFinishPreparation
                orderPreparation={props.orderPreparation}
                step='control'
                paletteId={props.paletteId}/>
            <Divider/>

            <GetOnePalette key={version}
                id={props.palette == null ? props.orderPreparation.palettes[0] : props.palette.id}>
                <ShowWithPalette
                    orderPreparation={props.orderPreparation}
                    setPalette={props.setPalette}
                    setCurrentPalette={props.setPalette}/>
            </GetOnePalette>
        </>
    );
}

const OrderPreparationControlShow = (props) => {
    const [palette, setPalette] = useState(null);

    return (
        <>
            <Title title={"Contrôler"}/>
            <GetOneOrderPreparation id={decodeURIComponent(props.match.params.id)}>
                <ShowWithOrderPreparation
                    palette={palette}
                    setPalette={setPalette}
                />
            </GetOneOrderPreparation>
        </>
    )
}

export default OrderPreparationControlShow;
