import {Chip} from "@mui/material";
import * as React from "react";
import PropTypes from 'prop-types';

const AttributePresenter = (props) => {
    if(props.value === "? : ?"){
        return null;
    }
    return (
        <Chip variant="outlined" sx={{marginRight: 1, marginBottom: 1}} label={props.value} />
    )
};

AttributePresenter.propTypes = {
    value: PropTypes.any
};

export default AttributePresenter;
