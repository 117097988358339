import {Box} from "@mui/material";
import PropTypes from "prop-types";
import * as React from "react";
import {getColors} from './SquareData'

const SmallSquareData = (props) => (
    <Box sx={Object.assign({}, {
        display: 'flex',
        flexDirection: 'column',
        fontSize: 18,
        fontWeight: 'bold',
        justifyContent: 'center',
        alignItems: 'center',
        width: (props.bigger ? '75px' : '50px'),
        height: (props.bigger ? '75px' : '50px'),
        minWidth: (props.bigger ? '75px' : '50px'),
        minHeight: (props.bigger ? '75px' : '50px'),
        marginTop: 1
    }, getColors(props.color), props.sx)}>
        {props.subTitle ? props.value : props.icon}
        {props.subTitle ?
            (<Box component="span" sx={{fontSize: 12}}>{props.subTitle}</Box>) :
            <Box>{props.value}</Box>
        }
    </Box>
);

SmallSquareData.propTypes = {
    subTitle: PropTypes.string,
    value: PropTypes.any,
    color: PropTypes.string,
    icon: PropTypes.object
};

export default SmallSquareData;