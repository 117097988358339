import * as React from 'react';
import Button from '@mui/material/Button';
import EventNoteIcon from '@mui/icons-material/EventNote';
import {
    useListContext,
    useNotify,
    useRefresh,
    useUnselectAll,
    useUpdateMany
} from 'react-admin';
import {CircularProgress} from "@mui/material";

export default function BulkPlanned() {
    const {selectedIds} = useListContext();

    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const [updateMany, {loading}] = useUpdateMany(
        'order_preparations',
        selectedIds,
        {step: '/order_preparation_steps/prepare'},
        {
            onSuccess: () => {
                refresh();
                notify('Les préparations de commande sont mises à mise à jour');
                unselectAll('order_preparations');
            },
            onFailure: () => notify('Erreur: les préparations de commande n\'ont pas pu être mis à jour', {type: 'warning'}),
        }
    );

    return (
        <Button startIcon={loading ? <CircularProgress/> : <EventNoteIcon/>} onClick={updateMany} disabled={loading}
                color='success'>
            A préparer
        </Button>
    );
}
