import * as React from 'react'
import FormEditGuesser from "../../form/Edit";
import InputGuesser from "@api-platform/admin/lib/InputGuesser";
import {InputColorPickerGuesser} from "../../input/ColorPicker";

export default function CarrierEdit(props) {
    return (
        <FormEditGuesser {...props}>
            <InputGuesser source={"name"} />
            <InputColorPickerGuesser source={'color'}/>
            <InputGuesser source={"maxWeightPerPalette"} />
        </FormEditGuesser>
    )
}

