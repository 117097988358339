import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import {IconButton} from "@mui/material";
import {ArrowBack} from "@mui/icons-material";

class BackButton extends Component {
    handleClick = () => {
        window.history.back();
    };

    render() {
        return <IconButton onClick={this.handleClick} sx={{mr: 1}} color="primary" aria-label="back">
            <ArrowBack/>
        </IconButton>;
    }
}

export default BackButton;
