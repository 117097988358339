import {Datagrid, ReferenceManyField, TextField, FunctionField} from "react-admin";
import GetOne from "../../../component/field/GetOne";
import * as React from "react";
import LockField from "./LockField";
import NumberField from "../../../component/field/NumberField";

const OrderPreparationGridPalettes = (props) => {
    return (
        <ReferenceManyField
            record={props.orderPreparation}
            reference="palettes"
            target="orderPreparation.id"
            label="Palettes"
        >
            <Datagrid>
                <TextField source="number"/>
                <GetOne orderprep={props.orderPreparation} seeInside={true} source="palettes" recordData="type" dataName="name" label='Type'/>
                {/*<GetOne seeInside={true} dataSeeInside={props.orderPreparation} source="products" recordData="type" dataName="name" label='Type'/>*/}
                <TextField source="products"/>
                <NumberField source="length"/>
                <NumberField source="width"/>
                <NumberField source="height"/>
                <FunctionField label={"Volume"} render={record => parseFloat(((record.length/100) * (record.width/100) * (record.height/100))).toFixed(2) + " m³"} />
                <FunctionField label={"Poids"} render={record => record.weight/1000 + " kg"} />
                {/*<SpotPresenter source='spot'/>*/}
                <LockField orderPreparation={props.orderPreparation} setOrderPreparation={props.setOrderPreparation}/>
            </Datagrid>
        </ReferenceManyField>
    )
}

export default OrderPreparationGridPalettes;
