import * as React from "react";
import Card from '@material-ui/core/Card';
import {Title} from 'react-admin';
import {Box} from "@mui/material";

const AdminParameter = () => {
    return (
        <Card>
            <Title title="Parameter"/>
            <Box>

            </Box>
        </Card>
    );
};
export default AdminParameter;
