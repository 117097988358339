import {useState} from "react";
import ButtonPaletteMoveLocation from "../../../component/resource/Palette/Palette/ButtonMoveLocation";
import * as React from "react";
import ButtonCharge from "./ButtonCharge";
import {Alert} from "@mui/material";
import Button from "@mui/material/Button";
import dataProvider from "../../../utils/dataProvider";


const LockField = (props) => {
    const [charged, setCharged] = useState(props.record.charged)

    function handleRemoveChargement(){
        setCharged(false);
        dataProvider.update(
            'palettes', {
                id: props.record.id,
                data: {
                    'charged': false
                }
            }
        ).then(
            () => {
                dataProvider.getOne('navettes', {id: props.navette.id}).then(
                    ({data}) => {
                        props.setNavette(data);
                    }
                )
            }
        )
    }

    if (charged){
        return (
            <Alert severity="success" style={{alignSelf: "center", alignItems: "center"}}>
                    <div style={{display: "flex", alignSelf: "center", alignItems: "center"}}>
                        <span>Chargé</span> 
                        <Button onClick={handleRemoveChargement} color="error" variant="outlined" style={{width: "32px", minWidth: "32px", height: "32px", borderRadius: "32px", marginLeft: "5px"}}>X</Button>
                    </div>
            </Alert>
        );
    }

    return (
        <>
            <ButtonCharge record={props.record} navette={props.navette} setNavette={props.setNavette} setCharged={setCharged}/>
            <ButtonPaletteMoveLocation palette={props.record}/>
        </>
    )
}

export default LockField;
