import * as React from "react";
import {FormListGuesser} from "../../component/form/List";
import {
    DateField,
    ReferenceArrayField,
    ReferenceField,
    TextField,
    SingleFieldList, 
    ArrayField, 
    usePermissions,
    TextInput,
    BooleanInput,
    ReferenceArrayInput
} from "react-admin";
import FilterSidebar from "../../component/resource/Navette/List/Filters";
import ChannelPresenter from "../../component/presenter/Channel";
import Counter from "../../component/field/Counter";
import NavetteListAction from "./Index/NavetteListAction";
import AgencePresenter from "../../component/presenter/Agence";
import BooleanField from "../../component/field/Boolean";
import IdFieldGuesser from "../../component/field/Id";


const postFilters = [
    <TextInput label="N°" source="id" reference="navettes" alwaysOn />
];

const OrderPreparationChargeIndex = (props) => {
    const {permissions} = usePermissions();

    if(!permissions)
        return null;

    return (
        <FormListGuesser filters={postFilters}
                         resource={'navettes'} basePath={'/navettes'} aside={<FilterSidebar/>} hasShow={false}
                         hasCreate={permissions.includes('ROLE_RESPONSABLE')}
                         hasEdit={permissions.includes('ROLE_RESPONSABLE')}
        >
            <IdFieldGuesser source="id"/>
            <ReferenceField reference={"carriers"} source={"carrier"} link={false}>
                <TextField source="name"/>
            </ReferenceField>
            <Counter source="palettes"/>
            <DateField source="createdAt" locales="fr-FR" options={{
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
            }}/>
            <DateField source="estimatedAt" locales="fr-FR" options={{
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
            }}/>
            <BooleanField source="charged"/>
            <ReferenceArrayField reference="channels" source="channels">
                <SingleFieldList>
                    <ChannelPresenter/>
                </SingleFieldList>
            </ReferenceArrayField>
            <ArrayField source="agences">
                <AgencePresenter/>
            </ArrayField>
            <NavetteListAction label='Actions'/>
        </FormListGuesser>
    );
};

export default OrderPreparationChargeIndex;
