import {useState} from "react";
import dataProvider from "../../../../../utils/dataProvider";
import {CreateButton, downloadCSV, ExportButton, TopToolbar} from "react-admin";
import {ImportButton} from "react-admin-import-csv";
import * as React from "react";
import jsonExport from 'jsonexport/dist';

const ROW_DELIMITER = ';'


const exporter = (records, fetchRelatedRecords) => {
    fetchRelatedRecords(records, 'warehouse', 'warehouses').then(warehouses => {
        const locationsForExport = records.map(record => {
            return ({
                id: record.id.split('/').pop(),
                product: record.product.split('/').pop(),
                warehouse: warehouses[record.warehouse].name,
                code: record.code,
                color: record.color,
                quantity: record.quantity,
                priority: record.priority,
                comment: record.comment,
            })
        });

        jsonExport(locationsForExport, {rowDelimiter: ROW_DELIMITER}, (err, csv) => {
            const BOM = '\uFEFF' // utf8 support
            downloadCSV(`${BOM}${csv}`, 'warehouse_locations');
        });
    });
};

const ListActions = (props) => {
    const [loadingWL, setLoadingWL] = useState(false);
    const [wl, setWL] = useState(null);

    if(!loadingWL && wl === null) {
        setLoadingWL(true)
        dataProvider.getList('warehouses', {
            pagination: {page: 1, perPage: 999},
            sort: {field: 'id', order: 'ASC'},
            filter: {},
        }).then(({data}) => {
            setWL(data)
            setLoadingWL(false)
        });
    }

    const { className } = props;
    const config: ImportConfig = {
        logging: true,
        disableUpdateMany: false,
        transformRows: (csvRows) => {
            return csvRows.map((row) => {
                row['product'] = '/products/' + row['product'].toString().replace(' ', '')
                row['@type'] = 'WarehouseLocation'

                let warehouse = wl.filter((w) => w.name === row['warehouse']).pop();
                if(!warehouse) {
                    //shouldn't happen
                    console.error("warehouse doesn't exist, but this problem should never happen")
                } else {
                    row['warehouse'] = warehouse.id
                }
                return row;
            });
        },
        parseConfig: {
            delimiter: ROW_DELIMITER,
            dynamicTyping: true
            // For all options see: https://www.papaparse.com/docs#config
        }
    }
    return (
        <TopToolbar className={className}>
            <CreateButton/>
            <ExportButton exporter={exporter}/>
            { loadingWL || wl === null ? null : <ImportButton {...props} {...config} /> }
        </TopToolbar>
    );
};

export default ListActions;