import * as React from 'react'
import FieldGuesser from "@api-platform/admin/lib/FieldGuesser";
import {FormListGuesser} from "../../form/List";

const Image = (props) => <img style={{maxWidth: '100px', height: 'auto'}} src={props.record[props.source]} alt={""}/>

export default function BrandList(props) {
    return (
        <FormListGuesser {...props} hasShow={false}>
            <FieldGuesser source={"code"} />
            <FieldGuesser source={"name"} />
            <Image source={"logo"}/>
        </FormListGuesser>
    )
}

