import {ReferenceField, TextField} from "react-admin";
import ChannelPresenter from "./Channel";
import * as React from "react";
import {Box} from "@mui/material";

import {useState} from "react";

const ChannelAndOrderIdPresenter = (props) => {
    const [colorBorder, setColorBorder] = useState("1px solid black");
    return <Box border={colorBorder} borderRadius="5px" display="flex" alignItems="center">
        <ReferenceField record={props.record} reference={"channels"} source={"channel"} link={false} sortBy="channel.name">
            <ChannelPresenter setColorBorder={setColorBorder}/>
        </ReferenceField>
        <TextField style={{marginLeft:'auto',marginRight:'auto', color: 'black'}} record={props.record} source="orderId"/>
    </Box>;
}

export default ChannelAndOrderIdPresenter
