const domainMessages = {
    labels: {
        'planifier': 'Planifier'
    },
    app: {
        page: {
            edit: "Modifier %{name}"
        }
    },
    resources: {
        agencies: {
            name: 'Agences',
            fields: {
                code: 'Code',
                name: 'Nom',
                address: 'Adresse',
                phone: 'Téléphone'
            }
        },
        brands: {
            name: 'Marques',
            fields: {
                code: 'Code',
                name: 'Nom',
                logo: 'Logo (Base64)'
            }
        },
        order_preparations: {
            name: 'Commandes',
            fields : {
                id: 'N°',
                channel: 'Canal',
                orderId: 'Commande',
                step: 'Étape',
                state: 'État',
                carrier: 'Transport',
                payment: 'Paiement',
                priority: 'Priorité',
                planned: 'Planifié',
                progress: 'Avancement',
                assignment: 'Assigné',
                usersHistories: 'Equipe',
                spot: 'Place',
                totalProducts: 'Produits',
                totalProductsQuantity: 'Quantité',
                totalPalettes: 'Palettes',
                currentStep: 'Etape actuelle'
            }
        },
        order_preparation_states: {
            name: 'État des commandes',
            filter_name: 'États',
            fields : {
                name: 'Nom',
                color: 'Couleur',
                icon: 'Icône'
            }
        },
        order_preparation_steps: {
            name: 'Étape des commandes',
            filter_name: 'Étapes',
            fields : {
                name: 'Nom',
                color: 'Couleur',
                private: 'Privée'
            }
        },
        order_preparation_items: {
            name: 'Préparation Produits',
            fields : {
                priority: 'Ordre',
                warehouseLocation: 'Place',
                productImage: 'Image',
                product: 'Produit',
                prepared: 'Progression',
                quantityTotal: 'Quantité',
                progress: 'Avancement'
            }
        },
        channels: {
            name: 'Canaux',
            filter_name: 'Canaux'
        },
        warehouses: {
            name: 'Dépôts',
            fields: {
                code: 'Code',
                name: 'Nom',
                color: 'Couleur'
            }
        },
        warehouse_locations: {
            name: 'Emplacement Entrepôt',
            fields : {
                warehouse: 'Entrepôt',
                product: 'Produit',
                code: 'Code',
                color: 'Couleur',
                quantity: 'Quantité',
                priority: 'Priorité',
                comment: 'Commentaire'
            }
        },
        users: {
            name: 'Utilisateurs',
            fields : {
                email: 'Email',
                password: 'Mot de passe'
            }
        },
        user_teams: {
            name: 'Equipes',
        },
        user_souffrances: {
            name: 'Souffrances',
        },
        palettes: {
            name: 'Palettes',
            fields : {
                ean: 'EAN',
                number: 'Numéro',
                type: 'Type',
                products: 'Qty Produits',
                length: 'Longueur cm',
                width: 'Largeur cm',
                height: 'Hauteur cm',
                volume: 'Volume m3',
                weight: 'Poids g',
                spot: 'Emplacement',
                controlled: 'Controllé',
                charged: 'Chargé',
                packed: 'Emballé'
            }
        },
        palette_items: {
            fields: {
                quantity: 'Qté',
                controlled: 'Controlé',
                palette: 'Palette'
            }
        },
        navettes: {
            name: 'Navettes',
            fields : {
                carriers: 'Transporteur',
                carrier: 'Transporteur',
                estimatedAt: 'Arrivée le',
                orderPreparations: 'Commandes',
                charged: 'Chargé'
            }
        },
        products: {
            name: 'Catalogue',
            fields: {
                name: 'Nom',
                attributes: 'Caractéristiques',
                ean: 'EAN',
                packagingUnit: 'Unité de conditionnement',
                packagingType: 'Type de conditionnement',
                packagingQuantity: 'Quantité de conditionnement',
                weight: 'Poids (g)',
                length: 'Longueur',
                width: 'Largeur',
                height: 'Hauteur',
                image: 'Image',
                comment: 'Notes',
                category: 'Catégorie',
                locations: 'Emplacements',
                palettes: 'Palettes',
                palette: 'Type palette'
            }
        },
        carriers: {
            filter_name: 'Transporteurs',
            name: 'Transporteurs',
            fields: {
                name: 'Nom',
                color: 'Couleur',
                maxWeightPerPalette: 'Poids maximum par palette (g)'
            }
        }
    }
};
export default domainMessages;
