import * as React from "react";
import PropTypes from "prop-types";
import {convertWeightToString} from "../../utils/functionProvider";
import {Box} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ProductWeightField = (props) =>
    <Box component="span" className={'IconAlignWithText'} sx={{display: 'inline-flex', float: 'right'}}>
        <FontAwesomeIcon icon="fa-solid fa-weight-hanging"/>
        {convertWeightToString(props.product.weight)} / {props.product.packagingUnit.toLowerCase()}
    </Box>

ProductWeightField.propTypes = {
    product: PropTypes.any
}

export default ProductWeightField;