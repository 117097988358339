import {
    HydraAdmin
} from "@api-platform/admin";
import * as React from 'react'

import {Helmet, HelmetProvider} from 'react-helmet-async';
import {META} from "./config/meta";
import {ENTRYPOINT} from "./config/entrypoint";
import routes from './config/routes';

import {AdminLayout} from "./component/layout/AdminLayout";

import authProvider from "./utils/authProvider";
import dataProvider from "./utils/dataProvider";
import i18nProvider from "./utils/i18nProvider";

import OrderPreparationStateResourceGuesser from "./component/resource/Order/OrderPreparationState/Resource";
import OrderPreparationStepResourceGuesser from "./component/resource/Order/OrderPreparationStep/Resource";
import ResourceGuesser from "@api-platform/admin/lib/ResourceGuesser";
import OrderPreparationResourceGuesser from "./component/resource/Order/OrderPreparation/Resource";
import OrderPreparationItemResourceGuesser from "./component/resource/Order/OrderPreparationItem/Resource";
import PaletteResourceGuesser from "./component/resource/Palette/Palette/Resource";
import ChannelResourceGuesser from "./component/resource/Channel/Resource";
import WarehouseResourceGuesser from "./component/resource/Warehouse/Warehouse/Resource";
import WarehouseLocationResourceGuesser from "./component/resource/Warehouse/WarehouseLocation/Resource";
import ProductResourceGuesser from "./component/resource/Product/Product/Resource";
import ProductCategoryResourceGuesser from "./component/resource/Product/Category/Resource";
import CarrierResourceGuesser from "./component/resource/Carrier/Resource";
import NavetteResourceGuesser from "./component/resource/Navette/Resource";
import UserTeamResourceGuesser from "./component/resource/User/Team/Resource";
import PaletteItemResourceGuesser from "./component/resource/Palette/PaletteItem/Resource";

import ScanLogResourceGuesser from "./component/resource/ScanLog/Resource";
import UserResourceGuesser from "./component/resource/User/User/Resource";
import {UserContextProvider} from "./component/context/UserContext";
import DashboardIndex from "./pages/Dashboard/Index";
import {ScanContextProvider} from "./component/context/ScanContext";
import MediaObjectsCreate from "./component/resource/MediaObjects/Create";
import AgencyResourceGuesser from "./component/resource/Agency/Resource";
import BrandResourceGuesser from "./component/resource/Brand/Resource";
import {TeamContextProvider} from "./component/context/TeamContext";

import Login from './pages/Login/Login.js';
import './global.css'

const Admin = (props) => (
    <UserContextProvider value={null}>
        <TeamContextProvider value={[]}>
            <ScanContextProvider>
                <HelmetProvider>
                    <Helmet>
                        <html lang="fr"/>
                        <title>{META.title}</title>
                    </Helmet>


                    <HydraAdmin loginPage={Login} layout={AdminLayout} dashboard={DashboardIndex} customRoutes={routes}
                                i18nProvider={i18nProvider} dataProvider={dataProvider} authProvider={authProvider}
                                entrypoint={ENTRYPOINT}>
                        <UserResourceGuesser name={"users"}  {...props}/>
                        <OrderPreparationStateResourceGuesser name={'order_preparation_states'} {...props}/>
                        <OrderPreparationStepResourceGuesser name={'order_preparation_steps'} {...props}/>
                        <OrderPreparationResourceGuesser name={"order_preparations"} {...props}/>
                        <OrderPreparationItemResourceGuesser name={"order_preparation_items"} {...props}/>
                        <PaletteResourceGuesser name={"palettes"} {...props}/>
                        <PaletteItemResourceGuesser name={"palette_items"} {...props}/>
                        <ChannelResourceGuesser name={"channels"} {...props}/>
                        <WarehouseResourceGuesser name={"warehouses"} {...props}/>
                        <WarehouseLocationResourceGuesser name={"warehouse_locations"} {...props}/>
                        <ProductResourceGuesser name={"products"} {...props}/>
                        <ProductCategoryResourceGuesser name={"product_categories"} {...props}/>
                        <CarrierResourceGuesser name={"carriers"}/>
                        <NavetteResourceGuesser name={"navettes"}/>
                        <UserTeamResourceGuesser name={"user_teams"}/>
                        <UserTeamResourceGuesser name={"user_souffrances"}/>
                        <AgencyResourceGuesser name={"agencies"}/>
                        <BrandResourceGuesser name={"brands"}/>
                        <ResourceGuesser name={"addresses"}/>
                        <ResourceGuesser name={"customers"}/>
                        <ResourceGuesser name="media_objects" create={MediaObjectsCreate}/>
                        <ScanLogResourceGuesser name={"scan_logs"}/>
                        <ResourceGuesser name={"media_preps"}/>
                    </HydraAdmin>
                </HelmetProvider>
            </ScanContextProvider>
        </TeamContextProvider>
    </UserContextProvider>
);
export default Admin;
