import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useState} from "react";
import {SHOW_CODE_PIN} from "../../config/app";
import NumpadPin from "./Numpad";

export default function LockDialog(props) {
    
    var debug = SHOW_CODE_PIN;
    
    const handleClose = () => {
        props.setOpen(false);
    };
    const [ean, setEan] = useState('0000');
    const handleValidation = () => {
        props.checkScan(ean)
        props.setOpen(false);
    }

    function handleValidPin(pin){
        setEan(pin);
        props.checkScan(pin);
        props.setOpen(false);
    }

    const onKeyUp = (e) => {
        const ENTER = 13;
        if (e.keyCode === ENTER) {
            handleValidation()
        }
    }
    return (
        <Dialog open={true} onClose={handleClose} onKeyUp={onKeyUp}
        >
            <DialogTitle>Déverrouiller l'emplacement</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Pour accéder et confirmer l'emplacement, merci de spécifier les 3 derniers chiffre du code PIN à l'emplacement où vous vous trouvez :
                    <br />
                    {debug ? props.pin : ""}
                </DialogContentText>
                
                <NumpadPin setpin={handleValidPin} pincode={props.pin}/>
            </DialogContent>
        </Dialog>

    );
}
