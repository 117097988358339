import {Box} from "@mui/material";
import ProductPresenter from "../../../component/presenter/Product";
import plural from "pluralize-fr";
import * as React from "react";
import ProductWeightField from "../../../component/field/ProductWeight";
import ImageField from "../../../component/field/ImageField";
import './Style.css'
import { useEffect, useState } from "react";

const Product = (props) => {
    const [visible, setVisible] = useState("block");

    useEffect(() => {
        if(props.product.packagingQuantity < 1){
            setVisible("none");
        }
    }, [props.product.packagingQuantity]);
    return (
        <Box
            sx={{display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginTop: 2, marginBottom: 2, maxHeight: "80px"}}>
            
            <ImageField record={props.product} source={"image"} style={{border: '1px solid lightgray', maxWidth: '150px', height: "100px"}} />
            <Box sx={{margin: '0 15px', width: '50%'}}>
                <ProductPresenter record={props.product}/>
                <Box>
                    <Box>
                        <Box style={{display: visible}} component="span">
                            Conditionnement
                            : {props.product.packagingType} de {props.product.packagingQuantity} {plural(props.product.packagingUnit).toLowerCase()}
                        </Box>
                        <ProductWeightField product={props.product}/>
                    </Box>
                </Box>
            </Box>

            {/*<Box sx={{border: '1px solid lightgray', height: "100px"}}>
                <ReactBarcode height={'100px'} className={"barcode"} width={1} value={props.product.ean} />
            </Box>*/}
        </Box>
    )
};

export {
    Product
}
