import {CreateGuesser, InputGuesser} from "@api-platform/admin";
import {Box} from "@mui/material";
import * as React from "react";
import {SaveButton, Toolbar, useDataProvider} from "react-admin";

const CreatePaletteItem = (props) => {
    const dataProvider = useDataProvider();

    const onSuccessPaletteItem = () => {
        props.setQuantityToPrepare(0);
        dataProvider.getOne('order_preparations', {id: props.orderPreparation.id})
            .then(({data}) => {
                props.setOrderPreparation(data);
            })
        props.setLoading(false);
    }

    const FormPaletteItemToolbar = props => (
        <Toolbar {...props}>
            <SaveButton id='form-paletteItem-submit'/>
        </Toolbar>
    );

    return (
        <Box sx={{display: 'flex', justifyContent: 'center', marginBottom: 2}}>
            <CreateGuesser onSuccess={onSuccessPaletteItem} title=" " style={{display: 'none'}}
                           toolbar={<FormPaletteItemToolbar/>} redirect={false} resource='palette_items'>
                <InputGuesser source={"palette"} initialValue={props.palette}/>
                <InputGuesser source={"quantity"} initialValue={props.quantityToPrepare}/>
                <InputGuesser source={"orderPreparationItem"} initialValue={props.orderPreparationItem.id}/>
            </CreateGuesser>
        </Box>
    )
}

export default CreatePaletteItem
