import {useContext} from "react";
import {
    Box
} from "@mui/material";
import * as React from "react";
import Profile from "./Profile";
import TeamMembers from "./TeamMembers";
import AddTeamMember from "./AddTeamMember";
import {UserContext} from "../../context/UserContext";
import {useLogout} from "react-admin";

const CurrentTeam = () => {
    const [user, setUser] = useContext(UserContext)
    const logout = useLogout();

    if(user == null)
        return null

    const handleLogout = () => {
        let promise = logout();
        setUser(null);
        return promise;
    }

    return <Box sx={{display: 'inline-flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row-reverse'}}>
        {
            user && <>
                <Profile user={user} handleLogout={handleLogout} />
                <TeamMembers user={user} setUser={setUser} />
                <AddTeamMember currentUser={user} setCurrentUser={setUser} />
            </>
        }
    </Box>
}

export default CurrentTeam;
