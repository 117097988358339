import * as React from "react";
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';

const ColorFieldGuesser = (props) => {
    const { source } = props;
    const record = useRecordContext(props);
    return   <div style={{backgroundColor: record[source], height: 30, width: 30}}/>;
}

ColorFieldGuesser.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default ColorFieldGuesser;
