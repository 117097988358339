import {useRedirect} from "react-admin";
import {ButtonGroup} from "@mui/material";
import Button from "@mui/material/Button";
import * as React from "react";

const NavetteListAction = (props) => {
    const redirect = useRedirect();

    return (
        <ButtonGroup>
            <Button
                onClick={() => redirect('/navettes/' + encodeURIComponent(props.record.id) + '/charge')}>Charger</Button>
        </ButtonGroup>
    )
}

export default NavetteListAction
