import dataProvider from "../../../utils/dataProvider";
import {Box, CircularProgress} from "@mui/material";
import Button from "@mui/material/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as React from "react";
import {useState} from "react";
import BootstrapDialogChoiceUserRedirect from "./BootstrapDialogChoiceUserRedirect";

export const Steps = {
    prepare: {
        id: 2,
        step_actual: 'prepare',
        next: 'control',
        redirect: 'controler',
        steped: 'prepared',
        text: 'Terminer préparation et passer au contrôle'
    },
    control: {
        id: 3,
        step_actual: 'controler',
        next: 'control-responsible',
        redirect: 'controler-responsable',
        steped: 'controlled',
        text: 'Valider le contrôle et passer au contrôle par un responsable'
    },
    controlResponsible: {
        id: 4,
        step_actual: 'controler-responsable',
        next: 'pack',
        redirect: 'emballer',
        steped: 'controlledResponsible',
        text: 'Valider le contrôle et passer à l\'emballage'
    },
    pack: {
        id: 5,
        step_actual: 'emballer',
        next: 'charge',
        redirect: 'charger',
        steped: 'packed',
        text: 'Valider l\'emballage et passer au chargement'
    },
    charge: {
        id: 6,
        step_actual: 'charger',
        next: 'expect',
        redirect: 'order_preparations',
        steped: 'charged',
        text: 'Valider le chargement dans la navette'
    }
}

const ButtonFinishPreparation = (props) => {
    const step = props.step;
    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleValidStep = () => {
        dataProvider.update('order_preparations', {
            id: props.orderPreparation.id,
            data: {
                'step': '/order_preparation_steps/' + Steps[step].next,
                'state': '/order_preparation_states/stopped'
            }
        }).then(() => {
            setOpenDialog(true)
        })
    }
    const handleClickFinishPrepare = () => {
        setLoading(true)
        if(step === 'prepare') {
            dataProvider.getMany('palettes', {
                ids: props.orderPreparation.palettes,
            }).then(({data}) => {
                let error = false;
                // Vérification du poids des palettes par rapport au transporteur, maximum
                // data.forEach((palette) => {
                //     if (palette.weight > props.orderPreparation.maxWeightPerPalette) {
                //         alert('Le poids de votre palette n°' + palette.number + ' dépasse le seuil du transporteur. Merci de retirer des produits et de les déplacer vers une nouvelle palette.')
                //         error = true;
                //     }
                // });
                if (!error) {
                    handleValidStep();
                } else {
                    setLoading(false)
                }
            });
        } else {
            handleValidStep();
        }
    }
    const percent = props.orderPreparation[Steps[step].steped]['percent'];

    return (
        <Box sx={{display: 'flex', justifyContent: 'space-around', alignContent: 'bottom', mt: 2, mb: 2}}>
            <Button onClick={() => handleClickFinishPrepare()}
                    disabled={percent !== 100} variant="contained" size={"large"}
                    startIcon={loading ? <CircularProgress color={'secondary'}/> : <FontAwesomeIcon icon="fa-solid fa-circle-check"/>}>
                { Steps[step].text }
            </Button>
            {
                openDialog && <BootstrapDialogChoiceUserRedirect orderPreparation={props.orderPreparation} steps={Steps} step={step} setOpenDialog={setOpenDialog}/>
            }
        </Box>
    )
};

export default ButtonFinishPreparation
