import {Box} from "@mui/material";
import * as React from "react";
import PropTypes from 'prop-types';
import Attribute from "./Attribute";

const ProductPresenter = (props) => {
    
    return (
        <>
            <Box component="div" sx={{fontSize: 25, fontWeight: 'bolder', textTransform: 'uppercase', marginBottom: 1}}>
                {props.record.name}
            </Box>
            
            {props.record.attributesToArray.map((attribute) => {
                if(!attribute[0]){
                    return null
                }
                return <Attribute key={attribute[0]} value={`${attribute[0]} : ${attribute[1]}`}/>
            })}
        </>
    )
};

ProductPresenter.propTypes = {
    record: PropTypes.any,
};

export default ProductPresenter;
