import * as React from 'react'
import ColorFieldGuesser from "../../../field/Color";
import NameFieldGuesser from "../../../field/Name";
import {FormListGuesser} from "../../../form/List";
import {FieldGuesser} from "@api-platform/admin";

export class OrderPreparationStateListState {}
export class OrderPreparationStateListProps {}
export class OrderPreparationStateList extends React.Component<OrderPreparationStateListProps, OrderPreparationStateListState> {
    render() {
        return (
            <FormListGuesser {...this.props}>
                <NameFieldGuesser source="name"/>
                <ColorFieldGuesser source="color"/>
                <FieldGuesser source={'private'}/>
            </FormListGuesser>
        )
    }
}
