import * as React from 'react';
import {Menu, MenuItemLink} from 'react-admin';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import {forwardRef} from "react";
import MenuItem from "./MenuItem";
import MenuRole from "./MenuRole";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import CategoryIcon from '@mui/icons-material/Category';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import ReportIcon from '@mui/icons-material/Report';
import PlaceIcon from '@mui/icons-material/Place';
import StorefrontIcon from '@mui/icons-material/Storefront';
import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto';
import BuildIcon from '@mui/icons-material/Build';
import GroupIcon from '@mui/icons-material/Group';
import {Divider} from "@mui/material";
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import ApartmentIcon from '@mui/icons-material/Apartment';
import Badge from '@mui/material/Badge';
import { useState, useEffect } from 'react';
import dataProvider from "../../utils/dataProvider";

const MenuItemPreparer = forwardRef(({onClick}, ref) => {
    const [count, setCount] = useState(0);
    
    useEffect(() => {
        dataProvider.getList('order_preparations', {
            pagination: {page: 1, perPage: 999},
            sort: {field: 'id', order: 'ASC'},
            filter: {"step[0]": "/order_preparation_steps/prepare"},
        }).then(({data}) => {
            var counter = 0;
            data.forEach((prep) =>{
                if((prep.assignment === "" || typeof prep.assignment === "undefined") && prep.currentUsers.length === 0){
                    counter++;
                }
            })
            setCount(counter);
        });
    }, []);
    return(<MenuItemLink ref={ref} style={{
        marginTop: 25,
        height: "43px",
        color: (window.location.href.includes('/prepare') ? '#000000de' : '#0000008a')
    }} to="/prepare" primaryText="Préparer" leftIcon={<Badge badgeContent={count} color="primary"><AssignmentIcon/></Badge>} onClick={onClick}/>
    )
});
const MenuItemControler = forwardRef(({onClick}, ref) => {
    const [count, setCount] = useState(0);
    
    useEffect(() => {
        dataProvider.getList('order_preparations', {
            pagination: {page: 1, perPage: 999},
            sort: {field: 'id', order: 'ASC'},
            filter: {"step[0]": "/order_preparation_steps/control"},
        }).then(({data}) => {
            var counter = 0;
            data.forEach((prep) =>{
                if((prep.assignment === "" || typeof prep.assignment === "undefined") && prep.currentUsers.length === 0){
                    counter++;
                }
            })
            setCount(counter);
        });
    }, []);
    return(
    <MenuItemLink ref={ref} style={{color: (window.location.href.includes('/controler') ? '#000000de' : '#0000008a'), height: "43px",}}
                  to="/controler" primaryText="Contrôler" leftIcon={<Badge badgeContent={count} color="primary"><FactCheckIcon/></Badge>} onClick={onClick}/>
    )
});
const MenuItemControlerResponsable = forwardRef(({onClick}, ref) => {
    const [count, setCount] = useState(0);
    
    useEffect(() => {
        dataProvider.getList('order_preparations', {
            pagination: {page: 1, perPage: 999},
            sort: {field: 'id', order: 'ASC'},
            filter: {"step[0]": "/order_preparation_steps/control-responsible"},
        }).then(({data}) => {
            var counter = 0;
            data.forEach((prep) =>{
                if((prep.assignment === "" || typeof prep.assignment === "undefined") && prep.currentUsers.length === 0){
                    counter++;
                }
            })
            setCount(counter);
        });
    }, []);
    return(
    <MenuItemLink ref={ref}
                  style={{color: (window.location.href.includes('/controler-responsable') ? '#000000de' : '#0000008a'), height: "43px",}}
                  to="/controler-responsable" primaryText="Contrôler Responsable" leftIcon={<Badge badgeContent={count} color="primary"><FactCheckIcon/></Badge>}
                  onClick={onClick}/>
    )
});
const MenuItemEmballer = forwardRef(({onClick}, ref) => {
    const [count, setCount] = useState(0);
    
    useEffect(() => {
        dataProvider.getList('order_preparations', {
            pagination: {page: 1, perPage: 999},
            sort: {field: 'id', order: 'ASC'},
            filter: {"step[0]": "/order_preparation_steps/pack"},
        }).then(({data}) => {
            var counter = 0;
            data.forEach((prep) =>{
                if((prep.assignment === "" || typeof prep.assignment === "undefined") && prep.currentUsers.length === 0){
                    counter++;
                }
            })
            setCount(counter);
        });
    }, []);
    return(
    <MenuItemLink ref={ref} style={{color: (window.location.href.includes('/emballer') ? '#000000de' : '#0000008a'), height: "43px",}}
                  to="/emballer" primaryText="Emballer" leftIcon={<Badge badgeContent={count} color="primary"><AutoAwesomeMotionIcon/></Badge>} onClick={onClick}/>
    )
});
const MenuItemCharger = forwardRef(({onClick}, ref) => {
    const [count, setCount] = useState(0);
    
    useEffect(() => {
        dataProvider.getList('order_preparations', {
            pagination: {page: 1, perPage: 999},
            sort: {field: 'id', order: 'ASC'},
            filter: {"step[0]": "/order_preparation_steps/charge"},
        }).then(({data}) => {
            var counter = 0;
            data.forEach((prep) =>{
                if((prep.assignment === "" || typeof prep.assignment === "undefined") && prep.currentUsers.length === 0){
                    counter++;
                }
            })
            setCount(counter);
        });
    }, []);
    return(
    <MenuItemLink ref={ref} style={{color: (window.location.href.includes('/charger') ? '#000000de' : '#0000008a'), height: "43px",}}
                  to="/charger" primaryText="Charger" leftIcon={<Badge badgeContent={count} color="primary"><LocalShippingIcon/></Badge>} onClick={onClick}/>
    )
});

const AdminMenu = () => {
    return (

        <Menu>
            <MenuRole role={"ROLE_LOGISTICIEN"}>
                <MenuItemPreparer/>
                <MenuItemControler/>
            </MenuRole>

            <MenuRole role={"ROLE_RESPONSABLE"}>
                <MenuItemControlerResponsable/>
            </MenuRole>

            <MenuRole role={"ROLE_LOGISTICIEN"}>
                <MenuItemEmballer/>
                <MenuItemCharger/>
            </MenuRole>
            <Divider sx={{mt: 2, mb: 2}}/>
            <MenuRole role={"ROLE_RESPONSABLE"}>
                <MenuItem leftIcon={<GroupIcon/>} resource="users"/>
                <MenuItem leftIcon={<ShoppingCartIcon/>} resource="order_preparations"/>
                <MenuItem leftIcon={<BorderAllIcon/>} resource="palettes"/>
                <MenuItem leftIcon={<AirportShuttleIcon/>} resource="navettes"/>
                <MenuItem leftIcon={<CategoryIcon/>} resource="products"/>
                <MenuItem leftIcon={<WarehouseIcon/>} resource="warehouses"/>
                <MenuItem leftIcon={<PlaceIcon/>} resource="warehouse_locations"/>
                <MenuItem leftIcon={<ReportIcon/>} resource="user_souffrances"/>
                <MenuItem leftIcon={<LocalShippingIcon/>} resource="carriers"/>
            </MenuRole>
            <Divider sx={{mt: 2, mb: 2}}/>
            <MenuRole role={"ROLE_ADMIN"}>
                <MenuItem leftIcon={<GroupIcon/>} resource="users"/>
                <MenuItem leftIcon={<AssistantPhotoIcon/>} resource="order_preparation_states"/>
                <MenuItem leftIcon={<BuildIcon/>} resource="order_preparation_steps"/>
                <MenuItem leftIcon={<PlaceIcon/>} resource="warehouse_locations"/>
                <MenuItem leftIcon={<LocalShippingIcon/>} resource="carriers"/>
                <MenuItem leftIcon={<StorefrontIcon/>} resource="channels"/>
                <MenuItem leftIcon={<ApartmentIcon/>} resource="agencies"/>
                <MenuItem leftIcon={<BrandingWatermarkIcon/>} resource="brands"/>
            </MenuRole>
        </Menu>
    )
};

export default AdminMenu;
