import dataProvider from "../../../utils/dataProvider";
import {Box, CircularProgress} from "@mui/material";
import Button from "@mui/material/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as React from "react";
import {useState} from "react";
import {Steps} from "../../Prepare/Show/ButtonFinish";

const ButtonFinishNavette = (props) => {
    const step = props.step;
    const [loading, setLoading] = useState(false);

    const handleClickFinishPrepare = (props) => {
        setLoading(true)
        dataProvider.update(
            'navettes', {
                id: props.navette.id,
                data: {
                    charged: true
                }
            }
        ).then(
            () => {
                window.location.href = "#/charger"
            }
        )
    }
    const percent = props.navette.progress;

    return (
        <Box sx={{display: 'flex', justifyContent: 'space-around', alignContent: 'bottom', mt: 2, mb: 2}}>
            <Button onClick={() => handleClickFinishPrepare(props)}
                    disabled={percent !== 100} variant="contained" size={"large"}
                    startIcon={loading ? <CircularProgress color={'secondary'}/> : <FontAwesomeIcon icon="fa-solid fa-circle-check"/>}>
                { Steps[step].text }
            </Button>
        </Box>

    )
};

export default ButtonFinishNavette
