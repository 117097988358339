import * as React from "react";
import WeightPresenter from "../presenter/Weight";
import PropTypes from "prop-types";

const PaletteWeightField = (props) => <WeightPresenter max={props.maxWeight} value={props.palette ? props.palette.weight : 0} />

PaletteWeightField.propTypes = {
    palette: PropTypes.any,
    maxWeight: PropTypes.number
}

export default PaletteWeightField;