import * as React from 'react'
import {InputGuesser} from "@api-platform/admin";
import CreateGuesser from "@api-platform/admin/lib/CreateGuesser";
import {InputColorPickerGuesser} from "../../../input/ColorPicker";

const ChannelCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source={"code"}/>
        <InputGuesser source={"name"}/>
        <InputColorPickerGuesser source={"color"}/>
        <InputGuesser source={"hostname"}/>
        {/*<InputGuesser source={"orderPreparations"} />*/}
        <InputGuesser source={"apiKey"}/>
    </CreateGuesser>
);

export default ChannelCreate;
