import * as React from 'react'
import {
    ReferenceInput, SelectInput
} from "react-admin"
import {FormCreateGuesser} from "../../../form/Create";
import {InputGuesser} from "@api-platform/admin";
import {InputColorPickerGuesser} from "../../../input/ColorPicker";

export default function WarehouseLocationCreate(props) {
    return (
        <FormCreateGuesser {...props}>
            <ReferenceInput source="product" reference="products">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <InputGuesser source={"code"} />
            <InputColorPickerGuesser source={'color'}/>
            <InputGuesser source={"quantity"} />
            <InputGuesser source={"priority"} />
            <InputGuesser  source={"comment"} />
        </FormCreateGuesser>
    )
}

