import * as React from "react";
import PropTypes from 'prop-types';
import {FieldGuesser} from "@api-platform/admin";

const NameFieldGuesser = (props) => {
    return <FieldGuesser {...props}/>;
}

NameFieldGuesser.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default NameFieldGuesser;
