import ButtonPaletteMoveLocation from "../../../component/resource/Palette/Palette/ButtonMoveLocation";
import * as React from "react";
import ButtonWrap from "./ButtonWrap";
import {Box} from "@mui/material";
import ButtonPalettePrint from "../../../component/resource/Palette/Palette/ButtonPrint";

const LockField = (props) => {
    return (
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <ButtonWrap record={props.record} orderPreparation={props.orderPreparation} setOrderPreparation={props.setOrderPreparation}/>

            <Box sx={{ml: 1}}>
                <Box sx={{display: 'inline-flex', mt: 1}}>
                    <ButtonPaletteMoveLocation palette={props.record}/>
                    <ButtonPalettePrint palette={props.record} orderPreparation={props.orderPreparation} />
                </Box>
            </Box>
        </Box>
    )
}

export default LockField;
