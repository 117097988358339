import {useState} from "react";
import {IconButton, ListItemIcon, Menu, MenuItem, Tooltip} from "@mui/material";
import Logout from "@mui/icons-material/Logout";
import * as React from "react";
import UserAvatarPresenter from "../../presenter/UserAvatar";

const Profile = (props) => {
    const [element, setElement] = useState(null);
    const open = Boolean(element);

    const handleClick = (event) => setElement(event.currentTarget);
    const handleClose = () => setElement(null);
    
    return (
        <>
            <Tooltip title={props.user.firstName + ' ' + props.user.lastName}>
                <IconButton
                    onClick={handleClick}
                    size="small"
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <UserAvatarPresenter sx={{cursor: 'pointer'}} record={props.user}/>
                </IconButton>
            </Tooltip>
            { props.handleLogout &&
                <Menu
                    anchorEl={element}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,

                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{horizontal: 'right', vertical: 'top'}}
                    anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                >
                    <MenuItem onClick={props.handleLogout}>
                        <ListItemIcon>
                            <Logout fontSize="small"/>
                        </ListItemIcon>
                        Déconnexion
                    </MenuItem>
                </Menu>
            }
        </>
    )
}

export default Profile;
