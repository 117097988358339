import {Box} from "@mui/material";
import * as React from "react";
import PropTypes from "prop-types";
import {ReferenceField} from "react-admin";
import ChannelPresenter from "../../presenter/Channel";
import ChannelAndOrderIdPresenter from "../../presenter/ChannelAndOrderIdPresenter";


const PreparationIdentity = (props) => {
    {/*<Box>*/}
    {/*    <IconButton color="primary" aria-label="??" component="span">*/}
    {/*        <Chat />*/}
    {/*    </IconButton>*/}
    {/*</Box>*/}

    /* if(!props.vertical) {
        return (
            <>
                <Box sx={{marginLeft: 2, marginRight: 2}}>
                    <ReferenceField label='' source={'channel'} record={props.record} reference={'channels'} link={false}>
                        <ChannelPresenter/>
                    </ReferenceField>
                </Box>
                <Box>
                    {props.record.orderId}
                </Box>
            </>
        )
    } */
    return (
        <Box>
            {/*<IconButton color="primary" aria-label="??" component="span">*/}
            {/*    <Chat/>*/}
            {/*</IconButton>*/}

            <ChannelAndOrderIdPresenter record={props.record}/>
        </Box>
    );
}

PreparationIdentity.propTypes = {
    record: PropTypes.object,
};

export default PreparationIdentity;
