import * as React from "react";
import { Route } from 'react-router-dom';
import AdminParameter from "../pages/Admin/Parameter";
import OrderPreparationPreparationIndex from "../pages/Prepare/Index";
import OrderPreparationPreparationShow from "../pages/Prepare/Show";
import OrderPreparationControlIndex from "../pages/Control/Index";
import OrderPreparationControlShow from "../pages/Control/Show";
import OrderPreparationPackIndex from "../pages/Pack/Index";
import OrderPreparationPackShow from "../pages/Pack/Show";
import OrderPreparationChargeIndex from "../pages/Charge/Index";
import OrderPreparationChargeShow from "../pages/Charge/Show";
import OrderPreparationControlResponsibleIndex from "../pages/Control-responsable/Index";
import OrderPreparationControlResponsibleShow from "../pages/Control-responsable/Show";

const routes = [
    <Route exact path="/prepare" component={OrderPreparationPreparationIndex} />,
    <Route exact path="/order_preparations/:id/prepare" component={OrderPreparationPreparationShow} />,

    <Route exact path="/controler" component={OrderPreparationControlIndex} />,
    <Route exact path="/order_preparations/:id/control" component={OrderPreparationControlShow} />,

    <Route exact path="/controler-responsable" component={OrderPreparationControlResponsibleIndex} />,
    <Route exact path="/order_preparations/:id/control-responsible" component={OrderPreparationControlResponsibleShow} />,

    <Route exact path="/emballer" component={OrderPreparationPackIndex} />,
    <Route exact path="/order_preparations/:id/pack" component={OrderPreparationPackShow} />,

    <Route exact path="/charger" component={OrderPreparationChargeIndex} />,
    <Route exact path="/navettes/:id/charge" component={OrderPreparationChargeShow} />,

    <Route exact path="/admin/parameter" component={AdminParameter} />,
];

export default routes;
