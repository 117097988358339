import * as React from 'react'
import {EditGuesser} from "@api-platform/admin";
import {PostEditToolbar} from "../toolbar/SaveButton";
import { withTranslate } from 'react-admin';

class EditGuesserState {
    pristine: boolean;
}
class EditGuesserProps {
    resource: string;
    id: number;
}

class FormEditGuesser extends React.Component<EditGuesserProps, EditGuesserState> {
    constructor(props) {
        super(props);
        this.state = {
            pristine: true
        }
    }

    onChange = () => {
        this.setState({pristine: false})
    }

    render() {
        const { translate } = this.props;
        const title = translate('app.page.edit', {name: translate('resources.' + this.props.resource + '.name')});
        return (
            <EditGuesser
                redirect={"/" + this.props.resource}
                resource={this.props.resource}
                id={this.props.id}
                toolbar={<PostEditToolbar disabled={this.state.pristine}/>}
                title={title}
            >
                {React.Children.map(this.props.children, c => {
                    if(React.isValidElement(c)){
                        return React.cloneElement(c, {resource: this.props.resource, onChange:this.onChange});
                    }
                })}
            </EditGuesser>
        )
    }
}
export default withTranslate(FormEditGuesser);
