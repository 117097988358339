import {Box} from "@mui/material";
import BooleanField from "../../field/Boolean";
import * as React from "react";
import PropTypes from "prop-types";
import EventNoteIcon from "@mui/icons-material/EventNote";
import AssignmentIcon from "@mui/icons-material/Assignment";
import LinearProgressWithLabelField from "../../field/LinearProgressWithLabelField";

const PreparationStep = (props) => {
    const textCenter = {textAlign: 'center'};
    return (
        <>
            <Box sx={textCenter}>
                <div className={'IconAlignWithText'} style={{marginBottom: 10}}>
                    <EventNoteIcon />
                    Planification
                </div>
                <BooleanField source="planned" record={props.record}/>
            </Box>

            <Box sx={textCenter}>
                <div className={'IconAlignWithText'} style={{marginBottom: 10}}>
                    <AssignmentIcon />
                    Préparation
                </div>
                <LinearProgressWithLabelField source="prepared" record={props.record}/>
            </Box>

            <Box sx={textCenter}>
                <div className={'IconAlignWithText'} style={{marginBottom: 10}}>
                    <AssignmentIcon />
                    Contrôle
                </div>
                <LinearProgressWithLabelField source="controlled" record={props.record}/>
            </Box>

            <Box sx={textCenter}>
                <div className={'IconAlignWithText'} style={{marginBottom: 10}}>
                    <AssignmentIcon />
                    Emballage
                </div>
                <LinearProgressWithLabelField source="packed" record={props.record}/>
            </Box>

            <Box sx={textCenter}>
                <div className={'IconAlignWithText'} style={{marginBottom: 10}}>
                    <AssignmentIcon />
                    Chargement
                </div>
                <LinearProgressWithLabelField source="charged" record={props.record}/>
            </Box>
        </>
    );
}

PreparationStep.propTypes = {
    record: PropTypes.object,
};

export default PreparationStep;
