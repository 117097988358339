import * as React from 'react';
import SelectUnstyled, { selectUnstyledClasses } from '@mui/base/SelectUnstyled';
import OptionUnstyled, { optionUnstyledClasses } from '@mui/base/OptionUnstyled';
import PopperUnstyled from '@mui/base/PopperUnstyled';
import { styled } from '@mui/system';
import {Alert, Box, CircularProgress} from "@mui/material";
import {useGetManyReference} from "react-admin";

const blue = {
    100: '#DAECFF',
    200: '#99CCF3',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
};

const grey = {
    100: '#E7EBF0',
    200: '#E0E3E7',
    300: '#CDD2D7',
    400: '#B2BAC2',
    500: '#A0AAB4',
    600: '#6F7E8C',
    700: '#3E5060',
    800: '#2D3843',
    900: '#1A2027',
};

const StyledButton = styled('button')(
    ({ theme }) => `
  display: flex;
  flex-direction: column;
  font-size: 25;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  height: 70px;
  border: 2px solid #989898;
  background-color: whitesmoke;
  position: relative;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 15px;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    background: ${theme.palette.mode === 'dark' ? '' : grey[100]};
    border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[100]};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
    }
  }

  &::after {
    position: absolute; 
    right: 5px;
    content: '▾';
  }
  `,
);

const StyledListbox = styled('ul')(
    ({ theme }) => `
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0;
  min-width: 125px;
  overflow: auto;
  max-height: 300px;
  outline: 0px;
  background-color: white;
  border: 1px solid lightgray;
  `,
);

const StyledOption = styled(OptionUnstyled)(
    ({ theme }) => `
  list-style: none;
  padding: 8px;
  cursor: default;
  display: flex;
  flex-direction: column;
  font-size: 25;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  height: 70px;
  border: 2px solid #989898;
  background-color: whitesmoke;
  padding: 15px;
  margin-bottom: 15px;
  box-sizing: border-box;
  cursor: pointer;
  &:last-of-type {
  margin-bottom: 0;
  }


  &:hover {
    background: ${theme.palette.mode === 'dark' ? '' : grey[100]};
    border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[100]};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
    }
  }
  `,
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
    const components = {
        Root: StyledButton,
        Listbox: StyledListbox,
        Popper: StyledPopper,
        ...props.components,
    };

    return <SelectUnstyled {...props} ref={ref} onChange={props.onChange} value={props.value} components={components} />;
});

export default function PaletteChoice(props) {
    const { data, ids, loading, error } = useGetManyReference(
        'palettes',
        'orderPreparation.id',
        props.orderPreparation.id,
        { page: 1, perPage: 50 },
        { field: 'number', order: 'ASC' },
        {},
        'order_preparations',
    );

    if (loading) return <CircularProgress size='1rem' />;
    if (error) return <Alert severity="error">Erreur lors de la récupération des palettes.</Alert>

    return (
        <CustomSelect onChange={props.onChange} value={props.palette.id}>
            {ids.map((id) => {
                return (
                    <StyledOption key={data[id].id} value={data[id].id}>
                        <Box component="div" sx={{fontSize: 15}}>#{data[id].number}</Box>
                        <Box component="div" sx={{fontSize: 15}}>{data[id].typeName}</Box>
                    </StyledOption>
                )
            }
            )}
        </CustomSelect>
    );
}
