import * as React from 'react'
import {Permission} from "../Permission";
import CarrierList from "../Carrier/List";
import CarrierCreate from "./Create";
import CarrierEdit from "./Edit";

const list = props => <CarrierList {...props}/>
const create = props => <CarrierCreate {...props}/>
const edit = props => <CarrierEdit {...props}/>

export default function BrandResourceGuesser(props) {
    return <Permission {...props } list={list} create={create} edit={edit} show={null}/>
}