import * as React from 'react'
import {Permission} from "../../Permission";
import OrderPreparationList from "./List";
import OrderPreparationShow from "./Show";

export default class OrderPreparationResourceGuesser extends React.Component {
    list = props => (
        <OrderPreparationList {...props}/>
    );

    show = props => (
        <OrderPreparationShow {...props}/>
    );

    render() {
        return (
            <Permission {...this.props } show={this.show} create={null} edit={null} list={this.list}/>
        )
    }
}
