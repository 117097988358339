import OrderPreparationShowItems from "./Tab/Items";
import OrderPreparationShowPalettes from "./Tab/Palettes";
import * as React from "react";
import {Box, Tabs, Tab, Typography} from "@mui/material";
import {Datagrid, ReferenceArrayField} from "react-admin";
import NavetteField from "../../../../field/NavetteField";
import NavetteDialogAction from "../../../../dialog/NavetteDialogAction";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const OrderPreparationShowTab = (props) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px' }}>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                    <ReferenceArrayField reference="navettes" source="navettes">
                        <Datagrid>
                            <NavetteField />
                        </Datagrid>
                    </ReferenceArrayField>
                </Box>
                <NavetteDialogAction record={props.record}/>
            </Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} variant={"fullWidth"}  onChange={handleChange} aria-label="Contenu">
                    <Tab label="Produits" {...a11yProps(0)} />
                    <Tab label="Palettes" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <OrderPreparationShowItems />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <OrderPreparationShowPalettes record={props.record}/>
            </TabPanel>
        </>
)
}

export default OrderPreparationShowTab;
