import {Alert} from "@mui/material";
import * as React from "react";
import PaletteItemButtonAction from "../../../component/resource/Palette/Palette/ButtonAction";

const AlertCreatePalette = (props) => {
    return (<Alert variant="filled" severity={props.color? props.color: "error"} action={
        <PaletteItemButtonAction orderPreparation={props.orderPreparation} record={null} create={true}/>
    } sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        {props.title? props.title:"Créer une palette pour continuer"}
    </Alert>)
}

export default AlertCreatePalette
