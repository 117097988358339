import * as React from 'react'
import {Permission} from "../../Permission";

const Redirect = (props) => {
    props.history.push('/dashboard');
}

export default class OrderPreparationItemResourceGuesser extends React.Component {
    list = props => (
        <Redirect {...props} />
    );

    render() {
        return (
            <Permission {...this.props } show={null} create={null} edit={null} list={null}/>
        )
    }
}
