import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import * as React from "react";
import {OrderPreparationAction} from "../../../pages/_Layout";
import {useState, useEffect} from "react";
import dataProvider from "../../../utils/dataProvider";
import { useRefresh } from 'react-admin';

const ScanPaletteDialog = (props) => {
    const palette = props.record;
    const [elements, setElements] = useState(<OrderPreparationAction id={palette.orderPreparation.split('/').pop()} />);
    const [visible, setVisible] = useState("visible");
    const refresh = useRefresh();

    function addPalette(){
        
        const pathArray = window.location.href.split("/");
        var navetteID = pathArray[5].replace("%2Fnavettes%2F", "");

        dataProvider.update('palettes', {
            id: props.record.id,
            data: {
                'navette': "/navettes/"+navetteID
            }
        }).then((value) => {
            
                refresh();
                props.handleClose();
            
        });
        
    }

    const checkKey = (obj, keyName) => {
        if (Object.keys(obj).indexOf(keyName) !== -1) {
            return true;
        } else {
            return false;
        }
    };

    useEffect(() => {
        const pathArray = window.location.href.split("/");
        if(pathArray[6] != null && pathArray[6] === "charge"){
            setElements(<><p>Voulez vous charger cette palette?</p><Button onClick={addPalette}>Oui</Button><Button onClick={props.handleClose}>Non</Button></>);
            setVisible("hidden");
        }
    });
   
    return (
        <Dialog open={true}>
            <DialogTitle>Palette : {palette.typeName} #{palette.number}</DialogTitle>
            <DialogContent>
                {elements}
            </DialogContent>
            <DialogActions>
                <Button style={{visibility: visible}} variant="contained" color="success" onClick={props.handleClose}>
                    Terminer
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default ScanPaletteDialog
