import * as React from 'react'
import {ShowGuesser} from "@api-platform/admin";
import {MenuTop} from "./Show/MenuTop";
import OrderPreparationShowTab from "./Show/Tab";
import {MenuBottom} from "./Show/MenuBottom";
import {Box, Divider} from "@mui/material";

const OrderPreparationShow = (props) => {
    return (
        <ShowGuesser {...props}>
            <MenuTop {...props} />
            <Box>
                <Divider />
            </Box>
            <MenuBottom {...props} />
            <OrderPreparationShowTab {...props}/>
        </ShowGuesser>
    )
}
export default OrderPreparationShow;
