import {useNotify, useRefresh} from "react-admin";
import {useState, useEffect} from "react";
import dataProvider from "../../utils/dataProvider";
import {IconButton, Tooltip} from "@mui/material";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import NavetteDialog from "../dialog/NavetteDialog";
import Button from "@mui/material/Button";
import * as React from "react";
import DeleteIcon from '@mui/icons-material/Delete';

const SetNavetteButton = (props) => {
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const [showremove, setShowremove] = useState("");
    const refresh = useRefresh();
    const [open, setOpen] = useState(false);
    const handleClick = () => setOpen(true);
    const handleValidation = (navette) => {
        setLoading(true)
        dataProvider.update('palettes', {
            id: props.palette.id,
            data: {
                'navette': navette
            }
        }).then(() => {
            setOpen(false)
            setLoading(false)
            notify('Changement de la navette pour la palette #' + props.palette.number, {type: 'success'})
            if(props.refresh) props.refresh();
        }).catch(() => {
            setLoading(false)
            notify('Erreur lors du changement de la navette pour la palette #' + props.palette.number, {type: 'error'})
        })
    };

    useEffect(() => {
        if(props.contextUsed == "WithoutPalette"){
            setShowremove("none");
        }
    })
    

    const handleRemoveNavette = () => {
        setLoading(true)
        dataProvider.update('palettes', {
            id: props.palette.id,
            data: {
                'navette': ""
            }
        }).then(() => {
            setLoading(false);
            notify('Changement de la navette pour la palette #' + props.palette.number, {type: 'success'});
            refresh();
            if(props.refresh) props.refresh();
        }).catch(() => {
            setLoading(false)
            notify('Erreur lors du changement de la navette pour la palette #' + props.palette.number, {type: 'error'})
        })
    }

    return (
        <>  
            <div style={{display: "flex"}}>
                <Tooltip title={"Changer la navette"}>
                    <IconButton color="primary" aria-label="Changer la navette" component="span" onClick={handleClick}>
                        <MoveUpIcon />
                    </IconButton>
                </Tooltip>
                <NavetteDialog open={open} setOpen={setOpen} loading={loading} handleValidation={handleValidation}/>
                <Tooltip title={"Supprimer la palette de la navette"}>
                    <Button onClick={handleRemoveNavette} color="error" variant="outlined" style={{width: "32px", minWidth: "32px", height: "32px", borderRadius: "32px", display: showremove}}><DeleteIcon/></Button>
                </Tooltip>
            </div>
        </>
    )
}

export default SetNavetteButton