import {useContext, useState} from "react";
import {ScanContext} from "../context/ScanContext";
import {useNotify} from "react-admin";
import ButtonSendEanTest from "../button/SendEanTest";
import * as React from "react"
import ScanUserWrapper from "./user/Wrapper";
import useScanDetection from 'use-scan-detection';
import ScanProductWrapper from "./product/Wrapper";
import ScanPaletteWrapper from "./palette/Wrapper";
import ScanNavetteWrapper from "./navette/Wrapper";
import ScanWarehouseLocationWrapper from "./warehouseLocation/Wrapper";
import dataProvider from "../../utils/dataProvider";

const ScanWrapper = (props) => {
    const [scan, setScan] = useContext(ScanContext);
    const notify = useNotify()

    useScanDetection({
        onComplete: (code) => { setScan(code.toString().replaceAll("Shift", "")) },
        minLength: 12, // EAN13
        maxLength: 13
    });
    const handleClose = (error) => {
        if (typeof error == 'boolean' && error)
            notify('Aucune correspondance', {type: 'error'})
        setTimeout(() => setScan(null))
        return null;
    }

    const createScanLog = (datas) => {
        dataProvider.create('scan_logs', {
            data: datas
        })
    }


    
    if (scan) {
        if(scan.length === 12 || scan.length === 13){
            console.log(scan);
            const prefix = scan.slice(0, 3);
            const pp = {
                id: +scan.slice(3, 13),
                scan: scan,
                setScan: setScan,
                handleClose: handleClose,
                createScanLog: createScanLog
            }
            //Context utilisateur
            if (prefix === '200')
                return <ScanUserWrapper {...pp}/>
            if (prefix === '201' || prefix === '202' || prefix === '370')
                return <ScanProductWrapper {...pp}/>
            if (prefix === '203')
                return <ScanPaletteWrapper {...pp}/>
            if (prefix === '204')
                return <ScanNavetteWrapper {...pp}/>
            if (prefix === '205') //TODO: Commandes
                return null
            if (prefix === '210')
                return <ScanWarehouseLocationWrapper {...pp}/>
            handleClose(true);
        }else{
            notify('Format invalide 12 ou 13 requis', {type: 'error'});
        }
        
    }
    return (
        <>
            {/*<ButtonSendEanTest/>*/}
        </>
    )
}

export default ScanWrapper;
