import * as React from "react";
import PropTypes from 'prop-types';
import {IconPickerItem} from "react-fa-icon-picker";
import {Box} from "@mui/material";

const PaymentField = (props) => {
    return (
        <Box component="span" className={'IconAlignWithText'}>
            <IconPickerItem icon={ props.record.payment ? "FaCheckCircle" : "FaTimesCircle" } size={20} color={ props.record.payment ? "#2ECC71" : "#FF0000" }/>
        </Box>
    )
}

PaymentField.propTypes = {
    record: PropTypes.object
};

export default PaymentField;
