import * as React from 'react';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {
    useListContext,
    useNotify,
    useUnselectAll,
    useUpdateMany
} from 'react-admin';
import {useState} from "react";
import {CircularProgress, TextField} from "@mui/material";
import BootstrapDialogTitle from "../../../../dialog/BootstrapDialogTitle";
import BootstrapDialog from "../../../../dialog/BootstrapDialog";

export default function BulkPriority() {
    const {selectedIds} = useListContext();

    const [open, setOpen] = useState(false);
    const [priority, setPriority] = useState(0);
    const handleClick = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const [updateMany, {loading}] = useUpdateMany(
        'order_preparations',
        selectedIds,
        {priority: parseInt(priority)},
        {
            onSuccess: () => {
                setOpen(false)
                unselectAll('order_preparations');
                notify('La priorité des préparations de commande sont mises à jour. Priorité :' + priority);
                window.location.reload();
            },
            onFailure: () => notify('Erreur: les préparations de commande n\'ont pas pu être mis à jour', {type: 'warning'}),
        }
    );

    if (open) {
        return (
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-priority"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-priority" onClose={handleClose}>
                    Définissez une priorité
                </BootstrapDialogTitle>

                <DialogContent dividers>
                    <TextField
                        label="Priorité"
                        type="number"
                        defaultValue={priority}
                        sx={{width: '100%'}}
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={(event) => setPriority(event.target.value)}
                    />
                </DialogContent>

                <DialogActions>
                    <Button startIcon={loading ? <CircularProgress/> : null} autoFocus variant="contained"
                            color="primary" disabled={loading} onClick={updateMany}>
                        Valider
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        )
    }

    return (
        <Button onClick={handleClick} color={'secondary'}>
            Priorité
        </Button>
    );
}
