import * as React from 'react'
import {
    ChipField, ReferenceArrayInput,
    ReferenceField, SelectArrayInput,
} from "react-admin"
import {FormListGuesser} from "../../../form/List";
import FieldGuesser from "@api-platform/admin/lib/FieldGuesser";
import NumberField from "../../../field/NumberField";
import ColorFieldGuesser from "../../../field/Color";
import ListActions from "./Actions/ListActions";
import dataProvider from "../../../../utils/dataProvider";

import { SelectInput, TextInput, Filter, ReferenceInput, AutocompleteInput } from 'react-admin';

const FilterWarehouse = (props) => (
    
    <Filter {...props}>
        
        <TextInput label="Produits" reference="products" source="product.name" alwaysOn/>
        <ReferenceInput
            label="Entrepôt"
            source="warehouse"
            reference="warehouses"
            sort={{ field: 'name', order: 'ASC' }}
            filterToQuery={searchText => ({ name: searchText })}
            allowEmpty={true}
            alwaysOn
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

export default function WarehouseLocationList(props) {
    
  
    
    return (
        <FormListGuesser filters={<FilterWarehouse/>} resource={'warehouses'} basePath={'/warehouses'} {...props} actions={<ListActions />}  removeBulkActionDelete={true} hasShow={false}>
            <ReferenceField record={props.record} reference={"warehouses"} source={"warehouse"} sortBy={'warehouse.name'}>
                <ChipField source="name"/>
            </ReferenceField>
            <ReferenceField record={props.record} reference={"products"} source={"product"} sortBy={'product.name'}>
                <ChipField source="name"/>
            </ReferenceField>
            <FieldGuesser source="code"/>
            <ColorFieldGuesser source="color"/>
            <FieldGuesser source="quantity"/>
            <NumberField source="priority"/>
            <FieldGuesser source="comment"/>

        </FormListGuesser>
    )
}

