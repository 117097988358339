import {useContext, useEffect, useState} from "react";
import dataProvider from "../../../utils/dataProvider";
import {
    Alert,
    Avatar, Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle, IconButton,
    LinearProgress,
    List,
    ListItem, ListItemAvatar, ListItemText,
    Tooltip
} from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import Profile from "./Profile";
import * as React from "react";
import {TeamContext} from "../../context/TeamContext";

const AddTeamMember = (props) => {
    const [team, setTeam] = useContext(TeamContext)
    const [open, setOpen] = useState(false);
    const [users, setUsers] = useState(null);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setUsers(null);
    }

    const handleAddUser = (user) => {
        dataProvider.update('users', {
            id: user.id,
            data: {
                currentOrderPreparation: props.currentUser.currentOrderPreparation
            }
        }).then(() => {
            setUsers([...users.filter((i) => i !== user)])
            setTeam([...team, user])
        })
    }

    useEffect(() => {
        if (open && users === null) {
            dataProvider.getList('users', {
                pagination: {page: 1, perPage: 999},
                sort: {field: 'id', order: 'ASC'},
                filter: {exists: {currentOrderPreparation: false}},
            }).then(({data}) => {
                setUsers(data.filter((u) => team.filter(tu => tu.id === u.id).length === 0 && props.currentUser.warehouses.some(w => u.warehouses.includes(w))))
            });
        }
        return null;
    })

    const userEmail = localStorage.getItem("email");
    const domainEmail = userEmail.split("@")[1];

    return (
        <>
            <Tooltip title="Ajouter un équipier">
                <Avatar sx={{ width: 30, height: 30, cursor: 'pointer', mr: 1 }} onClick={handleOpen}>
                    <GroupAddIcon fontSize={"small"} />
                </Avatar>
            </Tooltip>
            <Dialog open={open}>
                <DialogTitle>Ajouter un équipier</DialogTitle>
                <DialogContent>
                    <List>
                        {!users && <LinearProgress/>}
                        {users && (users.length === 0
                                ? <Alert severity={'error'}>Aucun utilisateur à ajouter</Alert>
                                : users.map((user) => (
                                    (user.email.includes(domainEmail) || domainEmail.includes("admin.com")) ?
                                    
                                    <ListItem key={user.email} secondaryAction={
                                        <IconButton onClick={() => handleAddUser(user)} edge="end" aria-label="ajouter">
                                            <GroupAddIcon />
                                        </IconButton>
                                    }>
                                        <ListItemAvatar>
                                            <Profile user={user} />
                                        </ListItemAvatar>
                                        <ListItemText primary={user.firstName + ' ' + user.lastName} secondary={user.email}/>
                                    </ListItem>

                                    : null
                                ))
                        )}

                    </List>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="success" onClick={handleClose}>
                        Terminer
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
};

export default AddTeamMember;
