import * as React from 'react'
import {InputColorPickerGuesser} from "../../../input/ColorPicker";
import {InputIconPickerGuesser} from "../../../input/IconPicker";
import {InputNameGuesser} from "../../../input/Name";
import FormEditGuesser from "../../../form/Edit";

export class OrderPreparationStateEditState {}
export class OrderPreparationStateEditProps {}
export class OrderPreparationStateEdit extends React.Component<OrderPreparationStateEditProps, OrderPreparationStateEditState> {
    render() {
        return (
            <FormEditGuesser {...this.props}>
                <InputNameGuesser source='name'/>
                <InputColorPickerGuesser source='color'/>
                <InputIconPickerGuesser source='icon'/>
            </FormEditGuesser>
        )
    }
}
