import {ReferenceField} from "react-admin";
import StatePresenter from "../../presenter/StatePresenter";
import InverseStateButton from "../../button/InverseStateButton";
import * as React from "react";
import {useState} from "react";
import {Divider} from "@mui/material";

const PreparationShowState = (props) => {
    const [state, setRecord] = useState(props.record)

    return (
        <>
            <StatePresenter record={state} hiddenLabel={false}/>
            <Divider orientation="vertical" sx={{mr: 2, ml: 2}} flexItem />
            <InverseStateButton orderPreparation={props.orderPreparation} record={state} setRecord={setRecord} />
        </>
    )
}

const PreparationState = (props) => {
    return (
        <ReferenceField label='' source={'state'} record={props.orderPreparation} reference={'order_preparation_states'} link={false}>
            <PreparationShowState orderPreparation={props.orderPreparation} />
        </ReferenceField>
    )
};

export default PreparationState;
