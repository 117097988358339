import * as React from "react";
import PropTypes from 'prop-types';
import {Button, ReferenceField, useNotify, useRefresh} from "react-admin";
import EventNoteIcon from '@mui/icons-material/EventNote';
import {ButtonGroup} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import FactCheckIcon from '@mui/icons-material/FactCheck';
import dataProvider from "../../../../../utils/dataProvider";
import {linkToRecord} from "ra-core";
import {useContext} from "react";
import {UserContext} from "../../../../context/UserContext";
import ImageEye from "@material-ui/icons/RemoveRedEye";
import {TeamContext} from "../../../../context/TeamContext";
import {useRedirect} from "react-admin";
import BtnSeeImgPrep from "../../../../module/BtnSeeImgPrep";

function ChoiceButton(props) {
    const [user, setUser] = useContext(UserContext);
    const [team, setTeam] = useContext(TeamContext);
    const redirect = useRedirect();
    const refresh = useRefresh();
    const notify = useNotify();

    function handleStartPreparation() {
        dataProvider.update('order_preparations', {
            id: props.id,
            data: {
                'step': '/order_preparation_steps/prepare'
            }
        }).then(() => {
            refresh()
            notify('La préparation de commande est mise à jour');
        })
    }

    function handleClickPreparation(e) {
        
        dataProvider.update('order_preparations', {
            id: props.id,
            data: {
                'state': '/order_preparation_states/started'
            }
        })
        dataProvider.updateMany('users', {
            ids: [user.id, ...team.map((u) => u.id)],
            data: {
                'currentOrderPreparation': props.id
            }
        }).then(() => setUser(null))

        var target = e.target.parentNode.parentNode;
        if(target.getAttribute("aria-label")){
            dataProvider.getOne('order_preparations', {
                id: props.id
            }).then(({data}) => {
                if(data.navettes[0] !== "" && data.navettes[0] != null){
                    var navetteToRedirect = "#/navettes/%2Fnavettes%2F"+ data.navettes[0].replace("/navettes/", "") +"/charge";
                    window.location.href = navetteToRedirect;
                }
                
            });
        }
        if(e.target.getAttribute('aria-label') === "Charger"){
            window.location.href="#/charger";
        }
    }

    const href = linkToRecord(`#/order_preparations`, props.id)

    switch (props.currentStep){
        case "plan":
            return <Button onClick={handleStartPreparation} variant="contained" startIcon={<EventNoteIcon/>} label='Mise en prépa' style={{backgroundColor: props.record.color}}/>;
        case 'prepare':
            return <Button onClick={handleClickPreparation} variant="contained" startIcon={<AssignmentIcon/>} href={href+'/prepare'} label='Préparer' style={{backgroundColor: props.record.color}}/>;
        case 'control':
            return <Button onClick={handleClickPreparation} variant="contained" startIcon={<FactCheckIcon/>} href={href+'/control'} label='Contrôler' style={{backgroundColor: props.record.color}}/>;
        case 'control-responsible':
            return <Button onClick={handleClickPreparation} variant="contained" startIcon={<FactCheckIcon/>} href={href+'/control-responsible'} label='Contrôler Responsable' style={{backgroundColor: props.record.color}}/>;
        case 'pack':
            return <Button onClick={handleClickPreparation} variant="contained" startIcon={<FactCheckIcon/>} href={href+'/pack'} label='Emballer' style={{backgroundColor: props.record.color}}/>;
        case 'charge':
            return <Button onClick={handleClickPreparation} variant="contained" startIcon={<FactCheckIcon/>} label='Charger' style={{backgroundColor: props.record.color}}/>;
        case 'expect':
            return <Button variant="contained" href={props.linkToResource} label='Expédié' style={{backgroundColor: props.record.color}}/>;
        default:
            return '';
    }
}



const OrderPreparationStartStep = (props) => {
    const {currentStep, itemToPrepare, paletteToControl, id} = props.record;
    const linkToResource = `#${linkToRecord(
        props.basePath || `/${props.record.resource}`,
        props.record.id
    )}/show`;
    return (
        <ButtonGroup disableElevation variant="contained">
            <ReferenceField record={props.record} reference={"order_preparation_steps"} source={"step"} link={false}>
                <ChoiceButton id={id} linkToResource={linkToResource} currentStep={currentStep} itemToPrepare={itemToPrepare} paletteToControl={paletteToControl}/>
            </ReferenceField>
            
            { props.viewImage && <BtnSeeImgPrep data={linkToResource}/> }

            { props.eye && <Button href={linkToResource} startIcon={<ImageEye />}/> }

        </ButtonGroup>
    )
}

OrderPreparationStartStep.propTypes = {
    record: PropTypes.object,
    eye: PropTypes.bool,
};
OrderPreparationStartStep.defaultProps = {
    eye: false,
};


export default OrderPreparationStartStep;
