import {Box} from "@mui/material";
import * as React from "react";
import PropTypes from "prop-types";

const Counter = (props) => (
    <Box sx={{textAlign: 'center', marginLeft: 1, marginRight: 1}}>
        <Box sx={{fontWeight: 'bolder', textTransform: 'uppercase'}}>{props.label}</Box>
        <div style={{marginTop: 7}}>
            {props.value}
        </div>
    </Box>
);
Counter.propsType = {
    label: PropTypes.string,
    value: PropTypes.string | PropTypes.number,
};

const PreparationCounterState = (props) => (
    <>
        <Counter label={"Produits"} value={props.record.totalProducts} />
        <Counter label={"Quantités"} value={props.record.totalProductsQuantity} />
        <Counter label={"Palettes"} value={props.record.totalPalettes} />
    </>
);

PreparationCounterState.propsType = {
    record: PropTypes.object
};

export default PreparationCounterState;