import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {LinearProgress} from "@mui/material";
import {IconPickerItem} from "react-fa-icon-picker";

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', with: '100%' }}>
            <LinearProgress sx={{ height: 20 }} variant="determinate" color={props.percent >= 100 ? 'success' : 'primary'} value={props.percent} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" color="white" fontWeight="bolder" marginTop="3px">
                    {props.content}
                </Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    percent: PropTypes.number.isRequired,
    content: PropTypes.string.isRequired,
};

export default function LinearProgressWithLabelStatic(props) {
    const progress = props.record[props.source];
    const content = progress ? progress.content : "";
    const percent = progress ? progress.percent : "0";
    const currentStep = progress ? progress.currentStep : "";

    if(!currentStep){
        if(percent < 100){
            return <IconPickerItem icon={'FaTimes'} size={20} color={'red'}/>
        } else {
            return <IconPickerItem icon={'FaCheck'} size={20} color={'green'}/>
        }
    }
    return <LinearProgressWithLabel percent={percent} content={content}/>;
}
