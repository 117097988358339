import FieldGuesser from "@api-platform/admin/lib/FieldGuesser";
import NameFieldGuesser from "../../../field/Name";
import ColorFieldGuesser from "../../../field/Color";
import * as React from "react";
import {FormListGuesser} from "../../../form/List";

const WarehouseList = props => (
    <FormListGuesser {...props}>
        <NameFieldGuesser source="name"/>
        <FieldGuesser source={"code"} />
        <ColorFieldGuesser source="color"/>
    </FormListGuesser>
);

export default WarehouseList
