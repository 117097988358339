import * as React from "react";
import PropTypes from 'prop-types';
import {Avatar} from "@mui/material";
import {useState, useEffect} from 'react';
import {LinearProgress} from "@mui/material";

import dataProvider from "../../utils/dataProvider";

const AgenceList = (props) => {

    return (
        props.agence.map((agency) => {
            return (
                <Avatar key={agency} variant="square" sx={{
                    borderRadius: '3px',
                    fontWeight: 'bold',
                    marginRight: '5px'
                }}>{agency}</Avatar>
            )
        })
    );
}

const AgencePresenter = (props) => {

    const [AgencyList, setAgencyList] = useState( [] );
    const [Loader, setLoader] = useState( false );
    
    useEffect(() => {
        const fetchData = async () => {
            props.ids.map((id) => {
                var idagency = props.data[id];
                setLoader(true);
                dataProvider.getOne('agencies', {
                    id: idagency
                }).then(({data}) => {
                    if (!AgencyList.includes(data.code)) {
                        setAgencyList(current => [...current, data.code]);
                        setLoader(false);
                    }
                });
            })
            
        };
        if(Loader == false){
            fetchData();
        }
    }, [props]);
    if(Loader){return <LinearProgress />;}
    if (props.data == null || props.ids == null || AgencyList.length === 0){return null}
    
    return (
        <div style={{display: "flex"}}>
        <AgenceList agence={AgencyList}/>
        </div>
        
        
    );
}

AgencePresenter.propTypes = {
    record: PropTypes.object,
};

export default AgencePresenter;
