import {ENTRYPOINT} from "../config/entrypoint";
import {Redirect, Route} from "react-router-dom";
import {
    hydraDataProvider as baseHydraDataProvider,
    fetchHydra as baseFetchHydra,
    useIntrospection
} from "@api-platform/admin";

import parseHydraDocumentation from "@api-platform/api-doc-parser/lib/cjs/hydra/parseHydraDocumentation";

const getHeaders = () => localStorage.getItem("token") ? {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
} : {};

const fetchHydra = (url, options = {}) =>
    baseFetchHydra(url, {
        ...options,
        headers: getHeaders,
    });

const RedirectToLogin = () => {
    const introspect = useIntrospection();

    if (localStorage.getItem("token")) {
        introspect();
        return <></>;
    }
    return <Redirect to="/login"/>;
};

const apiDocumentationParser = async () => {
    try {
        const {api} = await parseHydraDocumentation(ENTRYPOINT, {headers: getHeaders});
        return {api};
    } catch (result) {
        if (result.status !== 401) {
            throw result;
        }

        // Prevent infinite loop if the token is expired
        localStorage.removeItem("token");

        return {
            api: result.api,
            customRoutes: [
                <Route key="/" path="/" component={RedirectToLogin}/>
            ],
        };
    }
};

const dataProvider = baseHydraDataProvider({
    entrypoint: ENTRYPOINT,
    httpClient: fetchHydra,
    apiDocumentationParser,
});

export function fetchData(url, method = 'GET', body = null) {
    const content = {method: method, headers: {Authorization: `Bearer ${localStorage.getItem("token")}`}};
    if(body) content['body'] = body;
    return fetch(ENTRYPOINT + url, content);
}

export default dataProvider;
