import * as React from "react";
import PropTypes from 'prop-types';

const IdFieldGuesser = (props) => {
    if(props.record == null)
        return null
    return (<span>{props.record.id.split('/')[2]}</span>);
}

IdFieldGuesser.propTypes = {
    record: PropTypes.object,
};

export default IdFieldGuesser;
