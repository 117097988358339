import * as React from 'react'
import {IconPicker} from 'react-fa-icon-picker'
import {InputGuesser} from "@api-platform/admin";

export class IconPickerGuesserState {
    value: string;
}

export class IconPickerGuesserProps {
    value: string;
    source: string;
    resource: string;
    onChange: function;
    record: any;
}

export class InputIconPickerGuesser extends React.Component<IconPickerGuesserProps, IconPickerGuesserState> {
    constructor(props) {
        super(props);
        this.state = {
            value: props.record[props.source] ?? 'FaAdobe',
        }
    }

    change = (v) => {
        if(this.props.onChange){
            this.props.onChange();
            this.props.record[this.props.source] = v;
        }
        this.setState({value: v});
    }

    render() {
        return (
            <div>
                <InputGuesser
                    source={this.props.source}
                    resource={this.props.resource}
                    initialValue={this.state.value}
                    style={{display: 'none'}}
                />
                <IconPicker containerStyles={{left: 50, top: -100}}
                            value={this.state.value}
                            onChange={(v) => this.change(v)}
                />
            </div>
        )
    }
}
