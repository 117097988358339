import * as React from "react";
import PropTypes from 'prop-types';
import {IconPickerItem} from "react-fa-icon-picker";

const BooleanField = (props) => {
    if(props.record == null)
        return null
    return (
        props.record[props.source]
            ? <IconPickerItem icon={'FaCheck'} size={20} color={'green'} />
            : <IconPickerItem icon={'FaTimes'} size={20} color={'red'} />
    );
}

BooleanField.propTypes = {
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default BooleanField;
