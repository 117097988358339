import {Box} from "@mui/material";
import PropTypes from "prop-types";
import * as React from "react";

export function getColors(color, bgColor) {
    switch (color) {
        case 'success':
            bgColor = bgColor ?? '#CCFFCC';
            return {border: '2px solid #2BAF2B', backgroundColor: bgColor, color: '#009700'};
        case 'danger':
            bgColor = bgColor ?? '#FFCCCC';
            return {border: '2px solid #990000', backgroundColor: '#FFCCCC', color: '#990000'};
        case 'info':
            bgColor = bgColor ?? '#1976d2';
            return {border: '2px solid #0958a7', backgroundColor: '#1976d2', color: 'white'};
        default:
            bgColor = bgColor ?? '#FFFFFF';
            return {border: '2px solid #989898', backgroundColor: '#FFFFFF', color: '#3C3C3C'};
    }
}

const SquareData = (props) => {
    return (
        <Box sx={{textAlign: 'center', fontWeight: 'bold', marginBottom: 'auto'}} onClick={props.onClick}>
            {props.title}
            <Box sx={Object.assign({}, {
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                fontSize: 25,
                justifyContent: 'center',
                alignItems: 'center',
                width: '100px',
                height: '100px',
                marginTop: 1,
                userSelect: 'none',
                marginLeft: 'auto',
                marginRight: 'auto'
            }, getColors(props.color, props.backgroundColor), props.onClick ? {cursor: 'pointer'} : {})}>
                {props.subTitle ? props.value : props.icon}
                {props.subTitle ? <Box component="span" sx={{fontSize: 15}}>{props.subTitle}</Box> :
                    <Box>{props.value}</Box>}
            </Box>
        </Box>
    );
}

SquareData.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    value: PropTypes.any,
    color: PropTypes.string,
    icon: PropTypes.object
}

export default SquareData;