import FormEditGuesser from "../../form/Edit";
import {ReferenceInput, SelectInput} from "react-admin";
import InputGuesser from "@api-platform/admin/lib/InputGuesser";

const NavetteEdit = props => (
    <FormEditGuesser {...props}>
        <ReferenceInput source={"carrier"} reference={"carriers"}>
            <SelectInput optionText="name" />
        </ReferenceInput>
        <InputGuesser source={"estimatedAt"} />
    </FormEditGuesser>
);

export default NavetteEdit;