import * as React from "react";
import PropTypes from 'prop-types';
import {Chip} from "@mui/material";
import {LocalShipping} from "@mui/icons-material";

const CarrierPresenter = (props) => {
    return (
        <Chip icon={<LocalShipping />} label={props.record.name} />
    );
}

CarrierPresenter.propTypes = {
    record: PropTypes.object,
};

export default CarrierPresenter;
