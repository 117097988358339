import * as React from "react";
import {ReferenceField} from "react-admin";
import CarrierPresenter from "../../presenter/Carrier";

const PreparationCarrier = (props) => (
    <ReferenceField link={false} source={'carrier'} record={props.record} reference={'carriers'}>
        <CarrierPresenter/>
    </ReferenceField>
);

export default PreparationCarrier;
