import * as React from "react";
import {Layout, OrderPreparationCheckCurrentUser, OrderPreparationStepLoader} from "../_Layout";

const page = {
    title: 'Emballer',
    fetchData: '/order_preparation/pack',
}

const OrderPreparationPackIndex = () => {
    return (
        <Layout {...page}>
            <OrderPreparationCheckCurrentUser>
                <OrderPreparationStepLoader {...page}/>
            </OrderPreparationCheckCurrentUser>
        </Layout>
    );
};

export default OrderPreparationPackIndex;
