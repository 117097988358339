import * as React from 'react'
import {Permission} from "../Permission";
import NavetteList from "./List";
import NavetteCreate from "./Create";
import NavetteEdit from "./Edit";

export default class NavetteResourceGuesser extends React.Component {
    render() {
        return (
            <Permission {...this.props} list={NavetteList} create={NavetteCreate} edit={NavetteEdit} show={null}  />
        )
    }
}
