import * as React from 'react'
import {Permission} from "../../Permission";
import ProductList from "./List";
import ProductEdit from "./Edit";

const list = props => <ProductList {...props}/>
const edit = props => <ProductEdit {...props}/>

export default function ProductResourceGuesser(props) {
    return <Permission {...props } list={list} edit={edit}/>
}
