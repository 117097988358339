import * as React from 'react'
import {Permission} from "../../Permission";
import UsersList from './List';
import UserEdit from './Edit';

const list = props => <UsersList {...props}/>
const edit = props => <UserEdit {...props}/>

export default class UserResourceGuesser extends React.Component {

    render() {
        return (
            <Permission {...this.props} list={list} edit={edit}/>
        )
    }
}
