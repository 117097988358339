import {useGetOne} from "react-admin";
import {CircularProgress} from "@mui/material";
import * as React from "react";
import ScanUserDialog from "./Dialog";

const ScanUserWrapper = (props) => {
    const { data, loading, error } = useGetOne('users', '/users/' + props.id);

    if (loading)
        return <CircularProgress/>;

    if (error) {
        return props.handleClose();
    }

    props.createScanLog({})

    return <ScanUserDialog record={data} handleClose={props.handleClose}/>
}

export default ScanUserWrapper
