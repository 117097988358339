import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import * as React from "react";
import SpotPresenter from "../../presenter/Spot";
import {ReferenceField, TextField} from "react-admin";
import ProductPresenter from "../../presenter/Product";

const ScanWarehouseLocationDialog = (props) => {
    const warehouseLocation = props.record;
    return (
        <Dialog open={true}>
            <DialogTitle>Emplacement : </DialogTitle>
            <DialogContent>
                <SpotPresenter source='spot' record={warehouseLocation}/>
                <TextField source='quantity' record={warehouseLocation} label='Quantité'/>
                <ReferenceField reference={'products'} source={'product'} record={warehouseLocation}>
                    <ProductPresenter/>
                </ReferenceField>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="success" onClick={props.handleClose}>
                    Terminer
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default ScanWarehouseLocationDialog
