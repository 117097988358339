import dataProvider from "../../../utils/dataProvider";
import {Box} from "@mui/material";
import Button from "@mui/material/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as React from "react";
import {useRefresh} from "react-admin";

const ButtonFinishPalette = (props) => {
    const refresh = useRefresh();
    const controlled = props.responsable ? 'controlledResponsible' : 'controlled'
    const data = props.responsable ? {controlledResponsible: !props.palette[controlled]} : {controlled: !props.palette[controlled]};

    const disabled = props.palette.itemsToArray.filter((fi) => !fi[controlled]).length !== 0;

    const handleClickFinishPrepare = () => {
        dataProvider.update('palettes', {
            id: props.palette.id,
            data: data
        }).then(({data}) => {
                props.setPalette(data)
                refresh();
            }
        );
    }

    if(props.palette[controlled]) return <></>

    return (
        <Box sx={{display: 'flex', justifyContent: 'space-around', alignContent: 'bottom', mt: 2, mb: 2}}>
            <Button id="control-validation" onClick={handleClickFinishPrepare} disabled={disabled} variant="contained"
                    size={"large"}
                    startIcon={<FontAwesomeIcon icon="fa-solid fa-circle-check"/>}>
                Valider le contrôle de la palette #{props.palette.number}
            </Button>
        </Box>
    )
}

export default ButtonFinishPalette
