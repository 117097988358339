import * as React from "react";
import {useGetList, Loading, FilterList, FilterListItem, TextField, BooleanField} from 'react-admin';
import {LocalShipping} from "@mui/icons-material";
import FilterListIcon from '@mui/icons-material/FilterList';
import SortIcon from '@mui/icons-material/Sort';


const TransportFilter = (props) => {
    const { data, ids, loading, error } = useGetList('carriers');

    

    if (loading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }

    const charged = "Chargé";
    
    if(props.typeDisplay == "charged"){
        return (
        
            <FilterList label="Filtre des colonnes" icon={<FilterListIcon />}>
                <FilterListItem
                label= {"Non - Chargé"}
                value={{ charged: 0 }}
                />
                <FilterListItem
                label= {"Chargé"}
                value={{ charged: 1 }}
                />
            </FilterList>
        )
    }if(props.typeDisplay === "expired"){
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        
        today = yyyy + '-' + mm + '-' + dd;

  
        return (
        
            <FilterList label="Filtre des colonnes" icon={<FilterListIcon />}>
                <FilterListItem
                label= {"Déja parti"}
                key={"deja_parti"}
                value={{ 'estimatedAt[before]': today }}
                />
                <FilterListItem
                label= {"Programmée"}
                key={"programmee"}
                value={{ 'estimatedAt[after]': today }}
                />
            </FilterList>
        )
    }else{
        return (
        
            <FilterList label="resources.carriers.filter_name" icon={<LocalShipping />}>
                {ids.map(id =>
                    <FilterListItem
                        label={
                            <TextField record={data[id]} source="name" />
                        }
                        key={id}
                        value={{
                            carrier: {
                                name: data[id].name
                            }
                        }}
                    />
                )}
            </FilterList>
        )
    }
    
};

TransportFilter.propTypes = {

};

export default TransportFilter;
