import {Layout} from "../_Layout";
import Alert from "../../component/presenter/Alert";

const DashboardIndex = (props) => {
    return <Layout title={"Tableau de bord"}>
        <Alert type={'error'} value={'Aucun tableau de bord pour le moment'}/>
    </Layout>
}

export default DashboardIndex
