import * as React from "react";
import PropTypes from 'prop-types';
import {IconPickerItem} from "react-fa-icon-picker";

const StatePresenter = (props) => {
    const size = props.size ?? 20;
    const hiddenLabel = props.hiddenLabel ?? true;

    return <div className={'IconAlignWithText'}>
        <IconPickerItem icon={props.record.icon} size={size} color={props.record.color} />
        {!hiddenLabel && props.record.name}
    </div>;
}

StatePresenter.propTypes = {
    record: PropTypes.object,
};

export default StatePresenter;
