import * as React from 'react'
import {FormListGuesser} from "../../../form/List";
import FieldGuesser from "@api-platform/admin/lib/FieldGuesser";
import ListActions from "./Actions/ListActions";
import ImageField from "../../../field/ImageField";
import {ChipField, ReferenceField, FunctionField} from "react-admin";
import Attribute from "../../../presenter/Attribute";

export default function ProductList(props) {
    return (
        <FormListGuesser {...props} actions={<ListActions/>} hasShow={false}>
            <ImageField source={"image"}/>
            <FieldGuesser source={"name"}/>
            <ReferenceField reference={"product_categories"} source={"category"}>
                <ChipField source={"name"}/>
            </ReferenceField>
            <FunctionField label="Caractéristiques" render={record =>
                record.attributesToArray.map((attribute) => {
                    if(!attribute[0])
                        return null
                    return <Attribute key={attribute[0]} value={`${attribute[0]} : ${attribute[1]}`}/>
                })
            } />
            <FieldGuesser source={"ean"}/>
            <FieldGuesser source={"packagingUnit"}/>
            <FieldGuesser source={"packagingType"}/>
            <FieldGuesser source={"packagingQuantity"}/>
            <FieldGuesser source={"weight"}/>
            <FieldGuesser source={"length"}/>
            <FieldGuesser source={"width"}/>
            <FieldGuesser source={"height"}/>
            <FieldGuesser source={"comment"}/>
        </FormListGuesser>
    )
}

