import * as React from 'react';
import {AppBar, Logout, UserMenu, useLogout} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import Logo from './Logo';
import CurrentTeam from "./team/CurrentTeam";
import {makeStyles} from "@material-ui/core/styles";
import {useContext, useEffect, useState} from "react";
import authProvider from "../../utils/authProvider";
import {UserContext} from "../context/UserContext";
import {Redirect} from "react-router-dom";
import ScanWrapper from "../scan/Wrapper";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});

const MyLogoutButton = props => {
    const [user, setUser] = useContext(UserContext)
    const logout = useLogout();
    const handleClick = () => {
        let promise = logout();
        setUser(null);
        return promise;
    }
    return <Logout {...props} onClick={handleClick} icon={<ExitToAppIcon/>} />;
}

const MyUserMenu = () => <UserMenu><MyLogoutButton /></UserMenu>;

const AdminAppBar = props => {
    const classes = useStyles();
    const [user, setUser] = useContext(UserContext)
    const [error, setError] = useState(false);

    useEffect(() => {
        if(user == null){
            authProvider.getUser().then(({data}) => {
                setUser(data[0])
            }).catch(() => {
                setError(true);
            })
        }
    }, [user, setUser]);

    if(error)
        return <Redirect to={'/login'}/>;

    return (
        <AppBar {...props} userMenu={<></>}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <Logo />
            <span className={classes.spacer} />
            <CurrentTeam />
            <ScanWrapper/>
        </AppBar>
    );
};

export default AdminAppBar;
