import {useState} from "react";
import {useNotify, useRefresh, useUnselectAll} from "react-admin";
import dataProvider from "../../utils/dataProvider";
import NavetteDialog from "./NavetteDialog";
import Button from "@mui/material/Button";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import * as React from "react";

export default function NavetteDialogAction(props) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();

    const handleValidation = (navette) => {
        setLoading(true)
        dataProvider.update('order_preparations', {
            id: props.record.id,
            data: {navettes: [navette].concat(props.record.navettes)}
        }).then(() => {
            setOpen(false)
            setLoading(false)
            refresh();
            notify('Les préparations de commande sont mises à jour');
            unselectAll('order_preparations');
        }).catch(() => {
            setOpen(false)
            setLoading(false)
            notify('Erreur: les préparations de commande n\'ont pas pu être mis à jour', {type: 'error'})
        });
    }

    if (open) {
        return (
            <NavetteDialog loading={loading} open={open} setOpen={setOpen} handleValidation={handleValidation} />
        )
    }

    return (
        <Button startIcon={<LocalShippingIcon/>} onClick={() => setOpen(true)}>
            Navette
        </Button>

    );
}