import {ReferenceArrayField, SingleFieldList} from "react-admin";
import {Box, Button} from "@mui/material";
import SmallSquareData from "../../../component/presenter/square/SmallSquareData";
import * as React from "react";

const Palette = (props) => {
    if(props.record == null) return null;
    const controlled = props.responsable ? 'controlledResponsible' : 'controlled';
    const same = props.currentPalette.id === props.record.id;
    return (
        <Button onClick={() => {
            props.setPalette(props.record)
        }} style={{lineHeight: "1.1"}}>
            <SmallSquareData
                bigger={same}
                value={'#' + props.record.number}
                color={props.record[controlled] ? 'success' : 'danger'}
                subTitle={same ? props.record.typeName : null}/>
        </Button>
    )
}

const BreadcrumbPalette = (props) => {
    return (
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 2, marginBottom: 2}}>
            <Box sx={{fontWeight: 'bold'}}>Palettes à contrôler</Box>
            <Box sx={{display: 'inline-flex', gap: 2}}>
                <ReferenceArrayField record={props.orderPreparation} reference="palettes" source="palettes">
                    <SingleFieldList linkType={false} >
                        <Palette currentPalette={props.palette} setPalette={props.setPalette} responsable={props.responsable}/>
                    </SingleFieldList>
                </ReferenceArrayField>
            </Box>
        </Box>
    );
}

export default BreadcrumbPalette
