import * as React from "react";
import PropTypes from 'prop-types';
import {useGetOne} from 'react-admin';
import {CircularProgress} from "@mui/material";
import ViewContentPalette from '../button/ViewContentPalette';

const GetOne = (props) => {
    const { data, loading, error } = useGetOne(props.source, props.record[props.recordData]);

    if (loading) { return <CircularProgress size='1rem' />; }
    if (error) { return <p>ERROR</p>; }

    if(props.seeInside){
        return (<>{data[props.dataName]}<ViewContentPalette orderprep={props.orderprep} data={props.record}/></>)
    }else{
        return(data[props.dataName])
    }
}

GetOne.propTypes = {
    recordData: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
    dataName: PropTypes.string.isRequired,
};

export default GetOne;
