import {Datagrid, ReferenceManyField, TextField, Link, ReferenceField} from "react-admin";
import * as React from "react";
import {Box, Button, Divider} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {
    faPlusSquare,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import NumberField from "../../../../../field/NumberField";
import PaletteItemButtonAction from "../../../../Palette/Palette/ButtonAction";
import BooleanField from "../../../../../field/Boolean";
import SpotPresenter from "../../../../../presenter/Spot";
import {useState} from "react";
import { FunctionField } from 'react-admin';

library.add(faPlusSquare, faTrash);

const OrderPreparationShowPalettes = (props) => {
    const volumes = [];
    props.record.palettes.forEach((p) => volumes[p] = -1);
    const [volume, setVolume] = useState(volumes);
    const location = window.location.hash;

    const handleVolume = (data) => {
        volumes[data.id] = data.volume;
        setVolume(volumes)
    }

    return (
        <>
            <ReferenceManyField {...props}
                                reference="palettes"
                                target="orderPreparation.id"
                                label="Palettes"
            >
                <Datagrid>
                    <PaletteItemButtonAction label="Actions" orderPreparation={props.record} create={false}/>
                    <TextField source="number"/>
                    <ReferenceField reference={'products'} source={'type'} link={false}>
                        <TextField label={"Type"} source="name"/>
                    </ReferenceField>
                    <TextField source="products"/>
                    <NumberField handleData={handleVolume} source="length"/>
                    <NumberField handleData={handleVolume} source="width"/>
                    <NumberField handleData={handleVolume} source="height"/>
                    <FunctionField label={"Volume"} render={record => parseFloat(((record.length/100) * (record.width/100) * (record.height/100))).toFixed(2) + " m³"} />
                    <FunctionField label={"Poids"} render={record => record.weight/1000 + " kg"} />
                    <NumberField source="weight"/>
                    <SpotPresenter source='spot'/>
                    <BooleanField source='controlled'/>
                    <BooleanField source='packed'/>
                    <BooleanField source='charged'/>
                </Datagrid>
            </ReferenceManyField>

            <Divider/>
            <Box sx={{mt: 5}}>
                <Link to={{pathname: '/palettes/create', state: {prevPath: location, orderPreparationId: props.record.id}}}>
                    <Button variant="contained" startIcon={<FontAwesomeIcon icon="fa-plus-square"/>}>
                        Ajouter une palette
                    </Button>
                </Link>
            </Box>
        </>
    )
}

export default OrderPreparationShowPalettes;
