import * as React from "react";
import {Box} from "@mui/material";
import PreparationNumber from "../../../component/box/preparation/PreparationNumber";
import PreparationIdentity from "../../../component/box/preparation/PreparationIdentity";
import PreparationCarrier from "../../../component/box/preparation/PreparationCarrier";
import PreparationCounterState from "../../../component/box/preparation/PreparationCounterState";
import LinearProgressWithLabelField from "../../../component/field/LinearProgressWithLabelField";
import BackButton from "../../../component/button/BackButton";
import {ReferenceField} from "react-admin";
import StatePresenter from "../../presenter/StatePresenter";

const flexbox = {display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 2};

const PreparationLine = (props) => (
    <Box sx={flexbox}>
        {/*<Link>*/}
        {/*    <IconButton sx={{mr: 2}} size={"large"} aria-label="Précédente" component="span">*/}
        {/*        <ArrowCircleLeftIcon/>*/}
        {/*    </IconButton>*/}
        {/*</Link>*/}

        <Box>
            <PreparationNumber record={props.record}/>
        </Box>

        {/*<Link>*/}
        {/*    <IconButton sx={{ml: 2}} size={"large"} aria-label="Suivante" component="span">*/}
        {/*        <ArrowCircleRightIcon/>*/}
        {/*    </IconButton>*/}
        {/*</Link>*/}
    </Box>
);

const PreparationResume = (props) => (
    <>
        <Box sx={{display: 'flex'}}>
            <BackButton/>
        </Box>
        <PreparationLine record={props.record}/>
        <Box sx={flexbox}>
            <PreparationIdentity record={props.record}/>
        </Box>
        <Box sx={flexbox}>
            <PreparationCarrier record={props.record}/>
        </Box>
        <Box sx={flexbox}>
            <ReferenceField link={false} source={'state'} record={props.record} reference={'order_preparation_states'}>
                <StatePresenter />
            </ReferenceField>
        </Box>
        <Box sx={flexbox}>
            <PreparationCounterState record={props.record}/>
        </Box>
        <Box sx={{textAlign: 'center', maxWidth: 300, margin: 'auto', marginBottom: 2}}>
            <LinearProgressWithLabelField source="progress" record={props.record}/>
        </Box>
    </>
);

export default PreparationResume;
