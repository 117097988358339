import {cloneElement, createContext, useEffect, useState} from "react";
import * as React from "react";

export const TeamContext = createContext([null, () => {}])

export const TeamContextProvider = ({ value, children }) => {
    const [team, setTeam] = useState(value);

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem('team'));

        if (data) {
            setTeam(data)
        }
    }, [])

    useEffect(() => {
        localStorage.setItem('team', JSON.stringify(team))
    }, [team])

    return (
        <TeamContext.Provider value={[team, setTeam]}>
            {cloneElement(children, { team: team })}
        </TeamContext.Provider>
    );
};

