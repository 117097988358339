import {usePermissions, useTranslate} from "react-admin";
import * as React from "react";

const MenuRole = (props: { role: string, children: ReactNode }) => {
    const {permissions} = usePermissions();
    const translate = useTranslate();

    if (!permissions) return null;

    if(permissions.includes(props.role)) {
        return (
            <>
                {React.Children.map(props.children, c => {
                    if (React.isValidElement(c)) {
                        return React.cloneElement(c, {
                            ...props,
                            permissions: permissions,
                            translate: translate
                        });
                    }
                })}
            </>
        )
    }
    return null;
}

export default MenuRole
