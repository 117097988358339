import * as React from "react";
import {Box, IconButton} from "@mui/material";
import BackButton from "../../../component/button/BackButton";
import PropTypes from "prop-types";
import {ChipField, DateField, ReferenceArrayField, ReferenceField, SingleFieldList, TextField} from "react-admin";
import {Chat, LocalShipping} from "@mui/icons-material";
import ChannelPresenter from "../../../component/presenter/Channel";
import Counter from "../../../component/field/Counter";
import {convertWeightToString} from "../../../utils/functionProvider";
import {DownloadpdfList} from '../../../component/module/btnDownloadPDFfromPresta';

const flexbox = {display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2};

const Header = (props) => (
    <>
        <TopHeader navette={props.navette}/>
        <BottomHeader navette={props.navette}/>
    </>
)
const TopHeader = (props) => (
    <Box sx={flexbox}>
        <BackButton/>
        <Box sx={{fontWeight: 'bolder', fontSize: 25}}>
            Détail navette #{props.navette.id.split('/')[2]}
        </Box>
        <Box sx={{marginLeft: 2, marginRight: 2}}>
            <ReferenceArrayField label='' source={'channels'} record={props.navette} reference={'channels'} link={false}>
                <SingleFieldList>
                    <ChannelPresenter/>
                </SingleFieldList>
            </ReferenceArrayField>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', m: 1 }}>
            <DownloadpdfList sx={{m: 1}} type={"Invoice"} navette={props.navette} />
            <DownloadpdfList sx={{m: 1}} type={"DeliverySlip"} navette={props.navette} />
        </Box>
        <Box>
            <ReferenceField record={props.navette} reference={"carriers"} source={"carrier"} link={false}>
                <ChipField source="name" icon={<LocalShipping />} />
            </ReferenceField>
        </Box>
    </Box>
);
const BottomHeader = (props) => (
    <Box sx={Object.assign({}, flexbox, {justifyContent: 'space-around'})}>
        <Counter record={props.navette} source="orderPreparations" label={'Commandes'}/>
        <Counter record={props.navette} source="palettes" label={'Palettes'}/>
        <span>Poids total : {convertWeightToString(props.navette.weight)}</span>
        <span>Volume : <TextField record={props.navette} source="volume"/> m&sup3;</span>
        <DateField record={props.navette} source="estimatedAt" locales="fr-FR" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }}/>
    </Box>
);

const NavetteLargeResume = (props) => (
    <Header navette={props.navette}/>
);

NavetteLargeResume.propTypes = {
    navette: PropTypes.any,
}

export default NavetteLargeResume;
