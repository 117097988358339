import {MenuItemLink} from "react-admin";
import * as React from "react";

const MenuItem = (props) => {
    if (props.permissions && props.permissions.includes(props.role)) {
        return (
            <MenuItemLink
                leftIcon={props.leftIcon}
                style={props.style}
                to={'/' + props.resource}
                primaryText={props.translate('resources.' + props.resource + '.name')}
            />
        )
    }
    return null;
}

export default MenuItem
