import decodeJwt from 'jwt-decode';
import { ENTRYPOINT } from '../config/entrypoint';
import dataProvider from "./dataProvider";

const authProvider = {
    login: ({ username, password }) => {
        const request = new Request(
            `${ENTRYPOINT}/authentication_token`,
            {
                method: 'POST',
                body: JSON.stringify({ email: username, password }),
                headers: new Headers({ 'Content-Type': 'application/json' }),
            }
        );
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ token }) => {
                const decodedToken = decodeJwt(token);
                localStorage.setItem('token', token);
                localStorage.setItem('email', decodedToken.username);
                localStorage.setItem('permissions', decodedToken.roles);
            });
    },
    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('email');
        localStorage.removeItem('permissions');
        return Promise.resolve();
    },
    checkAuth: () => {
        try {
            // @ts-ignore
            if (!localStorage.getItem('token') || new Date().getTime() / 1000 > decodeJwt(localStorage.getItem('token'))?.exp) {
                return Promise.reject();
            }
            return Promise.resolve();
        } catch (e) {
            // override possible decodeJwt error
            return Promise.reject();
        }
    },
    checkError: (err) => {
        if ([401, 403].includes(err?.status || err?.response?.status)) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getEmail: () => {
        return localStorage.getItem('email');
    },
    getUser: () => {
        if (!localStorage.getItem('email')) {
            return Promise.reject();
        }
        return dataProvider.getList('users', {
            pagination: {page: 1, perPage: 1},
            sort: {field: 'id', order: 'ASC'},
            filter: {'email': authProvider.getEmail()}
        });
    },
    getPermissions: () => {
        const role = localStorage.getItem('permissions');
        return role ? Promise.resolve(role) : Promise.reject();
    }
}

export default authProvider;
