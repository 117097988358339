import {useState} from "react";
import dataProvider from "../../../../../utils/dataProvider";
import {Button, CreateButton, downloadCSV, ExportButton, TopToolbar} from "react-admin";
import {ImportButton} from "react-admin-import-csv";
import * as React from "react";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import {Dialog, DialogTitle} from "@mui/material";
import ImportProductsPictures from "./ImportProductsPictures";
import jsonExport from 'jsonexport/dist';

const ROW_DELIMITER = ';'

const exporter = (records, fetchRelatedRecords) => {
    fetchRelatedRecords(records, 'category', 'product_categories').then(categories => {
        const productsForExport = records.map(record => {
            return ({
                ean: record.id.split('/').pop(),
                name: record.name,
                attributes: record.attributes,
                packagingUnit: record.packagingUnit,
                packagingType: record.packagingType,
                packagingQuantity: record.packagingQuantity,
                weight: record.weight,
                length: record.length,
                width: record.width,
                height: record.height,
                category: categories[record.category].name,
                palette: record.palette,
            })
        });

        jsonExport(productsForExport, {rowDelimiter: ROW_DELIMITER}, (err, csv) => {
            const BOM = '\uFEFF' // utf8 support
            downloadCSV(`${BOM}${csv}`, 'products');
        });
    });
};

const ImportPicturesButton = () => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Button label={"Importer photos"} startIcon={<AddAPhotoIcon/>} onClick={() => setOpen(true)}/>
            <Dialog onClose={() => setOpen(false)} open={open}>
                <DialogTitle>Importer les photos de vos produits</DialogTitle>
                <ImportProductsPictures resource="products" target="image"  />
            </Dialog>
        </>
    )
}

const ListActions = (props) => {
    const [loadingCategories, setLoadingCategories] = useState(false);
    const [categories, setCategories] = useState(null);

    if(!loadingCategories && categories === null) {
        setLoadingCategories(true)
        dataProvider.getList('product_categories', {
            pagination: {page: 1, perPage: 999},
            sort: {field: 'id', order: 'ASC'},
            filter: {},
        }).then(({data}) => {
            setCategories(data)
            setLoadingCategories(false)
        });
    }

    const { className } = props;
    const config: ImportConfig = {
        logging: true,
        transformRows: (csvRows) => {
            return csvRows.map((row) => {
                row['id'] = '/products/' + row['ean'].toString().replace(' ', '')
                delete row['ean']
                row['@type'] = 'Product'

                let category = categories.filter((c) => c.name === row['category']).pop();
                if(!category) {
                    dataProvider.create('product_categories', {
                        data: {
                            name: row['category']
                        }
                    }).then((result) => {
                        //FIXME: Risque de bug
                        row['category'] = result.data.id
                    });
                } else {
                    row['category'] = category.id
                }
                return row;
            });
        },
        parseConfig: {
            delimiter: ROW_DELIMITER,
            dynamicTyping: true
            // For all options see: https://www.papaparse.com/docs#config
        }
    }

    return (
        <TopToolbar className={className}>
            <CreateButton/>
            <ExportButton exporter={exporter}/>
            { loadingCategories || categories === null ? null : <ImportButton {...props} {...config} /> }
            <ImportPicturesButton />
        </TopToolbar>
    );
};

export default ListActions;