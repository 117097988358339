import EditGuesser from "@api-platform/admin/lib/EditGuesser";
import InputGuesser from "@api-platform/admin/lib/InputGuesser";

const UserEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source={"firstName"} />
        <InputGuesser source={"lastName"} />
        <InputGuesser source={"email"} />
    </EditGuesser>
);

export default UserEdit;