import React from 'react';
import {InputGuesser} from "@api-platform/admin";

export class NameGuesserState {
}

export class NameGuesserProps {
    source: string;
    resource: string;
    type: string;
    onChange: any;
}

export class InputNameGuesser extends React.Component<NameGuesserProps, NameGuesserState> {
    render() {
        return <>
            <InputGuesser
                {...this.props}
                resource={this.props.resource}
                type={this.props.type}
                onChange={this.props.onChange}
            />
        </>
            ;
    }
}
