import * as React from 'react'
import {Permission} from "../Permission";
import ChannelList from "./List";
import ChannelShow from "./Show";
import ChannelEdit from "../Warehouse/Warehouse/Edit";
import ChannelCreate from "../Warehouse/Warehouse/Create";

export default class ChannelResourceGuesser extends React.Component {
    render() {
        return (
            <Permission {...this.props } list={ChannelList} show={ChannelShow} edit={ChannelEdit} create={ChannelCreate}/>
        )
    }
}
