import EditGuesser from "@api-platform/admin/lib/EditGuesser";
import InputGuesser from "@api-platform/admin/lib/InputGuesser";
import { ReferenceInput, SelectInput } from 'react-admin';

const ProductEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source={"ean"} />
        <InputGuesser source={"name"} />
        <InputGuesser source={"palette"} />
        <ReferenceInput label="Catégorie" source="category" reference="product_categories">
            <SelectInput optionText="name" />
        </ReferenceInput>
        <InputGuesser source={"weight"} />
        <InputGuesser source={"length"} />
        <InputGuesser source={"width"} />
        <InputGuesser source={"height"} />
        <InputGuesser source={"packagingUnit"} />
        <InputGuesser source={"packagingType"} />
        <InputGuesser source={"packagingQuantity"} />
        <InputGuesser source={"attributes"} />
        <InputGuesser source={"comment"} />
    </EditGuesser>
);

export default ProductEdit;