import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import * as React from "react";
import {Product} from "../../../pages/Prepare/Show/Product";

const ScanProductDialog = (props) => {
    return (
        <Dialog open={true}>
            <DialogTitle>Produit : </DialogTitle>
            <DialogContent>
                <Product product={props.record}/>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="success" onClick={props.handleClose}>
                    Terminer
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default ScanProductDialog
