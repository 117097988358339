import {useGetOne} from "react-admin";
import {CircularProgress} from "@mui/material";
import * as React from "react";
import ScanWarehouseLocationDialog from "./Dialog";

const ScanWarehouseLocationWrapper = (props) => {
    const { data, loading, error } = useGetOne('warehouse_locations', '/warehouse_locations/' + props.id);

    if (loading)
        return <CircularProgress/>;

    if (error) {
        return props.handleClose();
    }

    props.createScanLog({})

    return <ScanWarehouseLocationDialog record={data} handleClose={props.handleClose}/>
}

export default ScanWarehouseLocationWrapper
