import * as React from 'react'
import {Permission} from "../../Permission";
import WarehouseLocationList from "./List";
import WarehouseLocationCreate from "./Create";
import WarehouseLocationEdit from "./Edit";

export default class WarehouseLocationResourceGuesser extends React.Component {
    list = props => (
        <WarehouseLocationList {...props}/>
    );

    create = props => (
        <WarehouseLocationCreate {...props}/>
    );

    edit = props => (
        <WarehouseLocationEdit {...props}/>
    );


    render() {
        return (
            <Permission {...this.props } list={this.list} create={this.create} edit={this.edit}/>
        )
    }
}
