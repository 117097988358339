import * as React from 'react'
import {Divider} from "@mui/material";
import PreparationLargeResume from "../../component/box/preparation/PreparationLargeResume";
import {GetOneOrderPreparation} from "../GetOne";
import ButtonFinishPreparation from "../Prepare/Show/ButtonFinish";
import OrderPreparationGridPalettes from "./Show/ShowPalette";
import {Title} from "react-admin";

const ShowWithOrderPreparation = (props) => {
    return (
        <>
            <PreparationLargeResume orderPreparation={props.orderPreparation}/>
            <Divider/>
            <OrderPreparationGridPalettes orderPreparation={props.orderPreparation} setOrderPreparation={props.setOrderPreparation}/>
            <Divider />
            <ButtonFinishPreparation orderPreparation={props.orderPreparation} step='pack'/>
        </>
    )
}

const OrderPreparationPackShow = (props) => {

    return (
        <>
            <Title title={"Emballer"}/>
            <GetOneOrderPreparation id={decodeURIComponent(props.match.params.id)}>
                <ShowWithOrderPreparation/>
            </GetOneOrderPreparation>
        </>
    )
}
export default OrderPreparationPackShow;
