import * as React from 'react'
import {Divider} from "@mui/material";
import {GetOneNavette} from "../GetOne";
import NavetteLargeResume from "./Show/NavetteLargeResume";
import ListPalettes from "./Show/ListPalettes";
import ButtonFinishNavette from "./Show/ButtonFinishNavette";
import {ChipField, FunctionField, ReferenceField, TextField, Title, useRefresh, useVersion, ReferenceArrayField, SingleFieldList} from "react-admin";
import GetOne from "../../component/field/GetOne";
import NumberField from "../../component/field/NumberField";
import ListGuesser from "@api-platform/admin/lib/ListGuesser";
import SetNavetteButton from "../../component/button/SetNavetteButton";

import ChannelAndOrderIdPresenter from "../../component/presenter/ChannelAndOrderIdPresenter";

const ButtonsAction = (props) => (
    <SetNavetteButton contextUsed={props.contextUsed} palette={props.record} refresh={props.refresh}/>
)

const ListPalettesWithoutNavette = (props) => {
    
    return (
        <>
        <h2 style={{marginBottom: '-50px'}}>Palette(s) sans navette :</h2>
        <ListGuesser
            empty={false}
            actions={null}
            hasEdit={false}
            hasShow={false}
            hasCreate={false}
            exporter={false}
            bulkActionButtons={false}
            resource={'palettes'}
            basePath={'/palettes'}
            filter={{controlled: true, controlledResponsible: true, packed: true, exists: {navette: false}}}
        >   
            <FunctionField
                    label="Palette n°"
                    render={record => `${record.id.replace('/palettes/', '')}`}
            />
            <ReferenceField label="Commande" reference={'order_preparations'} source={'orderPreparation'} link={true} linkType="show">
                <ChannelAndOrderIdPresenter label='Canal' sortBy="channel.code"/>
            </ReferenceField>
            <TextField source="number" sortBy={"number"} />
            <GetOne source="products" recordData="type" dataName="name" label='Type'/>
            <TextField source="products"/>
            <NumberField source="length"/>
            <NumberField source="width"/>
            <NumberField source="height"/>
            <TextField source="volume"/>
            <TextField source="weight"/>
            <ButtonsAction contextUsed="WithoutPalette" label={"Actions"} {...props} />
        </ListGuesser>
        </>
    )
}

const ShowWithNavette = (props) => {
    const version = useVersion();
    const refresh = useRefresh();
    return (
        <>
            <NavetteLargeResume key={'resume' + version} navette={props.navette}/>
            <Divider/>
            <ListPalettes key={'palette' + version} navette={props.navette} setNavette={props.setNavette}/>
            <Divider />
            <ListPalettesWithoutNavette key={'palettew' + version} refresh={refresh} navette={props.navette} setNavette={props.setNavette}/>
            <Divider />
            <ButtonFinishNavette navette={props.navette} step='charge'/>
        </>
    )
}

const OrderPreparationChargeShow = (props) => {

    return (
        <>
            <Title title={"Charger"}/>
            <GetOneNavette id={decodeURIComponent(props.match.params.id)}>
                <ShowWithNavette/>
            </GetOneNavette>
        </>
    )
}
export default OrderPreparationChargeShow;
