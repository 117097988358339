import * as React from 'react'
import {FormListGuesser} from "../../../form/List";
import {ReferenceArrayInput, ReferenceField, SelectArrayInput, TextField, TextInput, BooleanInput} from "react-admin";
import SpotPresenter from "../../../presenter/Spot";
import BooleanField from "../../../field/Boolean";
import NumberField from "../../../field/NumberField";
import ChannelAndOrderIdPresenter from "../../../presenter/ChannelAndOrderIdPresenter";

const postFilters = [
    <TextInput label="N°" source="orderPreparation.id"/>,
    <ReferenceArrayInput label="Canal" source="orderPreparation.channel" reference="channels" alwaysOn>
        <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>,
    <TextInput label="Id Commande" source="orderPreparation.orderId" alwaysOn />,
    <BooleanInput label="Controllé" source="controlled"/>,
    <BooleanInput label="Controllé Responsable" source="controlledResponsible"/>,
    <BooleanInput label="Emballé" source="packed"/>,
    <BooleanInput label="Chargé" source="charged"/>
];

export default function PaletteList(props) {
    return (
        <FormListGuesser {...props} hasEdit={false} hasShow={false} filters={postFilters} >
            <TextField source="ean"/>
            <ReferenceField label="Commande" reference={'order_preparations'} source={'orderPreparation'} link={true} linkType="show">
                <ChannelAndOrderIdPresenter label='Canal' sortBy="channel.code"/>
            </ReferenceField>
            <TextField source="number"/>
            <ReferenceField reference={'products'} source={'type'} link={false}>
                <TextField label={"Type"} source="name"/>
            </ReferenceField>
            <TextField source="products"/>
            <TextField source="length"/>
            <TextField source="width"/>
            <TextField source="height"/>
            <TextField source="volume"/>
            <NumberField source="weight"/>
            <SpotPresenter source='spot'/>
            <BooleanField source='controlled'/>
            <BooleanField source='packed'/>
            <BooleanField source='charged'/>
        </FormListGuesser>
    )
}

