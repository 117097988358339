import {useContext} from "react";
import {UserContext} from "../../context/UserContext";
import dataProvider from "../../../utils/dataProvider";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import * as React from "react";

const ScanUserDialog = (props) => {
    const [userLogged, setUserLogged] = useContext(UserContext)
    const [team, setTeam] = useContext(UserContext)
    const user = props.record;

    const AddUserToTeam = () => {
        dataProvider.update('users', {
            id: user.id,
            data: {
                currentOrderPreparation: userLogged.currentOrderPreparation
            }
        }).then(() => {
                setTeam([...team, user])
                props.handleClose()
            }
        )
    }

    const RemoveUserToTeam = () => {
        dataProvider.update('users', {
            id: user.id,
            data: {
                currentOrderPreparation: null
            }
        }).then(() => {
                setTeam([...team.filter((u) => u.id !== user.id)])
                props.handleClose()
            }
        )
    }

    return (
        <Dialog open={true}>
            <DialogTitle>Utilisateur : {user.firstName} {user.lastName}</DialogTitle>
            <DialogContent>
                {
                    !user.currentOrderPreparation ?
                        <Button onClick={AddUserToTeam}>Ajouter dans l'équipe actuelle</Button>
                        : <Button onClick={RemoveUserToTeam}>Retirer de l'équipe</Button>
                }
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="success" onClick={props.handleClose}>
                    Terminer
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default ScanUserDialog
