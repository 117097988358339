import {Box} from "@mui/material";
import * as React from "react";
import PropTypes from "prop-types";
import BtnInfoPrep from "../../module/BtnInfoPrep.js";
import BtnMemoPrep from "../../module/BtnMemoPrep.js";
import BtnCaptureImagePrep from "../../module/BtnCaptureImagePrep.js";

const PreparationNumber = (props) => (
    <Box sx={{fontWeight: 'bolder', fontSize: 25, display: "flex", justifContent:"center", alignItems:'center'}}>
        Préparation {props.record.name}
        <BtnInfoPrep data={props.record}/>
        <BtnMemoPrep data={props.record}/>
        <BtnCaptureImagePrep data={props.record}/>
    </Box>
);

PreparationNumber.propTypes = {
    record: PropTypes.object,
};

export default PreparationNumber;
