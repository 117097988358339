import * as React from "react";
import ShowGuesser from "@api-platform/admin/lib/ShowGuesser";
import FieldGuesser from "@api-platform/admin/lib/FieldGuesser";
import {Datagrid, ReferenceManyField, EditButton} from "react-admin";
import ColorFieldGuesser from "../../../field/Color";

const WarehouseShow = props => (
    <ShowGuesser {...props}>
        <FieldGuesser source={"name"} addLabel={true}/>
        <FieldGuesser source={"code"} addLabel={true}/>
        <FieldGuesser source={"color"} addLabel={true}/>
        <ReferenceManyField
            reference="warehouse_locations"
                            target="warehouse.id"
                            label="Emplacements">
            <Datagrid>
                <FieldGuesser source={"code"} addLabel={true}/>
                <FieldGuesser source={"priority"} addLabel={true}/>
                <ColorFieldGuesser source={"color"} />
                <FieldGuesser source={"comment"} addLabel={true}/>
                <EditButton/>
            </Datagrid>
        </ReferenceManyField>
    </ShowGuesser>
);

export default WarehouseShow;
