import {useContext, useEffect, useState} from "react";
import {useRedirect} from "react-admin";
import {UserContext} from "../context/UserContext";
import dataProvider from "../../utils/dataProvider";
import {CircularProgress} from "@mui/material";
import {STOPPED} from "../../utils/enum/StateEnum";
import {IconPickerItem} from "react-fa-icon-picker";
import * as React from "react";


const StateButton = (props) => {
    const [state, setState] = useState(null);
    const [loading, setLoading] = useState(true);
    const redirect = useRedirect();
    const [user, _] = useContext(UserContext);


    useEffect(() => {
        if(state === null || state.code !== props.state){
            dataProvider.getOne('order_preparation_states', {
                id: '/order_preparation_states/' + props.state
            }).then(({data}) => {
                setState(data)
                setLoading(false)
            });
        }
    })

    if(loading) return <CircularProgress sx={{padding: "5px"}} size={25} />;

    const handleClick = () => {
        setLoading(true)
        dataProvider.update('order_preparations', {
            id: props.orderPreparation.id,
            data: {
                state: state.id
            }
        }).then(() => {
            props.setRecord(state);
            switch(state.code) {
                case STOPPED:
                    // On recharge l'order au cas où des utilisateurs se sont ajoutés
                    dataProvider.getOne('order_preparations', {
                        id: props.orderPreparation.id
                    }).then(({data}) => {
                        dataProvider.updateMany('users', {
                            ids: data.currentUsers,
                            data: {
                                currentOrderPreparation: null
                            }
                        }).then(() => {
                            user.currentOrderPreparation = null
                            redirect('/')
                        })
                    });
                    break;
                default:
                    setLoading(false)
                    break;

            }
        })
    };

    return (
        <IconPickerItem containerStyles={{display: 'flex', padding: 5, cursor: 'pointer'}} icon={state.icon} size={30} color={state.color} onClick={handleClick} />
    );
};

export default StateButton
