import {
    Datagrid,
    ReferenceManyField,
    TextField,
    ReferenceArrayField, ReferenceField, BooleanField
} from "react-admin";
import LinearProgressWithLabelField from "../../../../../field/LinearProgressWithLabelField";
import * as React from "react";
import ProductPresenter from "../../../../../presenter/Product";
import SpotPresenter from "../../../../../presenter/Spot";
import ImageField from "../../../../../field/ImageField";
import NumberField from "../../../../../field/NumberField";
import { FunctionField } from "react-admin";
import { Chip } from "@mui/material";


const DualReferenceField = props => {
    return (
        <>
        <ReferenceField reference={'products'} source={'product'} link={false}>
                    <ProductPresenter />
        </ReferenceField>
        <FunctionField resource={"order_preparation_items"} source={"custom"} render={record => (record.custom==="")?"":<Chip label={record.custom}/>} />
        </>
    )
}

const OrderPreparationShowItems = (props) => {
    return (
        <ReferenceManyField {...props}
                            reference="order_preparation_items"
                            target="orderPreparation.id"
                            label="Produits"
                            sort={{field: 'priority', order: 'ASC'}}
        >
            <Datagrid >
                <NumberField resource={"order_preparation_items"} source="priority"/>
                <SpotPresenter source='spot'/>
                <ReferenceField label={"Image"} reference={'products'} source={'product'} link={false}>
                    <ImageField source="image" />
                </ReferenceField>
                <DualReferenceField label={"Produit"}/>
                <LinearProgressWithLabelField source="prepared" calculated={true}/>
                <ReferenceArrayField reference="palette_items" source="paletteItems" label="">
                    <Datagrid>
                        <TextField source="quantity"/>
                        <ReferenceField reference={"palettes"} source={"palette"} link={false}>
                            <TextField source="number"/>
                        </ReferenceField>
                        <BooleanField source="controlled"/>
                    </Datagrid>
                </ReferenceArrayField>
            </Datagrid>
        </ReferenceManyField>
    )
}

export default OrderPreparationShowItems;
