import * as React from 'react';
import Button from '@mui/material/Button';
import {useNotify} from "react-admin";

export default function NumpadPin(props) {
    var pin = props.pincode;
    var nbrdepin= pin.length;
    const notify = useNotify();

    const inputs = [];
    for (let i = 0; i < nbrdepin; i++) {
        var pin_id =  "pin_"+i;
        inputs.push(<input key={pin_id} id={pin_id} style={{border: "0px", borderBottom: "1px solid grey", width: "20%", fontSize: "50px", textAlign: "center"}} readOnly type="text"/>);
    }

    function keyPress(e){
        var val = e.target.innerText;
        for (let i = 0; i < nbrdepin; i++) {
            var input = document.getElementById("pin_"+i);
            if(input.value == ""){
                input.value = val;
                return;
            }
            
        }
    }

    function keyClear(e){
        for (let i = (nbrdepin-1); i >= 0; i--) {
            var input = document.getElementById("pin_"+i);
            if(input.value != ""){
                input.value = "";
                return;
            }
        }
    }

    function keyValid(){
        var pin_entered = "";
        for (let i = 0; i < nbrdepin; i++) {
            var input = document.getElementById("pin_"+i);
            if(input.value != ""){
                pin_entered += input.value;
            }
        }
       
        if(pin_entered == pin){
            props.setpin(pin_entered);
        }else{
            notify('Le code PIN est invalide', {type: 'error'})
        }
    }


    return (

        <>  
            <div style={{display: "flex", gap: "20px", justifyContent:"center", marginLeft: "auto", marginRight: "auto", marginBottom: "25px", width: "35%", justifyContent: "space-between"}}>{inputs}</div>
            <div style={{display: "flex", flexWrap: "wrap", width: "80%", marginLeft: "auto", marginRight: "auto", gap: "5px"}} >
                <Button onClick={keyPress} style={{flex: "1 0 32%", height: "64px"}} variant="contained">1</Button>
                <Button onClick={keyPress} style={{flex: "1 0 32%", height: "64px"}} variant="contained">2</Button>
                <Button onClick={keyPress} style={{flex: "1 0 32%", height: "64px"}} variant="contained">3</Button>
                <Button onClick={keyPress} style={{flex: "1 0 32%", height: "64px"}} variant="contained">4</Button>
                <Button onClick={keyPress} style={{flex: "1 0 32%", height: "64px"}} variant="contained">5</Button>
                <Button onClick={keyPress} style={{flex: "1 0 32%", height: "64px"}} variant="contained">6</Button>
                <Button onClick={keyPress} style={{flex: "1 0 32%", height: "64px"}} variant="contained">7</Button>
                <Button onClick={keyPress} style={{flex: "1 0 32%", height: "64px"}} variant="contained">8</Button>
                <Button onClick={keyPress} style={{flex: "1 0 32%", height: "64px"}} variant="contained">9</Button>
                <Button onClick={keyPress} style={{flex: "1 0 32%", height: "64px"}} variant="contained">0</Button>
                
                <Button onClick={keyClear} variant="contained">Effacer</Button>
                <Button onClick={keyValid} variant="contained">Valider</Button>
            </div>
        </>


    )

}