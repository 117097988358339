import React from 'react';
import {BlockPicker} from 'react-color';
import {InputGuesser} from "@api-platform/admin";

export class ColorPickerGuesserState {
    background: string;
    defaultColors: string;
    visibility: boolean;
}

export class ColorPickerGuesserProps {
    source: string;
    resource: string;
    background: string;
    onChange: function;
    record: any;
}

export class InputColorPickerGuesser extends React.Component<ColorPickerGuesserProps, ColorPickerGuesserState> {
    constructor(props) {
        super(props);
        this.state = {
            background: props.record[props.source] ?? props.background,
            defaultColors: ['#D9E3F0', '#F47373', '#697689', '#37D67A', '#2CCCE4', '#555555', '#dce775', '#ff8a65', '#ba68c8', '#abcdef'],
            visibility: false
        }
    }

    myRef = React.createRef();

    handleBlockPickerChangeComplete = (color) => {
        this.props.record[this.props.source] = color.hex;
        this.setState({background: color.hex, visibility: false});
        this.props.onChange();
    };

    handleChange = (e) => {
        this.props.onChange();
        this.props.record[this.props.source] = e.target.value;
        this.setState({background: e.target.value});
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }
    handleClickOutside = e => {
        if (!this.myRef.current.contains(e.target)) {
            this.setState({ visibility: false });
        }
    };

    handleClickInside = () => this.setState({ visibility: !this.visibility });

    render() {
        return <div ref={this.myRef} onClick={this.handleClickInside} style={{position: 'relative'}}>
                <InputGuesser
                    onChange={this.handleChange}
                    resource={this.props.resource}
                    source={this.props.source}
                    initialValue={this.state.background}
                    disabled={this.state.visibility}
                />
                {this.state.visibility &&
                <div style={{position: 'absolute', top: 0, left: 0, zIndex: 2}}>
                    <BlockPicker
                        id='picker'
                        colors={this.state.defaultColors}
                        color={this.state.background}
                        onChangeComplete={this.handleBlockPickerChangeComplete}
                        style={{position: 'absolute'}}
                    /></div>
                }
            </div>
            ;
    }
}
