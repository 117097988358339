import {Box, Divider, Grid} from "@mui/material";
import {ReferenceArrayField, SingleFieldList} from "react-admin";
import * as React from "react";
import PaletteItem from "./PaletteItem";
import {useState} from "react";

const GridPalette = (props) => {
    const gridStyle = {border: '1px solid lightgray', borderRadius: '5px', backgroundColor: 'smoke', padding: 0, margin: '5px', overflow: 'hidden'};
    const name = props.name;
    const backgroundColor = props.backgroundColor;

    var filter = true;
    if(props.controlled == null){
    }else{
        filter = props.responsable ? {controlledResponsible: props.controlled ?? true} : {controlled: props.controlled ?? true};
    }
    
    const [refresh, setRefresh] = useState(true);

    return (
        <Grid justifyContent="center" item xs={12} sm={12} md={12} lg={8} style={{marginLeft: "auto", marginRight: 'auto'}}>
            <Box sx={gridStyle}>
                <h3 style={{textAlign: "center", color: 'white', backgroundColor: backgroundColor, margin: 0, padding: '10px'}}>{name}</h3>
                <Divider/>
                <ReferenceArrayField key={'referenceArray'+refresh} style={{marginTop: 0, marginBottom: 0}} emptyText={"Aucune palette"} record={props.palette} reference="palette_items" source="items" filter={filter}>
                    <SingleFieldList linkType={false}>
                        <PaletteItem handleRefresh={() => {setRefresh(!refresh)}} palette={props.palette} setPalette={props.setPalette} lock={props.lock} responsable={props.responsable}/>
                    </SingleFieldList>
                </ReferenceArrayField>
            </Box>
        </Grid>
    )
}

export default GridPalette
