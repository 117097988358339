import frenchMessages from 'ra-language-french';
import domainMessages from './i18n/domainMessages.fr';
import * as importCsvDomainMessages from "react-admin-import-csv/lib/i18n";
import polyglotI18nProvider from 'ra-i18n-polyglot';

const defaultLocale = 'fr';
const messages = {
    'fr': {...frenchMessages, ...domainMessages, ...importCsvDomainMessages},
    'en': {...frenchMessages, ...domainMessages, ...importCsvDomainMessages}
};

const i18nProvider = polyglotI18nProvider(() => messages[defaultLocale], 'fr', { allowMissing: true });
export default i18nProvider;
