import FieldGuesser from "@api-platform/admin/lib/FieldGuesser";
import * as React from "react";
import ColorFieldGuesser from "../../field/Color";
import {FormListGuesser} from "../../form/List";

const ChannelList = props => (
    <FormListGuesser {...props}>
        <FieldGuesser source={"code"} />
        <FieldGuesser source={"name"} />
        <ColorFieldGuesser source={"color"} />
        <FieldGuesser source={"hostname"} />
        {/*<FieldGuesser source={"orderPreparations"} />*/}
        <FieldGuesser source={"apiKey"} />
    </FormListGuesser>
);

export default ChannelList
