import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SquareData from "../../../component/presenter/square/SquareData";
import * as React from "react";
import {useCreate, useNotify} from "react-admin";
import {CircularProgress} from "@mui/material";
import Alert from "../../../component/presenter/Alert";
import PropTypes from "prop-types";
import LockDialog from "../../../component/dialog/LockDialog";
import {useContext, useEffect, useState} from "react";
import {ScanContext} from "../../../component/context/ScanContext";
import {SPOT_LOCK_WITH_EAN} from "../../../config/app";

import dataProvider from "../../../utils/dataProvider";

const Lock = (props) => {
    const [scan, setScan] = useContext(ScanContext);
    const notify = useNotify()
    const [open, setOpen] = useState(false);
    const [pin, setPin] = useState("000");
    const [createScanLog, { loading, error }] = useCreate('scan_logs', {
        warehouseLocation: props.spot.id,
        orderPreparationStep: '/order_preparation_steps/'+props.step,
        palette: props.paletteId
    });

    const successScan = () => {
        createScanLog();
        notify('Le code EAN est valide. Déverrouillage de l\'emplacement.', {type: 'success'})
        props.setLock(false);
    }

    
    

    const checkScan = (value) => {
        if(!value){
            return false
        }
        
        if((pin === value && open) || pin === value){
            successScan()
            setScan(null);
            return true
        }
        notify('Code incorrect')
        return false;
        
        
    }

    useEffect(() => {
        if(props.spot.id != null){
            dataProvider.getOne('warehouse_locations', {
                id: props.spot.id
            }).then(({data}) => {
                setPin(data.pin);
            });
        }
        return checkScan(scan);
        
    })

    if(error)
        return <Alert value={"Probleme dans l'envoi du scan"} type={"error"} reload={true}/>

    return (
        <>
            <SquareData title={props.hideLabel ? null : "Emplacement"} value={props.spot.spot}
                        onClick={() => {
                            props.lock ?
                                (SPOT_LOCK_WITH_EAN ? setOpen(true) : props.setLock(!props.lock))
                                    : props.setLock(!props.lock);
                        }} backgroundColor={props.spot.color}
                        color={props.lock ? "danger" : "success"}
                        icon={loading ? <CircularProgress/> : <FontAwesomeIcon icon={"fa-solid fa-" + (props.lock ? "lock" : "unlock")}/>}/>
            {
                open && <LockDialog setOpen={setOpen} pin={pin} spot={props.spot} checkScan={checkScan}/>
            }

        </>

    )
}

Lock.propTypes = {
    spot: PropTypes.object.isRequired,
    paletteId: PropTypes.string.isRequired,
    step: PropTypes.string.isRequired,
    lock: PropTypes.bool.isRequired,
    setLock: PropTypes.func.isRequired
};

export default Lock
