import { Component, useState, useEffect } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import decodeJwt from 'jwt-decode';
import {ENTRYPOINT} from "./../../config/entrypoint";
import './Login.css';
import {TextField, Button} from '@mui/material'
import useScanDetection from 'use-scan-detection';
import * as React from "react";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';

function TransitionRight(props) {
    return <Slide {...props} direction="right" />;
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const LoginScan = () => {
    const [value, setValue] = useState("");
    const [stateNotif, setStateNotif] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
        message: "",
        severity: "",
        transition: TransitionRight,
    });
    const { vertical, horizontal, open, message, severity, transition } = stateNotif;


    useScanDetection({
        onComplete: (code) => { setValue(code.toString().replaceAll("Shift", "")) },
        minLength: 128 // EAN13
    });

   

    useEffect(() => {
        if(value !== ""){
            const request = new Request(
                `${ENTRYPOINT}/quick_authentification_token`,
                    {
                        method: 'POST',
                        body: JSON.stringify({ key: value }),
                        headers: new Headers({ 'Content-Type': 'application/json' }),
                    }
                );
                return fetch(request)
                    .then((response) => {
                        if (response.status < 200 || response.status >= 300) {
                            throw new Error(response.statusText);
                        }
                        return response.json();
                    }).catch(error => {
                        setStateNotif({...stateNotif, open: true, message: "Une erreur est survenue lors de la tentative de connexion", severity: "error"});
                    }).then(({ token }) => {
                        const decodedToken = decodeJwt(token);
                        setStateNotif({...stateNotif, open: true, message: "Connexion réussi", severity: "success"});
                        localStorage.setItem('token', token);
                        localStorage.setItem('email', decodedToken.username);
                        localStorage.setItem('permissions', decodedToken.roles);
                        window.location.href = "";
                    });
        }
    },[value])

    const handleCloseNotification = () => {
        setStateNotif({ ...stateNotif, open: false }); 
    };

    return (
        <>
        <input style={{display: "none"}} 
            value={value} 
            type="text"
            readOnly
        />
        <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={2000}
        TransitionComponent={transition}
        onClose={handleCloseNotification}
        key={vertical + horizontal}>
            <Alert severity={severity}>{message}</Alert>
        </Snackbar>
        </>
    );
};



class Login extends Component {
    
    constructor(props) {
        super(props);
        this.state = {email: '', pass: '', open: false, vertical: 'top', horizontal: 'center', message: "", severity: "", transition: TransitionRight};

        this.submit = this.submit.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangePass = this.handleChangePass.bind(this);
        this.handleCloseNotification = this.handleCloseNotification.bind(this);
        
    }
    
    

    handleCloseNotification = () => {
        this.setState({open: false});
    };

    submit = (e) => {
        
        e.preventDefault();
        var username = this.state.email;
        var password = this.state.pass;
        const request = new Request(
            `${ENTRYPOINT}/authentication_token`,
            {
                method: 'POST',
                body: JSON.stringify({ email: username, password }),
                headers: new Headers({ 'Content-Type': 'application/json' }),
            }
        );
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            }).catch(error => {
                this.setState({open: true, message: "Une erreur est survenue lors de la tentative de connexion", severity: "error"});
            }).then(({ token }) => {
                const decodedToken = decodeJwt(token);
                this.setState({open: true, message: "Connexion réussi", severity: "success"});
                localStorage.setItem('token', token);
                localStorage.setItem('email', decodedToken.username);
                localStorage.setItem('permissions', decodedToken.roles);
                window.location.href = "";
            });
    }

    handleChangeEmail = (e) => {
        e.preventDefault();
        this.setState({email: e.target.value});
    }

    handleChangePass = (e) => {
        e.preventDefault();
        this.setState({pass: e.target.value});
    }

    
    render() {
        return (
            <MuiThemeProvider theme={this.props.theme}>
                <LoginScan/>
                <div className="root-login">
                    <form className='formPanel' onSubmit={this.submit}>
                        <div className='inputPanel'>
                            <TextField key={'inputLoginEmail'} type={"email"} label="Email" variant="outlined" value={this.state.email} onChange={this.handleChangeEmail}/>
                        </div>
                        <div className='inputPanel'>
                            <TextField key={'inputLoginPass'} type={"password"} label="Mot de passe" variant="outlined" value={this.state.pass} onChange={this.handleChangePass}/>
                        </div>
                        <Button variant="contained" type="submit" color="success">
                            Se connecter
                        </Button>
                        
                    </form>
                </div>
                <Snackbar
                anchorOrigin={{ vertical: this.state.vertical, horizontal: this.state.horizontal }}
                open={this.state.open}
                autoHideDuration={2000}
                TransitionComponent={this.state.transition}
                onClose={this.handleCloseNotification}
                key={this.state.vertical + this.state.horizontal}>
                    <Alert severity={this.state.severity}>{this.state.message}</Alert>
                </Snackbar>
            </MuiThemeProvider>
        );
    }
};
export default Login;