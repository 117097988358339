import * as React from 'react'
import {Fragment} from 'react';
import {List, BulkDeleteButton} from 'react-admin';
import CustomizableDatagrid from 'ra-customizable-datagrid';

export class ListGuesserState {
}

export class ListGuesserProps {
    resource: string;
    exporter: any;
    basePath: any;
    hasEdit: boolean;
    hasShow: boolean;
    hasList: boolean;
    hasCreate: boolean;
    filters: any;
    filter: any;
    aside: any;
    removeBulkActionDelete: boolean;
}


export class FormListGuesser extends React.Component<ListGuesserProps, ListGuesserState> {
    PostBulkActionButtons = props => (
        <Fragment>
            {props.bulkActionButtons}
            {!props.removeBulkActionDelete && <BulkDeleteButton/>}
        </Fragment>
    );

    render() {
        return (
            <List
                theme={this.props.theme}
                exporter={this.props.exporter}
                filters={this.props.filters}
                filter={this.props.filter}
                actions={this.props.actions}
                aside={this.props.aside}
                basePath={this.props.basePath}
                resource={this.props.resource}
                hasEdit={this.props.hasEdit}
                hasShow={this.props.hasShow}
                hasList={this.props.hasList}
                hasCreate={this.props.hasCreate}
                bulkActionButtons={<this.PostBulkActionButtons
                    removeBulkActionDelete={this.props.removeBulkActionDelete}
                    bulkActionButtons={this.props.bulkActionButtons}/>}
                style={{tableLayout: 'fixed'}}
                rowclick={this.props.rowClick}
            >
                <CustomizableDatagrid>
                    {React.Children.map(this.props.children, c => {
                        if (React.isValidElement(c)) {
                            return React.cloneElement(c, {resource: this.props.resource});
                        }
                    })}
                </CustomizableDatagrid>
            </List>
        )
    }
}
