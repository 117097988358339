import * as React from "react";
import ShowGuesser from "@api-platform/admin/lib/ShowGuesser";
import FieldGuesser from "@api-platform/admin/lib/FieldGuesser";
import ColorFieldGuesser from "../../field/Color";

const ChannelShow = props => (
    <ShowGuesser {...props}>
        <FieldGuesser source={"code"} addLabel={true} />
        <FieldGuesser source={"name"} addLabel={true} />
        <ColorFieldGuesser source={"color"} addLabel={true} />
        <FieldGuesser source={"hostname"} addLabel={true} />
        {/*<FieldGuesser source={"orderPreparations"} addLabel={true} />*/}
        <FieldGuesser source={"apiKey"} addLabel={true} />
    </ShowGuesser>
);

export default ChannelShow;
