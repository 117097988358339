import * as React from 'react';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {
    useGetList,
    useListContext,
    useNotify, useRefresh,
    useUnselectAll,
    useUpdateMany
} from 'react-admin';
import {useState} from "react";
import {CircularProgress, LinearProgress, MenuItem, Select, TextField} from "@mui/material";
import BootstrapDialogTitle from "../../../../dialog/BootstrapDialogTitle";
import BootstrapDialog from "../../../../dialog/BootstrapDialog";

export default function BulkAssignment(props) {
    const {selectedIds} = useListContext();
    const refresh = useRefresh();

    const [open, setOpen] = useState(false);
    const [user, setUser] = useState(null);
    const handleClick = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const [updateMany, {loading}] = useUpdateMany(
        'order_preparations',
        selectedIds,
        {assignment: user},
        {
            onSuccess: () => {
                setOpen(false)
                unselectAll('order_preparations');
                notify('Affectation des préparations de commande réussi.');
                refresh();
            },
            onFailure: () => notify('Erreur: les préparations de commande n\'ont pas pu être mis à jour', {type: 'warning'}),
        }
    );

    if (open) {
        return (
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-priority"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-priority" onClose={handleClose}>
                    Affecter un utilisateur
                </BootstrapDialogTitle>

                <DialogContent dividers>
                    <SelectUsers onChange={(event) => setUser(event.target.value)}/>
                </DialogContent>

                <DialogActions>
                    <Button startIcon={loading ? <CircularProgress/> : null} autoFocus variant="contained"
                            color="primary" disabled={loading} onClick={updateMany}>
                        Valider
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        )
    }

    return (
        <Button onClick={handleClick} color={'secondary'}>
            Assigner
        </Button>
    );
}

const SelectUsers = (props) => {
    const {data, ids, loading, error} = useGetList(
        'users',
        {page: 1, perPage: 999},
        {field: 'id', order: 'ASC'}
    );
    if (loading || error) return <LinearProgress/>;

    return (
        <Select defaultValue={undefined} placeholder={"Choisissez un utilisateur"} sx={{width: '100%'}} onChange={props.onChange}>
            <MenuItem key={'default-null-user'} value={undefined}>
                Aucun
            </MenuItem>
            {ids.map((id) => (
                <MenuItem key={id} value={id}>
                    {data[id].email}
                </MenuItem>
            ))}
        </Select>
    )
}
