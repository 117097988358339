import MoveDownIcon from '@mui/icons-material/MoveDown';
import * as React from "react";
import {useRef, useState} from "react";
import {Box, CircularProgress, DialogContent, Fab, TextField} from "@mui/material";
import BootstrapDialogTitle from "../../../dialog/BootstrapDialogTitle";
import BootstrapDialog from "../../../dialog/BootstrapDialog";
import Lock from "../../../../pages/Prepare/Show/Lock";
import Button from "@mui/material/Button";
import {useDataProvider, useNotify, useRefresh} from "react-admin";
import Alert from "../../../presenter/Alert";

const ModalMovePaletteLocation = (props) => {
    const [lock, setLock] = useState(true);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const refInput = useRef(null);
    const dataProvider = useDataProvider();
    const notify = useNotify()
    const refresh = useRefresh()

    const handleClick = () => {
        setLoading(true)
        dataProvider.getOne('warehouse_locations', {
            id: '/warehouse_locations/'+refInput.current.value
        }).then(({data}) => {
            dataProvider.create('scan_logs', {
                data: {
                    warehouseLocation: data.id,
                    palette: props.palette.id
                }
            }).then(() => {
                setLoading(false)
                setError(false);
                notify('Validation du déplacement palette')
                props.handleClose();
                refresh();
            })
        }).catch(() => {
            setError(true);
            setLoading(false)
        })
    }

    return (
        <BootstrapDialog
            onClose={props.handleClose}
            aria-labelledby="customized-dialog-title"
            open={true}
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={props.handleClose}>
                Déplacer l'emplacement de la palette
            </BootstrapDialogTitle>
            <DialogContent>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Lock
                        lock={lock}
                        setLock={setLock}
                        paletteId={props.palette.id}
                        spot={props.palette.spot}
                        step='prepare'
                    />
                    =>
                    <TextField disabled={lock} error={error ? error : false} helperText={error ? 'Code emplacement introuvable' : null} inputRef={refInput} id="outlined-basic" label="Emplacement d'arrivé" variant="outlined" placeholder={'6580'}/>
                    <Button disabled={lock} startIcon={loading && <CircularProgress/>} onClick={handleClick}>Valider</Button>
                </Box>
            </DialogContent>
        </BootstrapDialog>
    )
}

const ButtonPaletteMoveLocation = (props) => {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(true)
    }

    return (
        <>
            <Fab size={"small"} color="default" aria-label="Déplacer la palette" onClick={handleClick}>
                <MoveDownIcon/>
            </Fab>
            {
                open &&
                <ModalMovePaletteLocation handleClose={() => setOpen(false)} palette={props.palette}/>
            }
        </>
    )
}

export default ButtonPaletteMoveLocation;
