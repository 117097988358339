import {FormCreateGuesser} from "../../form/Create";
import InputGuesser from "@api-platform/admin/lib/InputGuesser";
import { ReferenceInput, SelectInput } from 'react-admin';


export default function CreateNavette(props) {
    return (
        <FormCreateGuesser {...props}>
            <ReferenceInput source={"carrier"} reference={"carriers"}>
                <SelectInput optionText="name" />
            </ReferenceInput>
            <InputGuesser source={"estimatedAt"} />

            {/*<ReferenceArrayInput source={"orderPreparations"} reference={"order_preparations"}>*/}
            {/*    <SelectArrayInput optionText={"name"} />*/}
            {/*</ReferenceArrayInput>*/}
        </FormCreateGuesser>
    );
};
