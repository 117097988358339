import * as React from 'react'
import {Box, Divider} from "@mui/material";
import PreparationCounterState from "../../../../box/preparation/PreparationCounterState";
import PreparationStep from "../../../../box/preparation/PreparationStep";
import SpotPresenter from "../../../../presenter/Spot";

export class MenuBottomState {
}

export class MenuBottomProps {
    resource: string;
    record: Object;
}

export class MenuBottom extends React.Component<MenuBottomProps, MenuBottomState> {
    render() {
        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 2,
                marginBottom: 2,
                paddingBottom: 2,
                borderBottom: '1px solid lightgray'
            }}>
                <PreparationStep record={this.props.record}/>

                <Box>
                    <Divider orientation="vertical"/>
                </Box>

                <PreparationCounterState record={this.props.record}/>

                <Box>
                    <Divider orientation="vertical"/>
                </Box>
                <SpotPresenter record={this.props.record} source={'spot'}/>
            </Box>
        )
    }
}

