import {cloneElement, createContext, useState} from "react";
import * as React from "react";


export const UserContext = createContext([null, () => {}])

export const UserContextProvider = ({ value, children }) => {
    const [user, setUser] = useState(value);
    return (
        <UserContext.Provider value={[user, setUser]}>
            {cloneElement(children, { user: user })}
        </UserContext.Provider>
    );
};
