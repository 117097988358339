import * as React from 'react'
import {Permission} from "../Permission";

export default class AgencyResourceGuesser extends React.Component {
    render() {
        return (
            <Permission {...this.props} show={null} />
        )
    }
}
