import * as React from "react";
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    spot: {
        height: 20,
        width: 'auto',
        fontWeight: 'bold',
        padding: 7,
        borderRadius: 5,
        textAlign: 'center',
        minWidth: '48px',
        justifyContent: 'center'
    },
});

const SpotPresenter = (props) => {
    const classes = useStyles();

    if(props.record == null)
        return null;

    let spot = props.record[props.source];
    const value = spot ? spot.spot : "";
    const color = spot ? spot.color : "";
    return (
        <div style={{backgroundColor: color, display: 'inline-flex'}} className={classes.spot}>
            {value ? value : '-'}
        </div>
    );
}

SpotPresenter.propTypes = {
    record: PropTypes.object
};

export default SpotPresenter;
