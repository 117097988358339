import * as React from 'react'
import {FormCreateGuesser} from "../../form/Create";
import {InputColorPickerGuesser} from "../../input/ColorPicker";
import {InputGuesser} from "@api-platform/admin";

export default function CarrierCreate(props) {
    return (
        <FormCreateGuesser {...props}>
            <InputGuesser source={"name"} />
            <InputColorPickerGuesser source={'color'}/>
            <InputGuesser source={"maxWeightPerPalette"} />
        </FormCreateGuesser>
    )
}