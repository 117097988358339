import * as React from 'react'
import {InputGuesser} from "@api-platform/admin";
import {CreateGuesser} from "@api-platform/admin";
import {ReferenceInput, SelectInput} from 'react-admin';

export class PaletteCreateState {
    location: string;
    orderPreparationId: number;
}

export class PaletteCreateProps {
    resource: string;
    location: any;
}

export class PaletteCreate extends React.Component<PaletteCreateProps, PaletteCreateState> {
    constructor(props) {
        super(props);
        if (this.props.location.state && this.props.location.state.prevPath) {
            this.state = {
                location: this.props.location.state.prevPath.substr(1),
                orderPreparationId: this.props.location.state.orderPreparationId
            }
        }
    }

    render() {
        return (
            <CreateGuesser
                redirect={this.state ? this.state.location : null}
                resource={this.props.resource}>
                {
                    (this.props.location.state && !this.props.location.state.prevPath) ?
                        <ReferenceInput source={'orderPreparation'} reference={'order_preparations'}>
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                        :
                        <InputGuesser initialValue={this.state.orderPreparationId} label="" style={{display: 'none'}}
                                      source={'orderPreparation'}/>
                }
                <ReferenceInput source="type" reference="products" filter={{'category.palette': true}}>
                    <SelectInput optionText="name" />
                </ReferenceInput>

                {/* Remove in create, get weight length and width from palette.type */}
                {/*<InputGuesser source={'weight'} initialValue={0}/>*/}
                {/*<InputGuesser source={'length'} initialValue={0}/>*/}
                {/*<InputGuesser source={'width'} initialValue={0}/>*/}
                {/*<InputGuesser source={'height'} initialValue={0}/>*/}
            </CreateGuesser>
        )
    }
}
